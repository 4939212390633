.icon,
.icon > svg {
    display: block;
    max-width: 100%;
}

.icon > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    fill: currentColor;
}

.icon {
    position: relative;

    &::after {
        content: "";
        display: block;
        height: 0;
        width: auto;
    }

    &--logo-twitter-2 {
        width: 25px;
        height: 25px;
    }

    &--logo-youtube {
        width: 25px;
        height: 25px;
    }

    &--logo-linkedin {
        width: 25px;
        height: 25px;
    }

    &--shape-star {
        width: 22px;
        height: 22px;
    }

    &--launch-app {
        width: 22px;
        height: 22px;
    }

    &--zoom {
        width: 22px;
        height: 22px;
    }

    &--menu-8 {
        width: 22px;
        height: 22px;
    }

    &--move-layer-down {
        width: 22px;
        height: 22px;
    }

    &--e-remove {
        width: 22px;
        height: 22px;
    }

    &--down-arrow {
        width: 22px;
        height: 22px;
    }

    &--up-arrow {
        width: 22px;
        height: 22px;
    }

    &--left-arrow {
        width: 22px;
        height: 22px;
    }

    &--right-arrow {
        width: 22px;
        height: 22px;
    }

    &--full-screen {
        width: 15px;
        height: 15px;
    }

    &--gallery-view {
        width: 15px;
        height: 15px;
    }

    &--video-64 {
        width: 20px;
        height: 20px;
    }

    &--plus {
        width: 8px;
        height: 8px;
    }
}
