.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: map-get($spacing, m);
    padding-right: map-get($spacing, m);

    @include media(">l") {
        max-width: 1180px;
    }

    &--elements {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: map-get($spacing, xxxl);
    }

    &--case-studies {
        max-width: none;
        padding: 0;
        // These margins are to offset from the fixed header
        margin-top: 105px;
        display: flex;
        min-height: calc(
            100vh - (1248px + 105px)
        ); // This is to offset the footer and the margin
        flex-direction: column;
        flex-wrap: nowrap;
        @include media(">s") {
            margin-top: 137px;
            min-height: calc(
                100vh - (530px + 137px)
            ); // This is to offset the footer and the margin
        }
    }

    &--carousel {
        position: relative;
    }

    &--wider {
        padding-left: map-get($spacing, m);
        padding-right: map-get($spacing, m);
        @include media(">l") {
            max-width: 1350px;
        }
    }
}
