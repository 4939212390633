.project-details {
    margin-bottom: 30px;
    &__label {
        display: block;
        font-weight: normal;
        margin-bottom: map-get($spacing, s);
        color: map-get($colour, greyM);
        font-family: $fontFamilyStandardLight;
        font-size: map-get($fontSize, l);
    }
    &__desc {
        color: map-get($colour, startD);
        font-size: map-get($fontSize, l);
        margin-bottom: map-get($spacing, l);
        @include media(">m") {
            margin-bottom: 0;
        }
        a {
            color: black;
        }
    }
}
