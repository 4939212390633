.responsive-image {
    display: block;
    width: 100%;
    height: auto;
}

.home {
    .content-wrapper {
        margin-bottom: 0;
    }
}

.column-spacer {
    &--left {
        padding-left: 0;
        @include media(">m") {
            padding-left: map-get($spacing, m);
        }
        @include media(">xl") {
            padding-left: map-get($spacing, xxl);
        }
    }
    &--right {
        padding-right: 0;
        @include media(">m") {
            padding-right: map-get($spacing, m);
        }
        @include media(">xl") {
            padding-right: map-get($spacing, xxl);
        }
    }
}

.partner-scrolling {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    max-height: 640px;
    -webkit-overflow-scrolling: touch;
    @include media(">m") {
        max-height: 840px;
    }

    &::-webkit-scrollbar {
        width: 2px;
        appearance: none;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0,0,0,0.1);
    }
    &::-webkit-scrollbar-thumb {
        background: #000;
    }

    &__inner {
        padding-right: map-get($spacing, m);
        @include media(">xl") {
            padding-right: map-get($spacing, xl);
        }
    }
}

.partners-background {
    position: relative;
    overflow: visible !important;
    padding-top: map-get($spacing, l);
    @include media(">l") {
        padding-top: 0;
        overflow: hidden;
    }
    &:after {
        content: "";
        display: block;
        background:#FAFAFA;
        z-index: -1;
        position: absolute;
        pointer-events: none;
        width: calc(100% + #{map-get($spacing, m)*2});
        height: 100%;
        top: 0;
        left: #{map-get($spacing, m) * -1};
        @include media(">l") {
            height: calc(100% + #{map-get($spacing, xxxl)});
            top: -#{map-get($spacing, xxxl)};
            right: #{map-get($spacing, m)*-1};
            left: auto;
            width: 1000%;
        }
    }
}

.not { display: block; }
@each $target, $value in $breakpoints {
    .not\@#{$target} {
        @include media(">#{$target}") {
            display: none;
        }
    }
}

.stickyfill {
    &:before, &:after {
        content: '';
        display: table;
    }
}

.max-width {
    display: block;
    max-width: 100%;
}

.flickity-page-dots {
    list-style: none !important;
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    li.dot {
        margin: 0px 4px !important;
        line-height: 1;
        appearance: none;
        border: none;
        border-radius: 0px;
        opacity: 1;
        height: 6px;
        width: 6px;
        display: block;
        background: map-get($colour, greyL);
        user-select: none;
        cursor: pointer;
        outline: none;
        padding: 0px;
        &.is-selected {
            background: map-get($colour, startD);
        }
    }
}

[x-cloak] {
    display: none;
}

.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}


