.share-links {
    li {
        display: block;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: none;
        color: black;
        display: flex;
        align-items: center;
        font-size: 14px;
        svg {
            margin-right: 14px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
