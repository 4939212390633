// Some fancybox overrides
.fancybox-container {
    .fancybox-content {
        position: relative;

        .fancybox-close-small {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 64px;
            height: 64px;
            color: map-get($colour, startL);
        }
    }

    .fancybox-infobar {
        display: none;
    }

    .fancybox-button {
        color: map-get($colour, startL);
        background: transparent;
        width: 54px;
        height: 54px;
    }

    .fancybox-navigation {
        .fancybox-button {
            height: 120px;
            width: 90px;
        }
    }

    .fancybox-slide--iframe,
    .fancybox-slide--video {
        .fancybox-content {
            max-width: 100%;
            max-height: 100%;
            overflow: auto !important;
        }
    }

    &.fancybox--dark-close {
        .fancybox-close-small {
            color: map-get($colour, startD);
            top: 18px;
        }
    }
}