.body {
    font-family: $fontFamilyStandard;
    font-size: map-get($fontSize, m);
    overflow-x: hidden;
    color: map-get($colour, startS);

    &.scroll-hidden {
        overflow: hidden;
    }
    &--case-studies-index {
        .site-header {
            z-index: 20;
        }
        .site-footer {
            padding-bottom: 20px + 62px;
        }
    }
}

.body.l-full {
    background-color: #3d3d3d;
    height: calc(100vh - 51px);
    .site-footer {
        display: none;
    }
}
