#contact-map {
    width: 100%;
    height: 300px;
    margin-bottom: 40px;

    @include media(">m") {
        height: 500px;
        margin-bottom: 60px;
    }
}
