.product-block {
    &__header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 20px;
        img {
            margin-right: 20px;
            max-width: 35px;
            @include media(">m") {
                max-width: 65px;
            }
        }
        h2 {
            margin: 0px;
        }
    }

    .button {
        margin-bottom: 0;
    }
}
