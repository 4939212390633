.partners-search {
    width: 100%;
    margin-bottom: map-get($spacing, l);
    @include media(">m") {
        margin-bottom: 24px;
    }
    &__form {
        width: 100%;
        height: 60px;
        display: block;
        @include media(">m") {
            height: 70px;
        }
    }
    &__row {
        display: flex;
    }
    &__search {
        background: #FFF;
        border: 1px solid #DDD;
        border-right: 0px;
        border-radius: 0px;
        display: block;
        padding: 0px map-get($spacing, m);
        margin: 0px;
        width: 100%;
        font-size: 16px;
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        appearance: none;
        height: 60px;
        @include media(">m") {
            height: 70px;
            padding: 0px map-get($spacing, l);
        }
        &::placeholder { color: #000; }
    }
    &__submit {
        background: #000;
        appearance: none;
        border: none;
        border-radius: 0;
        cursor: pointer;
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
        @include media(">m") {
            width: 70px;
            height: 70px;
            flex: 0 0 70px;
        }
    }
}
