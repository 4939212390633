@tailwind base;
@tailwind components;

select {
    background-image: none !important;
}

details summary::-webkit-details-marker {
    display: none;
}

/* NOTE: Making a separate class here, because the styles are different from the Typography plugin config. */
.tw-service-prose p {
    @apply tw-leading-normal;
}

.tw-service-prose a {
    @apply tw-underline;
}

.tw-service-prose>*+* {
    @apply tw-mt-5;
}

.tw-service-prose>ul>li+li,
.tw-service-prose>ol>li+li {
    @apply tw-mt-5;
}

/* purgecss start ignore */
.tw-checkbox-wrapper {
    @apply tw-relative tw-my-9;
}

.tw-checkbox-wrapper label {
    @apply tw-block tw-text-black;
    padding: 4px 0 5px 46px;
}

.tw-checkbox-wrapper input {
    height: 30px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 10;
}

.tw-checkbox-wrapper input+label::before {
    border: 1px solid #dedede;
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
}

.tw-checkbox-wrapper input+label::after {
    content: "";
    opacity: 0;
    background-image: url("../img/check-black.svg");
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 8px;
    top: 9px;
    transition: opacity 0.2s ease-in-out;
}

.tw-checkbox-wrapper input:checked+label::after {
    opacity: 1;
}

.tw-page-builder-container-sm,
.tw-page-builder-container-lg {
    @apply tw-w-full tw-mx-auto tw-px-4;
}

.tw-page-builder-container-sm {
    @apply tw-max-w-[calc(1140px+2rem)];
}

.tw-page-builder-container-lg {
    @apply tw-max-w-[calc(1140px+2rem)];
}

@screen md {
    .tw-page-builder-container-sm {
        @apply tw-max-w-[calc(1140px+80px)] tw-px-[40px];
    }

    .tw-page-builder-container-lg {
        @apply tw-max-w-[calc(1140px+80px)] tw-px-[40px];
    }
}

@screen lg {
    .tw-page-builder-container-sm {
        @apply tw-max-w-[578px] tw-px-0;
    }

    .tw-page-builder-container-lg {
        @apply tw-max-w-[calc(1140px+140px)] tw-px-[70px];
    }
}

@screen xl {
    .tw-page-builder-container-sm {
        @apply tw-max-w-[746px];
    }
}

.tw-custom-scrollbar::-webkit-scrollbar {
    width: 0.5em;
    /* width of the entire scrollbar */
    height: 0.5em;
}

.tw-custom-scrollbar::-webkit-scrollbar-track {
    background: #f5f5f5;
    /* color of the tracking area */
}

.tw-custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #000;
    /* color of the scroll thumb */
    border-radius: 0;
    /* roundness of the scroll thumb */
    border: 0;
    /* creates padding around scroll thumb */
}

.tw-prose-black {
    --tw-prose-bullets: #000;
    --tw-prose-counters: #000;
    --tw-prose-bold: #000;
}

.tw-prose-darkGrey {
    --tw-prose-bullets: #535353;
    --tw-prose-counters: #535353;
    --tw-prose-bold: #535353;
}

.tw-prose-white {
    --tw-prose-bullets: #fff;
    --tw-prose-counters: #fff;
    --tw-prose-bold: #fff;
}

.tw-prose-black a:hover,
.tw-prose-darkGrey a:hover {
    @apply tw-text-black;
}

.timeline-popup.fancybox-content {
    padding: 0 !important;
    height: 100%;
    max-height: 500px;
    overflow: auto;
}

.timeline-entry {
    @apply tw-mb-16 tw-relative md:tw-w-1/2 tw-pl-7 tw-block !important;
}

.timeline-entry--right {
    @apply tw-float-right tw-clear-right tw-pl-6 !important;
}

.timeline-entry--right>svg {
    @apply md:tw-translate-x-[-23px] !important;
}

.timeline-entry--left {
    @apply tw-float-left tw-clear-left tw-pl-0 tw-pr-6 !important;
}

.timeline-entry--left>svg {
    @apply md:tw-left-auto md:tw-right-0 md:tw-translate-x-[27px] !important;
}

/* newsletter signup form */
.form--newsletter button {
    border-radius: 40px !important;
    padding: 8px 12px 10px 12px;
    @apply tw-font-medium tw-h-auto;
}

.form--newsletter .form__input--checkbox,
.form--newsletter .form__input--mailing_list {
    @apply tw-h-5 tw-w-5 tw-p-0 tw-inline-block tw-mb-0;
    border-radius: 4px !important;
}

.form--newsletter a {
    @apply tw-underline;
}

.form--newsletter .ff-form-errors {
    @apply tw-mb-6 tw-text-red tw-text-sm;
}

.form--newsletter .ff-has-errors.form__input--checkbox {
    @apply tw-border-red;
}

.form--newsletter .ff-has-errors~.ff-errors {
    @apply tw-hidden;
}

/* Alpine helper */
[x-cloak] {
    display: none !important;
}

html {
    scroll-padding-top: 200px;
}

.accessibility-calm-mode-active {
    filter: saturate(0.5);
}

.accessibility-high-contrast *:not(svg):not(img):not(.no-high-contrast):not(.no-high-contrast *) {
    background: var(--high-contrast-bg-color) !important;
    color: var(--high-contrast-text-color) !important;
}
.accessibility-high-contrast input:not(.no-high-contrast):not(.no-high-contrast *),
.accessibility-high-contrast a:not(.no-high-contrast):not(.no-high-contrast *),
.accessibility-high-contrast button:not(.no-high-contrast):not(.no-high-contrast *) {
    border: solid 2px var(--high-contrast-text-color) !important;
}
.accessibility-high-contrast ::placeholder {
    color: var(--high-contrast-text-color) !important;
}

.accessibility-no-animations-active * {
    transition: none !important;
    animation: none !important;
}

.accessibility-dyslexic-mode-active,
.accessibility-dyslexic-mode-active * {
    font-family: 'Open-Dyslexic', sans-serif !important;
}

.accessibility-underline-links-active a,
.accessibility-underline-links-active a * {
    text-decoration: underline !important;
}

.accessibility-readable-fonts-active:not(.force-font-family),
.accessibility-readable-fonts-active:not(.force-font-family) *:not(.force-font-family) {
    font-family: 'Open Sans', sans-serif !important;
}

/* purgecss end ignore */

@tailwind utilities;
