.aside {
    &-content {
        display: flex;
        &__media {
            flex: 0 0 70px;
        }

        &__copy {
            padding-right: 15px;
        }

        &__logo {
            margin-bottom: map-get($spacing, m);
        }
    }
}
