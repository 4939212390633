.related-card {
    position: relative;
    display: inline-block;
    height: 280px;
    width: calc(100% - 10px);
    margin: 5px;

    @include media(">m") {
        width: calc(50% - 10px);
    }

    @include media(">l") {
        width: calc(33.3% - 10px);
    }

    &__image {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
    }

    &__overlay {
        position: absolute;
        bottom: map-get($spacing, m);
        width: 60%;
        right: 0;
        bottom: 0;
        padding: map-get($spacing, m);
    }

    &__title {
        font-size: map-get($fontSize, m);
        font-family: $fontFamilyStandardBold;
        color: map-get($colour, startL);
        margin-bottom: 5px;
    }

    &__link {
        color: map-get($colour, startL);
        text-decoration: underline;
    }

    &--green {
        .related-card__overlay {
            background: map-get($colour, greenS);
        }
    }
}
