@import "//hello.myfonts.net/count/39858b";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #eaeaea;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

[type="button"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

[type="reset"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

[type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-ms-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.tw-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.tw-prose :where(p):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.tw-prose :where([class~="lead"]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.tw-prose :where(a):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: bold;
  text-decoration: underline;
}

.tw-prose :where(strong):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.tw-prose :where(a strong):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(blockquote strong):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(thead th strong):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(ol):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.tw-prose :where(ol[type="A"]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.tw-prose :where(ol[type="a"]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.tw-prose :where(ol[type="A" s]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.tw-prose :where(ol[type="a" s]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.tw-prose :where(ol[type="I"]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: upper-roman;
}

.tw-prose :where(ol[type="i"]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: lower-roman;
}

.tw-prose :where(ol[type="I" s]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: upper-roman;
}

.tw-prose :where(ol[type="i" s]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: lower-roman;
}

.tw-prose :where(ol[type="1"]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  list-style-type: decimal;
}

.tw-prose :where(ul):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.tw-prose :where(ol > li):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.tw-prose :where(ul > li):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.tw-prose :where(dt):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.tw-prose :where(hr):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.tw-prose :where(blockquote):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.tw-prose :where(blockquote p:first-of-type):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)):before {
  content: open-quote;
}

.tw-prose :where(blockquote p:last-of-type):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)):after {
  content: close-quote;
}

.tw-prose :where(h1):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.tw-prose :where(h1 strong):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.tw-prose :where(h2):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.tw-prose :where(h2 strong):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.tw-prose :where(h3):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 3em;
  margin-bottom: .6em;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.6;
}

.tw-prose :where(h3 strong):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.tw-prose :where(h4):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.tw-prose :where(h4 strong):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.tw-prose :where(img):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(picture):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.tw-prose :where(kbd):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  border-radius: .3125rem;
  padding: .1875em .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.tw-prose :where(code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.tw-prose :where(code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)):before {
  content: "`";
}

.tw-prose :where(code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)):after {
  content: "`";
}

.tw-prose :where(a code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(h1 code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(h2 code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.tw-prose :where(h3 code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.tw-prose :where(h4 code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(blockquote code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(thead th code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(pre):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.tw-prose :where(pre code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.tw-prose :where(pre code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)):before {
  content: none;
}

.tw-prose :where(pre code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)):after {
  content: none;
}

.tw-prose :where(table):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.tw-prose :where(thead):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.tw-prose :where(thead th):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.tw-prose :where(tbody tr):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.tw-prose :where(tbody tr:last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  border-bottom-width: 0;
}

.tw-prose :where(tbody td):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  vertical-align: baseline;
}

.tw-prose :where(tfoot):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.tw-prose :where(tfoot td):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  vertical-align: top;
}

.tw-prose :where(figure > *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose :where(figcaption):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.tw-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgba(0, 0, 0, .5);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.tw-prose :where(picture > img):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose :where(video):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(li):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.tw-prose :where(ol > li):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-left: .375em;
}

.tw-prose :where(ul > li):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-left: .375em;
}

.tw-prose :where(.tw-prose > ul > li p):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.tw-prose :where(.tw-prose > ul > li > :first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
}

.tw-prose :where(.tw-prose > ul > li > :last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.tw-prose :where(.tw-prose > ol > li > :first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
}

.tw-prose :where(.tw-prose > ol > li > :last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.tw-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.tw-prose :where(dl):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.tw-prose :where(dd):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.625em;
}

.tw-prose :where(hr + *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h2 + *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h3 + *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h4 + *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(thead th:first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose :where(thead th:last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose :where(tbody td, tfoot td):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding: .571429em;
}

.tw-prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose :where(figure):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(.tw-prose > :first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(.tw-prose > :last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-bottom: 0;
}

.tw-prose {
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica;
}

.tw-prose-sm {
  font-size: 1rem;
  line-height: 1.71429;
}

.tw-prose-sm :where(p):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.tw-prose-sm :where([class~="lead"]):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.tw-prose-sm :where(blockquote):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.11111em;
}

.tw-prose-sm :where(h1):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .8em;
  font-size: 2.14286em;
  line-height: 1.2;
}

.tw-prose-sm :where(h2):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: .8em;
  font-size: 1.42857em;
  line-height: 1.4;
}

.tw-prose-sm :where(h3):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .444444em;
  font-size: 1.5rem;
  line-height: 1.55556;
}

.tw-prose-sm :where(h4):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.42857em;
  margin-bottom: .571429em;
  line-height: 1.42857;
}

.tw-prose-sm :where(img):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.tw-prose-sm :where(picture):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.tw-prose-sm :where(picture > img):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose-sm :where(video):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.tw-prose-sm :where(kbd):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  border-radius: .3125rem;
  padding: .142857em .357143em;
  font-size: .857143em;
}

.tw-prose-sm :where(code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  font-size: .857143em;
}

.tw-prose-sm :where(h2 code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  font-size: .9em;
}

.tw-prose-sm :where(h3 code):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  font-size: .888889em;
}

.tw-prose-sm :where(pre):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  border-radius: .25rem;
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding: .666667em 1em;
  font-size: .857143em;
  line-height: 1.66667;
}

.tw-prose-sm :where(ol):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
  padding-left: 1.57143em;
}

.tw-prose-sm :where(ul):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
  padding-left: 1.57143em;
}

.tw-prose-sm :where(li):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .285714em;
  margin-bottom: .285714em;
}

.tw-prose-sm :where(ol > li):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-left: .428571em;
}

.tw-prose-sm :where(ul > li):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-left: .428571em;
}

.tw-prose-sm :where(.tw-prose-sm > ul > li p):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.tw-prose-sm :where(.tw-prose-sm > ul > li > :first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.14286em;
}

.tw-prose-sm :where(.tw-prose-sm > ul > li > :last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.tw-prose-sm :where(.tw-prose-sm > ol > li > :first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.14286em;
}

.tw-prose-sm :where(.tw-prose-sm > ol > li > :last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.tw-prose-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.tw-prose-sm :where(dl):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.tw-prose-sm :where(dt):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.14286em;
}

.tw-prose-sm :where(dd):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .285714em;
  padding-left: 1.57143em;
}

.tw-prose-sm :where(hr):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 2.85714em;
  margin-bottom: 2.85714em;
}

.tw-prose-sm :where(hr + *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-sm :where(h2 + *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-sm :where(h3 + *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-sm :where(h4 + *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-sm :where(table):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  font-size: .857143em;
  line-height: 1.5;
}

.tw-prose-sm :where(thead th):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-bottom: .666667em;
  padding-left: 1em;
  padding-right: 1em;
}

.tw-prose-sm :where(thead th:first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose-sm :where(thead th:last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose-sm :where(tbody td, tfoot td):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding: .666667em 1em;
}

.tw-prose-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose-sm :where(figure):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.tw-prose-sm :where(figure > *):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose-sm :where(figcaption):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: .666667em;
  font-size: .857143em;
  line-height: 1.33333;
}

.tw-prose-sm :where(.tw-prose-sm > :first-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-sm :where(.tw-prose-sm > :last-child):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-bottom: 0;
}

.tw-prose-darkGrey {
  color: #535353;
}

.tw-prose-darkGrey :where(h3):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: #000;
}

.tw-prose-darkGrey :where(a):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: #00a04a;
  text-decoration: underline;
  transition: color .2s ease-in-out;
}

.tw-prose-darkGrey :where(a):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)):hover {
  color: #000;
}

.tw-prose-black {
  color: #000;
}

.tw-prose-black :where(h3):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: #000;
}

.tw-prose-black :where(a):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: #00a04a;
  text-decoration: underline;
  transition: color .2s ease-in-out;
}

.tw-prose-black :where(a):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)):hover {
  color: #000;
}

.tw-prose-white {
  color: #fff;
}

.tw-prose-white :where(h3):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: #fff;
}

.tw-prose-white :where(a):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  color: #fff;
  text-decoration: underline;
}

.tw-prose-format :where(a):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  text-decoration: underline;
}

.tw-prose-format :where(p):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  margin-bottom: 1em;
  line-height: 1.5;
}

.tw-prose-format :where(em):not(:where([class~="tw-not-prose"], [class~="tw-not-prose"] *)) {
  font-style: italic;
}

select {
  background-image: none !important;
}

details summary::-webkit-details-marker {
  display: none;
}

.tw-service-prose p {
  line-height: 1.5;
}

.tw-service-prose a {
  text-decoration-line: underline;
}

.tw-service-prose > * + *, .tw-service-prose > ul > li + li, .tw-service-prose > ol > li + li {
  margin-top: 1.25rem;
}

.tw-checkbox-wrapper {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
  position: relative;
}

.tw-checkbox-wrapper label {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  padding: 4px 0 5px 46px;
  display: block;
}

.tw-checkbox-wrapper input {
  opacity: 0;
  z-index: 10;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.tw-checkbox-wrapper input + label:before {
  content: "";
  border: 1px solid #dedede;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.tw-checkbox-wrapper input + label:after {
  content: "";
  opacity: 0;
  background-image: url("check-black.5ba0dc32.svg");
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: 9px;
  left: 8px;
}

.tw-checkbox-wrapper input:checked + label:after {
  opacity: 1;
}

.tw-page-builder-container-sm, .tw-page-builder-container-lg {
  width: 100%;
  max-width: calc(1140px + 2rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .tw-page-builder-container-sm, .tw-page-builder-container-lg {
    max-width: 1220px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1024px) {
  .tw-page-builder-container-sm {
    max-width: 578px;
    padding-left: 0;
    padding-right: 0;
  }

  .tw-page-builder-container-lg {
    max-width: 1280px;
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: 1280px) {
  .tw-page-builder-container-sm {
    max-width: 746px;
  }
}

.tw-custom-scrollbar::-webkit-scrollbar {
  width: .5em;
  height: .5em;
}

.tw-custom-scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.tw-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #000;
  border: 0;
  border-radius: 0;
}

.tw-prose-black {
  --tw-prose-bullets: #000;
  --tw-prose-counters: #000;
  --tw-prose-bold: #000;
}

.tw-prose-darkGrey {
  --tw-prose-bullets: #535353;
  --tw-prose-counters: #535353;
  --tw-prose-bold: #535353;
}

.tw-prose-white {
  --tw-prose-bullets: #fff;
  --tw-prose-counters: #fff;
  --tw-prose-bold: #fff;
}

.tw-prose-black a:hover, .tw-prose-darkGrey a:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.timeline-popup.fancybox-content {
  height: 100%;
  max-height: 500px;
  overflow: auto;
  padding: 0 !important;
}

.timeline-entry {
  margin-bottom: 4rem !important;
  padding-left: 1.75rem !important;
  display: block !important;
  position: relative !important;
}

@media (min-width: 768px) {
  .timeline-entry {
    width: 50% !important;
  }
}

.timeline-entry--right {
  float: right !important;
  clear: right !important;
  padding-left: 1.5rem !important;
}

@media (min-width: 768px) {
  .timeline-entry--right > svg {
    --tw-translate-x: -23px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }
}

.timeline-entry--left {
  float: left !important;
  clear: left !important;
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

@media (min-width: 768px) {
  .timeline-entry--left > svg {
    --tw-translate-x: 27px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    left: auto !important;
    right: 0 !important;
  }
}

.form--newsletter button {
  height: auto;
  padding: 8px 12px 10px;
  font-family: HelveticaNeue-Medium, Helvetica Neue Medium, Helvetica Neue, Helvetica;
  font-weight: 500;
  border-radius: 40px !important;
}

.form--newsletter .form__input--checkbox, .form--newsletter .form__input--mailing_list {
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  border-radius: 4px !important;
}

.form--newsletter a {
  text-decoration-line: underline;
}

.form--newsletter .ff-form-errors {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.form--newsletter .ff-has-errors.form__input--checkbox {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.form--newsletter .ff-has-errors ~ .ff-errors {
  display: none;
}

[x-cloak] {
  display: none !important;
}

html {
  scroll-padding-top: 200px;
}

.accessibility-calm-mode-active {
  -webkit-filter: saturate(.5);
  filter: saturate(.5);
}

.accessibility-high-contrast :not(svg):not(img):not(.no-high-contrast):not(.no-high-contrast *) {
  background: var(--high-contrast-bg-color) !important;
  color: var(--high-contrast-text-color) !important;
}

.accessibility-high-contrast input:not(.no-high-contrast):not(.no-high-contrast *), .accessibility-high-contrast a:not(.no-high-contrast):not(.no-high-contrast *), .accessibility-high-contrast button:not(.no-high-contrast):not(.no-high-contrast *) {
  border: solid 2px var(--high-contrast-text-color) !important;
}

.accessibility-high-contrast ::-webkit-input-placeholder {
  color: var(--high-contrast-text-color) !important;
}

.accessibility-high-contrast ::-ms-input-placeholder {
  color: var(--high-contrast-text-color) !important;
}

.accessibility-high-contrast ::placeholder {
  color: var(--high-contrast-text-color) !important;
}

.accessibility-no-animations-active * {
  transition: none !important;
  animation: none !important;
}

.accessibility-dyslexic-mode-active, .accessibility-dyslexic-mode-active * {
  font-family: Open-Dyslexic, sans-serif !important;
}

.accessibility-underline-links-active a, .accessibility-underline-links-active a * {
  text-decoration: underline !important;
}

.accessibility-readable-fonts-active:not(.force-font-family), .accessibility-readable-fonts-active:not(.force-font-family) :not(.force-font-family) {
  font-family: Open Sans, sans-serif !important;
}

.tw-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.tw-pointer-events-none {
  pointer-events: none !important;
}

.tw-pointer-events-auto {
  pointer-events: auto !important;
}

.tw-static {
  position: static !important;
}

.tw-fixed {
  position: fixed !important;
}

.tw-absolute {
  position: absolute !important;
}

.tw-relative {
  position: relative !important;
}

.tw-inset-0 {
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.-tw-left-4 {
  left: -1rem !important;
}

.-tw-top-0 {
  top: 0 !important;
}

.tw-bottom-0 {
  bottom: 0 !important;
}

.tw-bottom-\[-7px\] {
  bottom: -7px !important;
}

.tw-left-0 {
  left: 0 !important;
}

.tw-left-2 {
  left: .5rem !important;
}

.tw-left-5 {
  left: 1.25rem !important;
}

.tw-right-0 {
  right: 0 !important;
}

.tw-right-3 {
  right: .75rem !important;
}

.tw-right-5 {
  right: 1.25rem !important;
}

.tw-top-0 {
  top: 0 !important;
}

.tw-top-1\/2 {
  top: 50% !important;
}

.tw-top-24 {
  top: 6rem !important;
}

.tw-top-6 {
  top: 1.5rem !important;
}

.tw-top-\[96px\] {
  top: 96px !important;
}

.tw-top-\[98px\] {
  top: 98px !important;
}

.tw-isolate {
  isolation: isolate !important;
}

.tw-z-0 {
  z-index: 0 !important;
}

.tw-z-10 {
  z-index: 10 !important;
}

.tw-z-20 {
  z-index: 20 !important;
}

.tw-z-40 {
  z-index: 40 !important;
}

.tw-z-50 {
  z-index: 50 !important;
}

.tw-z-\[60\] {
  z-index: 60 !important;
}

.tw-z-\[70\] {
  z-index: 70 !important;
}

.tw-z-\[999999999\] {
  z-index: 999999999 !important;
}

.tw-z-\[99999\] {
  z-index: 99999 !important;
}

.tw-order-1 {
  order: 1 !important;
}

.tw-order-2 {
  order: 2 !important;
}

.tw-order-first {
  order: -9999 !important;
}

.tw-col-span-1 {
  grid-column: span 1 / span 1 !important;
}

.tw-col-span-12 {
  grid-column: span 12 / span 12 !important;
}

.tw-col-span-2 {
  grid-column: span 2 / span 2 !important;
}

.tw-col-span-4 {
  grid-column: span 4 / span 4 !important;
}

.tw-row-start-1 {
  grid-row-start: 1 !important;
}

.tw-clear-both {
  clear: both !important;
}

.\!tw-my-\[80px\] {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.tw-mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.tw-my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.tw-my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.tw-my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.tw-my-\[120px\] {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.\!tw-mt-\[80px\] {
  margin-top: 80px !important;
}

.tw--mb-16 {
  margin-bottom: -4rem !important;
}

.tw-mb-0 {
  margin-bottom: 0 !important;
}

.tw-mb-1 {
  margin-bottom: .25rem !important;
}

.tw-mb-10 {
  margin-bottom: 2.5rem !important;
}

.tw-mb-16 {
  margin-bottom: 4rem !important;
}

.tw-mb-2 {
  margin-bottom: .5rem !important;
}

.tw-mb-20 {
  margin-bottom: 5rem !important;
}

.tw-mb-3 {
  margin-bottom: .75rem !important;
}

.tw-mb-4 {
  margin-bottom: 1rem !important;
}

.tw-mb-5 {
  margin-bottom: 1.25rem !important;
}

.tw-mb-6 {
  margin-bottom: 1.5rem !important;
}

.tw-mb-8 {
  margin-bottom: 2rem !important;
}

.tw-mb-\[120px\] {
  margin-bottom: 120px !important;
}

.tw-mb-\[20vh\] {
  margin-bottom: 20vh !important;
}

.tw-mb-\[24px\] {
  margin-bottom: 24px !important;
}

.tw-mb-\[30px\] {
  margin-bottom: 30px !important;
}

.tw-mb-\[60px\] {
  margin-bottom: 60px !important;
}

.tw-ml-2 {
  margin-left: .5rem !important;
}

.tw-ml-2\.5 {
  margin-left: .625rem !important;
}

.tw-ml-5 {
  margin-left: 1.25rem !important;
}

.tw-ml-6 {
  margin-left: 1.5rem !important;
}

.tw-ml-auto {
  margin-left: auto !important;
}

.tw-mr-2 {
  margin-right: .5rem !important;
}

.tw-mr-\[4px\] {
  margin-right: 4px !important;
}

.tw-mr-auto {
  margin-right: auto !important;
}

.tw-ms-3:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .75rem;
}

.tw-ms-3:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .75rem;
}

.tw-ms-3:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .75rem;
}

.tw-ms-3:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .75rem;
}

.tw-mt-0 {
  margin-top: 0 !important;
}

.tw-mt-10 {
  margin-top: 2.5rem !important;
}

.tw-mt-12 {
  margin-top: 3rem !important;
}

.tw-mt-16 {
  margin-top: 4rem !important;
}

.tw-mt-2 {
  margin-top: .5rem !important;
}

.tw-mt-20 {
  margin-top: 5rem !important;
}

.tw-mt-24 {
  margin-top: 6rem !important;
}

.tw-mt-4 {
  margin-top: 1rem !important;
}

.tw-mt-5 {
  margin-top: 1.25rem !important;
}

.tw-mt-6 {
  margin-top: 1.5rem !important;
}

.tw-mt-7 {
  margin-top: 1.75rem !important;
}

.tw-mt-8 {
  margin-top: 2rem !important;
}

.tw-mt-\[120px\] {
  margin-top: 120px !important;
}

.tw-mt-\[164px\] {
  margin-top: 164px !important;
}

.tw-mt-\[58px\] {
  margin-top: 58px !important;
}

.tw-mt-\[60px\] {
  margin-top: 60px !important;
}

.tw-mt-\[80px\] {
  margin-top: 80px !important;
}

.tw-block {
  display: block !important;
}

.tw-inline-block {
  display: inline-block !important;
}

.tw-inline {
  display: inline !important;
}

.tw-flex {
  display: flex !important;
}

.tw-inline-flex {
  display: inline-flex !important;
}

.tw-grid {
  display: grid !important;
}

.tw-hidden {
  display: none !important;
}

.tw-aspect-\[4\/3\] {
  aspect-ratio: 4 / 3 !important;
}

.tw-aspect-square {
  aspect-ratio: 1 !important;
}

.tw-aspect-video {
  aspect-ratio: 16 / 9 !important;
}

.\!tw-h-full {
  height: 100% !important;
}

.tw-h-0 {
  height: 0 !important;
}

.tw-h-1 {
  height: .25rem !important;
}

.tw-h-1\/2 {
  height: 50% !important;
}

.tw-h-10 {
  height: 2.5rem !important;
}

.tw-h-14 {
  height: 3.5rem !important;
}

.tw-h-16 {
  height: 4rem !important;
}

.tw-h-3 {
  height: .75rem !important;
}

.tw-h-4 {
  height: 1rem !important;
}

.tw-h-5 {
  height: 1.25rem !important;
}

.tw-h-6 {
  height: 1.5rem !important;
}

.tw-h-\[200px\] {
  height: 200px !important;
}

.tw-h-\[350px\] {
  height: 350px !important;
}

.tw-h-\[60vw\] {
  height: 60vw !important;
}

.tw-h-\[72px\] {
  height: 72px !important;
}

.tw-h-\[calc\(100vh-106px\)\] {
  height: calc(100vh - 106px) !important;
}

.tw-h-\[calc\(100vw-2rem\)\] {
  height: calc(100vw - 2rem) !important;
}

.tw-h-auto {
  height: auto !important;
}

.tw-h-full {
  height: 100% !important;
}

.tw-h-hero-mob {
  height: 600px !important;
}

.tw-h-screen {
  height: 100vh !important;
}

.tw-max-h-\[400px\] {
  max-height: 400px !important;
}

.tw-max-h-\[80px\] {
  max-height: 80px !important;
}

.tw-max-h-\[calc\(100dvh-5rem\)\] {
  max-height: calc(100dvh - 5rem) !important;
}

.tw-min-h-\[500px\] {
  min-height: 500px !important;
}

.tw-min-h-\[50px\] {
  min-height: 50px !important;
}

.tw-min-h-\[600px\] {
  min-height: 600px !important;
}

.tw-min-h-\[75vh\] {
  min-height: 75vh !important;
}

.\!tw-w-10 {
  width: 2.5rem !important;
}

.\!tw-w-\[70px\] {
  width: 70px !important;
}

.\!tw-w-auto {
  width: auto !important;
}

.\!tw-w-full {
  width: 100% !important;
}

.tw-w-10 {
  width: 2.5rem !important;
}

.tw-w-11 {
  width: 2.75rem !important;
}

.tw-w-3 {
  width: .75rem !important;
}

.tw-w-3\/5 {
  width: 60% !important;
}

.tw-w-4 {
  width: 1rem !important;
}

.tw-w-4\/12 {
  width: 33.3333% !important;
}

.tw-w-5 {
  width: 1.25rem !important;
}

.tw-w-6 {
  width: 1.5rem !important;
}

.tw-w-8 {
  width: 2rem !important;
}

.tw-w-\[120px\] {
  width: 120px !important;
}

.tw-w-\[2px\] {
  width: 2px !important;
}

.tw-w-\[60px\] {
  width: 60px !important;
}

.tw-w-\[87px\] {
  width: 87px !important;
}

.tw-w-\[90px\] {
  width: 90px !important;
}

.tw-w-\[92px\] {
  width: 92px !important;
}

.tw-w-\[calc\(100vw-20px\)\] {
  width: calc(100vw - 20px) !important;
}

.tw-w-\[calc\(90\%-0\.6rem\)\] {
  width: calc(90% - .6rem) !important;
}

.tw-w-auto {
  width: auto !important;
}

.tw-w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.tw-w-full {
  width: 100% !important;
}

.tw-min-w-\[150px\] {
  min-width: 150px !important;
}

.tw-min-w-\[200px\] {
  min-width: 200px !important;
}

.\!tw-max-w-\[35px\] {
  max-width: 35px !important;
}

.tw-max-w-2xl {
  max-width: 42rem !important;
}

.tw-max-w-3xl {
  max-width: 48rem !important;
}

.tw-max-w-4xl {
  max-width: 56rem !important;
}

.tw-max-w-\[1140px\] {
  max-width: 1140px !important;
}

.tw-max-w-\[1200px\] {
  max-width: 1200px !important;
}

.tw-max-w-\[1380px\] {
  max-width: 1380px !important;
}

.tw-max-w-\[1920px\] {
  max-width: 1920px !important;
}

.tw-max-w-\[233px\] {
  max-width: 233px !important;
}

.tw-max-w-\[250px\] {
  max-width: 250px !important;
}

.tw-max-w-\[300px\] {
  max-width: 300px !important;
}

.tw-max-w-\[340px\] {
  max-width: 340px !important;
}

.tw-max-w-\[37em\] {
  max-width: 37em !important;
}

.tw-max-w-\[40em\] {
  max-width: 40em !important;
}

.tw-max-w-\[47em\] {
  max-width: 47em !important;
}

.tw-max-w-\[640px\] {
  max-width: 640px !important;
}

.tw-max-w-\[750px\] {
  max-width: 750px !important;
}

.tw-max-w-\[875px\] {
  max-width: 875px !important;
}

.tw-max-w-full {
  max-width: 100% !important;
}

.tw-max-w-none {
  max-width: none !important;
}

.tw-max-w-xl {
  max-width: 36rem !important;
}

.tw-flex-1 {
  flex: 1 !important;
}

.tw-flex-shrink-0, .tw-shrink-0 {
  flex-shrink: 0 !important;
}

.tw-flex-grow {
  flex-grow: 1 !important;
}

.tw-flex-grow-0 {
  flex-grow: 0 !important;
}

.-tw-translate-x-4 {
  --tw-translate-x: -1rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-4 {
  --tw-translate-y: -1rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-0 {
  --tw-translate-y: -0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-0\.5 {
  --tw-translate-y: -.125rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-16 {
  --tw-translate-y: -4rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-0 {
  --tw-translate-x: 0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-5 {
  --tw-translate-x: 1.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-\[-50\%\] {
  --tw-translate-y: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-\[calc\(100\%\+1px\)\] {
  --tw-translate-y: calc(100% + 1px) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-3 {
  --tw-rotate: -3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-6 {
  --tw-rotate: -6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-0 {
  --tw-rotate: 0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-180 {
  --tw-rotate: 180deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-3 {
  --tw-rotate: 3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-6 {
  --tw-rotate: 6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-\[0\.95\] {
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-\[1\.1\] {
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-\[1\.2\] {
  --tw-scale-x: 1.2 !important;
  --tw-scale-y: 1.2 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-\[1\.3\] {
  --tw-scale-x: 1.3 !important;
  --tw-scale-y: 1.3 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-cursor-pointer {
  cursor: pointer !important;
}

.tw-snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness) !important;
}

.tw-appearance-none {
  -webkit-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.tw-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.tw-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.tw-grid-cols-site-header-sm {
  grid-template-columns: min-content 1fr min-content !important;
}

.tw-flex-row {
  flex-direction: row !important;
}

.tw-flex-col {
  flex-direction: column !important;
}

.tw-flex-nowrap {
  flex-wrap: nowrap !important;
}

.tw-items-start {
  align-items: flex-start !important;
}

.tw-items-end {
  align-items: flex-end !important;
}

.tw-items-center {
  align-items: center !important;
}

.tw-justify-start {
  justify-content: flex-start !important;
}

.tw-justify-end {
  justify-content: flex-end !important;
}

.tw-justify-center {
  justify-content: center !important;
}

.tw-justify-between {
  justify-content: space-between !important;
}

.tw-gap-1 {
  gap: .25rem !important;
}

.tw-gap-10 {
  gap: 2.5rem !important;
}

.tw-gap-2 {
  gap: .5rem !important;
}

.tw-gap-3 {
  gap: .75rem !important;
}

.tw-gap-4 {
  gap: 1rem !important;
}

.tw-gap-5 {
  gap: 1.25rem !important;
}

.tw-gap-8 {
  gap: 2rem !important;
}

.tw-gap-x-10 {
  column-gap: 2.5rem !important;
}

.tw-gap-x-16 {
  column-gap: 4rem !important;
}

.tw-gap-x-8 {
  column-gap: 2rem !important;
}

.tw-gap-x-9 {
  column-gap: 2.25rem !important;
}

.tw-gap-y-20 {
  row-gap: 5rem !important;
}

.tw-gap-y-5 {
  row-gap: 1.25rem !important;
}

.tw-gap-y-8 {
  row-gap: 2rem !important;
}

.tw-gap-y-\[120px\] {
  row-gap: 120px !important;
}

.tw-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-\[10px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(10px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-\[10px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(10px * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-\[120px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(120px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(120px * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-\[12px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(12px * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-\[14px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(14px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(14px * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-\[28px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(28px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(28px * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-\[40px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(40px * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-\[52px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(52px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(52px * var(--tw-space-y-reverse)) !important;
}

.tw-self-center {
  align-self: center !important;
}

.tw-justify-self-end {
  justify-self: end !important;
}

.tw-overflow-auto {
  overflow: auto !important;
}

.tw-overflow-hidden {
  overflow: hidden !important;
}

.tw-overflow-visible {
  overflow: visible !important;
}

.tw-overflow-y-auto {
  overflow-y: auto !important;
}

.tw-overflow-x-scroll {
  overflow-x: scroll !important;
}

.tw-whitespace-nowrap {
  white-space: nowrap !important;
}

.tw-break-words {
  overflow-wrap: break-word !important;
}

.\!tw-rounded-\[12px\] {
  border-radius: 12px !important;
}

.tw-rounded {
  border-radius: .25rem !important;
}

.tw-rounded-2xl {
  border-radius: 1rem !important;
}

.tw-rounded-\[0\.25em\] {
  border-radius: .25em !important;
}

.tw-rounded-\[0\.5em\] {
  border-radius: .5em !important;
}

.tw-rounded-\[12px\] {
  border-radius: 12px !important;
}

.tw-rounded-\[36px\] {
  border-radius: 36px !important;
}

.tw-rounded-\[4px\] {
  border-radius: 4px !important;
}

.tw-rounded-\[5px\] {
  border-radius: 5px !important;
}

.tw-rounded-button {
  border-radius: 40px !important;
}

.tw-rounded-full {
  border-radius: 9999px !important;
}

.tw-rounded-lg {
  border-radius: .5rem !important;
}

.tw-rounded-md {
  border-radius: .375rem !important;
}

.tw-rounded-sm {
  border-radius: .125rem !important;
}

.tw-rounded-xl {
  border-radius: .75rem !important;
}

.tw-rounded-b-lg {
  border-bottom-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.tw-rounded-l-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.tw-rounded-l-md {
  border-top-left-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.tw-rounded-r-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.tw-rounded-r-md {
  border-top-right-radius: .375rem !important;
  border-bottom-right-radius: .375rem !important;
}

.tw-rounded-t {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.tw-rounded-t-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.tw-rounded-t-\[1em\] {
  border-top-left-radius: 1em !important;
  border-top-right-radius: 1em !important;
}

.tw-border {
  border-width: 1px !important;
}

.tw-border-2 {
  border-width: 2px !important;
}

.tw-border-x-0 {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.tw-border-y {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.tw-border-b {
  border-bottom-width: 1px !important;
}

.tw-border-b-2 {
  border-bottom-width: 2px !important;
}

.tw-border-t {
  border-top-width: 1px !important;
}

.tw-border-t-0 {
  border-top-width: 0 !important;
}

.tw-border-solid {
  border-style: solid !important;
}

.tw-border-none {
  border-style: none !important;
}

.tw-border-\[\#E6E6E6\] {
  --tw-border-opacity: 1 !important;
  border-color: rgba(230, 230, 230, var(--tw-border-opacity)) !important;
}

.tw-border-\[\#f5f5f5\] {
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity)) !important;
}

.tw-border-\[rgba\(0\,0\,0\,0\.1\)\] {
  border-color: rgba(0, 0, 0, .1) !important;
}

.tw-border-black {
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.tw-border-black\/20 {
  border-color: rgba(0, 0, 0, .2) !important;
}

.tw-border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(234, 234, 234, var(--tw-border-opacity)) !important;
}

.tw-border-green {
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 160, 74, var(--tw-border-opacity)) !important;
}

.tw-border-green-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(58, 152, 82, var(--tw-border-opacity)) !important;
}

.tw-border-transparent {
  border-color: rgba(0, 0, 0, 0) !important;
}

.tw-border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.tw-border-white\/25 {
  border-color: rgba(255, 255, 255, .25) !important;
}

.tw-border-opacity-25 {
  --tw-border-opacity: .25 !important;
}

.tw-border-opacity-5 {
  --tw-border-opacity: .05 !important;
}

.tw-bg-\[\#0000FF\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 255, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#009C49\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 156, 73, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#019C49\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(1, 156, 73, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#1500FF\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(21, 0, 255, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#1D1D1D\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 29, 29, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#242424\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(36, 36, 36, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#282828\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(40, 40, 40, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#4d4d4d\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(77, 77, 77, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#6EF7F7\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 247, 247, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#E1E1E1\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(225, 225, 225, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#F5F5F5\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#FCED1F\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 237, 31, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#e6e6e6\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(230, 230, 230, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#f5f5f5\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[rgba\(0\,0\,0\,0\.9\)\] {
  background-color: rgba(0, 0, 0, .9) !important;
}

.tw-bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.tw-bg-black\/10 {
  background-color: rgba(0, 0, 0, .1) !important;
}

.tw-bg-black\/50 {
  background-color: rgba(0, 0, 0, .5) !important;
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(234, 234, 234, var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 167, 167, var(--tw-bg-opacity)) !important;
}

.tw-bg-green {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 160, 74, var(--tw-bg-opacity)) !important;
}

.tw-bg-green-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(58, 152, 82, var(--tw-bg-opacity)) !important;
}

.tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.tw-bg-opacity-10 {
  --tw-bg-opacity: .1 !important;
}

.tw-bg-opacity-70 {
  --tw-bg-opacity: .7 !important;
}

.tw-bg-opacity-80 {
  --tw-bg-opacity: .8 !important;
}

.tw-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
}

.tw-from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgba(0, 0, 0, 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position) !important;
}

.tw-bg-cover {
  background-size: cover !important;
}

.tw-bg-clip-text {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.tw-bg-no-repeat {
  background-repeat: no-repeat !important;
}

.tw-object-cover {
  object-fit: cover !important;
}

.tw-object-center {
  object-position: center !important;
}

.tw-p-0 {
  padding: 0 !important;
}

.tw-p-10 {
  padding: 2.5rem !important;
}

.tw-p-16 {
  padding: 4rem !important;
}

.tw-p-5 {
  padding: 1.25rem !important;
}

.tw-p-6 {
  padding: 1.5rem !important;
}

.tw-p-7 {
  padding: 1.75rem !important;
}

.tw-p-8 {
  padding: 2rem !important;
}

.tw-px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.tw-px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.tw-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.tw-px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.tw-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.tw-px-\[12px\] {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.tw-py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.tw-py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.tw-py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.tw-py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.tw-py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.tw-py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.tw-py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.tw-py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.tw-py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.tw-py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.tw-py-\[10px\] {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.tw-py-\[152px\] {
  padding-top: 152px !important;
  padding-bottom: 152px !important;
}

.tw-py-\[48px\] {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.tw-py-\[6px\] {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.tw-py-\[70px\] {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.tw-pb-10 {
  padding-bottom: 2.5rem !important;
}

.tw-pb-12 {
  padding-bottom: 3rem !important;
}

.tw-pb-16 {
  padding-bottom: 4rem !important;
}

.tw-pb-2 {
  padding-bottom: .5rem !important;
}

.tw-pb-24 {
  padding-bottom: 6rem !important;
}

.tw-pb-4 {
  padding-bottom: 1rem !important;
}

.tw-pb-5 {
  padding-bottom: 1.25rem !important;
}

.tw-pb-6 {
  padding-bottom: 1.5rem !important;
}

.tw-pb-8 {
  padding-bottom: 2rem !important;
}

.tw-pb-\[120px\] {
  padding-bottom: 120px !important;
}

.tw-pb-\[133\%\] {
  padding-bottom: 133% !important;
}

.tw-pb-\[55\.4\%\] {
  padding-bottom: 55.4% !important;
}

.tw-pb-\[63\.5\%\] {
  padding-bottom: 63.5% !important;
}

.tw-pl-0 {
  padding-left: 0 !important;
}

.tw-pl-10 {
  padding-left: 2.5rem !important;
}

.tw-pl-3 {
  padding-left: .75rem !important;
}

.tw-pl-5 {
  padding-left: 1.25rem !important;
}

.tw-pl-\[20px\] {
  padding-left: 20px !important;
}

.tw-pr-0 {
  padding-right: 0 !important;
}

.tw-pr-2 {
  padding-right: .5rem !important;
}

.tw-pr-5 {
  padding-right: 1.25rem !important;
}

.tw-pr-6 {
  padding-right: 1.5rem !important;
}

.tw-pr-\[74px\] {
  padding-right: 74px !important;
}

.tw-pt-1 {
  padding-top: .25rem !important;
}

.tw-pt-16 {
  padding-top: 4rem !important;
}

.tw-pt-2 {
  padding-top: .5rem !important;
}

.tw-pt-20 {
  padding-top: 5rem !important;
}

.tw-pt-32 {
  padding-top: 8rem !important;
}

.tw-pt-4 {
  padding-top: 1rem !important;
}

.tw-pt-5 {
  padding-top: 1.25rem !important;
}

.tw-pt-8 {
  padding-top: 2rem !important;
}

.tw-pt-\[118px\] {
  padding-top: 118px !important;
}

.tw-pt-\[12px\] {
  padding-top: 12px !important;
}

.tw-pt-\[60px\] {
  padding-top: 60px !important;
}

.tw-pt-\[81px\] {
  padding-top: 81px !important;
}

.tw-text-left {
  text-align: left !important;
}

.tw-text-center {
  text-align: center !important;
}

.tw-text-right {
  text-align: right !important;
}

.\!tw-font-body {
  font-family: HelveticaNeue, Helvetica Neue, Helvetica !important;
}

.tw-font-\[\'Open-Dyslexic\'\] {
  font-family: Open-Dyslexic !important;
}

.tw-font-body {
  font-family: HelveticaNeue, Helvetica Neue, Helvetica !important;
}

.tw-font-heading {
  font-family: ArialMTStd-Black, Arial Bold, Arial !important;
}

.tw-font-light {
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica !important;
}

.tw-font-medium {
  font-family: HelveticaNeue-Medium, Helvetica Neue Medium, Helvetica Neue, Helvetica !important;
}

.tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.tw-text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.tw-text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.tw-text-5xl {
  font-size: 3rem !important;
  line-height: 1 !important;
}

.tw-text-\[1\.5em\] {
  font-size: 1.5em !important;
}

.tw-text-\[10px\] {
  font-size: 10px !important;
}

.tw-text-\[14px\] {
  font-size: 14px !important;
}

.tw-text-\[16px\] {
  font-size: 16px !important;
}

.tw-text-\[20px\] {
  font-size: 20px !important;
}

.tw-text-\[24px\] {
  font-size: 24px !important;
}

.tw-text-\[28px\] {
  font-size: 28px !important;
}

.tw-text-\[32px\] {
  font-size: 32px !important;
}

.tw-text-\[34px\] {
  font-size: 34px !important;
}

.tw-text-\[36px\] {
  font-size: 36px !important;
}

.tw-text-\[40px\] {
  font-size: 40px !important;
}

.tw-text-\[48px\] {
  font-size: 48px !important;
}

.tw-text-\[52px\] {
  font-size: 52px !important;
}

.tw-text-\[70px\] {
  font-size: 70px !important;
}

.tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.tw-text-md {
  font-size: 1rem !important;
}

.tw-text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.tw-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.tw-text-xs {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.tw-text-xxs {
  font-size: .625rem !important;
  line-height: 1 !important;
}

.tw-font-bold {
  font-weight: 700 !important;
}

.tw-font-light {
  font-weight: 300 !important;
}

.tw-font-medium {
  font-weight: 500 !important;
}

.tw-font-semibold {
  font-weight: 600 !important;
}

.tw-uppercase {
  text-transform: uppercase !important;
}

.tw-capitalize {
  text-transform: capitalize !important;
}

.\!tw-leading-snug {
  line-height: 1.375 !important;
}

.\!tw-leading-tight {
  line-height: 1.25 !important;
}

.tw-leading-6 {
  line-height: 1.5rem !important;
}

.tw-leading-\[1\.1\] {
  line-height: 1.1 !important;
}

.tw-leading-\[1\.25\] {
  line-height: 1.25 !important;
}

.tw-leading-\[1\.2\] {
  line-height: 1.2 !important;
}

.tw-leading-\[1\.3\] {
  line-height: 1.3 !important;
}

.tw-leading-\[1\.5\] {
  line-height: 1.5 !important;
}

.tw-leading-\[1\.75\] {
  line-height: 1.75 !important;
}

.tw-leading-\[100\%\] {
  line-height: 100% !important;
}

.tw-leading-\[130\%\] {
  line-height: 130% !important;
}

.tw-leading-\[150\%\] {
  line-height: 150% !important;
}

.tw-leading-\[34px\] {
  line-height: 34px !important;
}

.tw-leading-\[85px\] {
  line-height: 85px !important;
}

.tw-leading-none {
  line-height: 1 !important;
}

.tw-leading-normal {
  line-height: 1.5 !important;
}

.tw-leading-snug {
  line-height: 1.375 !important;
}

.tw-leading-tight {
  line-height: 1.25 !important;
}

.tw-text-\[\#1500FF\] {
  --tw-text-opacity: 1 !important;
  color: rgba(21, 0, 255, var(--tw-text-opacity)) !important;
}

.tw-text-\[\#333333\] {
  --tw-text-opacity: 1 !important;
  color: rgba(51, 51, 51, var(--tw-text-opacity)) !important;
}

.tw-text-\[\#4a4a4a\] {
  --tw-text-opacity: 1 !important;
  color: rgba(74, 74, 74, var(--tw-text-opacity)) !important;
}

.tw-text-\[\#6EF7F7\] {
  --tw-text-opacity: 1 !important;
  color: rgba(110, 247, 247, var(--tw-text-opacity)) !important;
}

.tw-text-\[\#FCED1F\] {
  --tw-text-opacity: 1 !important;
  color: rgba(252, 237, 31, var(--tw-text-opacity)) !important;
}

.tw-text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.tw-text-black\/50 {
  color: rgba(0, 0, 0, .5) !important;
}

.tw-text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(68, 68, 68, var(--tw-text-opacity)) !important;
}

.tw-text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(36, 36, 36, var(--tw-text-opacity)) !important;
}

.tw-text-green {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 160, 74, var(--tw-text-opacity)) !important;
}

.tw-text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(58, 152, 82, var(--tw-text-opacity)) !important;
}

.tw-text-transparent {
  color: rgba(0, 0, 0, 0) !important;
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.tw-text-white\/50 {
  color: rgba(255, 255, 255, .5) !important;
}

.tw-text-white\/75 {
  color: rgba(255, 255, 255, .75) !important;
}

.tw-underline {
  text-decoration-line: underline !important;
}

.tw-underline-offset-4 {
  text-underline-offset: 4px !important;
}

.tw-placeholder-gray-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 167, 167, var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-300::-ms-input-placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 167, 167, var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 167, 167, var(--tw-placeholder-opacity)) !important;
}

.tw-opacity-0 {
  opacity: 0 !important;
}

.tw-opacity-100 {
  opacity: 1 !important;
}

.tw-opacity-50 {
  opacity: .5 !important;
}

.tw-opacity-70 {
  opacity: .7 !important;
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05) !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.tw-outline-offset-2 {
  outline-offset: 2px !important;
}

.tw-ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0)) !important;
}

.tw-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0)) !important;
}

.tw-ring-inset {
  --tw-ring-inset: inset !important;
}

.tw-ring-gray-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 167, 167, var(--tw-ring-opacity)) !important;
}

.tw-drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, .04)) drop-shadow(0 4px 3px rgba(0, 0, 0, .1)) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, .07)) drop-shadow(0 2px 2px rgba(0, 0, 0, .06)) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tw-transition-all {
  transition-property: all !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tw-transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tw-transition-opacity {
  transition-property: opacity !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tw-delay-100 {
  transition-delay: .1s !important;
}

.tw-duration-100 {
  transition-duration: .1s !important;
}

.tw-duration-150 {
  transition-duration: .15s !important;
}

.tw-duration-200 {
  transition-duration: .2s !important;
}

.tw-duration-300 {
  transition-duration: .3s !important;
}

.tw-duration-500 {
  transition-duration: .5s !important;
}

.tw-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tw-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.file\:tw-mr-5::-webkit-file-upload-button {
  margin-right: 1.25rem !important;
}

.file\:tw-mr-5::-ms-browse {
  margin-right: 1.25rem !important;
}

.file\:tw-mr-5::file-selector-button {
  margin-right: 1.25rem !important;
}

.file\:tw-cursor-pointer::-webkit-file-upload-button {
  cursor: pointer !important;
}

.file\:tw-cursor-pointer::-ms-browse {
  cursor: pointer !important;
}

.file\:tw-cursor-pointer::file-selector-button {
  cursor: pointer !important;
}

.file\:tw-rounded-full::-webkit-file-upload-button {
  border-radius: 9999px !important;
}

.file\:tw-rounded-full::-ms-browse {
  border-radius: 9999px !important;
}

.file\:tw-rounded-full::file-selector-button {
  border-radius: 9999px !important;
}

.file\:tw-border-0::-webkit-file-upload-button {
  border-width: 0 !important;
}

.file\:tw-border-0::-ms-browse {
  border-width: 0 !important;
}

.file\:tw-border-0::file-selector-button {
  border-width: 0 !important;
}

.file\:tw-bg-green-600::-webkit-file-upload-button {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(58, 152, 82, var(--tw-bg-opacity)) !important;
}

.file\:tw-bg-green-600::-ms-browse {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(58, 152, 82, var(--tw-bg-opacity)) !important;
}

.file\:tw-bg-green-600::file-selector-button {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(58, 152, 82, var(--tw-bg-opacity)) !important;
}

.file\:tw-px-5::-webkit-file-upload-button {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.file\:tw-px-5::-ms-browse {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.file\:tw-px-5::file-selector-button {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.file\:tw-py-2::-webkit-file-upload-button {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.file\:tw-py-2::-ms-browse {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.file\:tw-py-2::file-selector-button {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.file\:tw-text-white::-webkit-file-upload-button {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.file\:tw-text-white::-ms-browse {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.file\:tw-text-white::file-selector-button {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.file\:tw-duration-200::-webkit-file-upload-button {
  transition-duration: .2s !important;
}

.file\:tw-duration-200::-ms-browse {
  transition-duration: .2s !important;
}

.file\:tw-duration-200::file-selector-button {
  transition-duration: .2s !important;
}

.placeholder\:tw-text-\[\#535353\]::-webkit-input-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgba(83, 83, 83, var(--tw-text-opacity)) !important;
}

.placeholder\:tw-text-\[\#535353\]::-ms-input-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgba(83, 83, 83, var(--tw-text-opacity)) !important;
}

.placeholder\:tw-text-\[\#535353\]::placeholder {
  --tw-text-opacity: 1 !important;
  color: rgba(83, 83, 83, var(--tw-text-opacity)) !important;
}

.after\:tw-absolute:after {
  content: var(--tw-content) !important;
  position: absolute !important;
}

.after\:-tw-bottom-2:after {
  content: var(--tw-content) !important;
  bottom: -.5rem !important;
}

.after\:tw-left-0:after {
  content: var(--tw-content) !important;
  left: 0 !important;
}

.after\:tw-z-10:after {
  content: var(--tw-content) !important;
  z-index: 10 !important;
}

.after\:tw-block:after {
  content: var(--tw-content) !important;
  display: block !important;
}

.after\:tw-h-1:after {
  content: var(--tw-content) !important;
  height: .25rem !important;
}

.after\:tw-w-full:after {
  content: var(--tw-content) !important;
  width: 100% !important;
}

.after\:tw-rounded-full:after {
  content: var(--tw-content) !important;
  border-radius: 9999px !important;
}

.after\:tw-bg-black:after {
  content: var(--tw-content) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.after\:tw-opacity-0:after {
  content: var(--tw-content) !important;
  opacity: 0 !important;
}

.after\:tw-opacity-100:after {
  content: var(--tw-content) !important;
  opacity: 1 !important;
}

.first\:tw-border-0:first-child {
  border-width: 0 !important;
}

.first\:tw-pt-0:first-child {
  padding-top: 0 !important;
}

.first\:tw-pt-16:first-child {
  padding-top: 4rem !important;
}

.hover\:tw-border-black:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.hover\:tw-border-opacity-70:hover {
  --tw-border-opacity: .7 !important;
}

.hover\:tw-bg-\[\#007535\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 117, 53, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-black:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-gray-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(234, 234, 234, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-white:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-opacity-70:hover {
  --tw-bg-opacity: .7 !important;
}

.hover\:\!tw-text-\[\#5d5d5d\]:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(93, 93, 93, var(--tw-text-opacity)) !important;
}

.hover\:tw-text-\[\#019C49\]:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(1, 156, 73, var(--tw-text-opacity)) !important;
}

.hover\:tw-text-black:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.hover\:tw-text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.hover\:tw-underline:hover {
  text-decoration-line: underline !important;
}

.hover\:tw-opacity-100:hover {
  opacity: 1 !important;
}

.hover\:tw-opacity-50:hover {
  opacity: .5 !important;
}

.hover\:tw-opacity-60:hover {
  opacity: .6 !important;
}

.hover\:tw-opacity-70:hover {
  opacity: .7 !important;
}

.hover\:tw-opacity-75:hover {
  opacity: .75 !important;
}

.hover\:file\:tw-opacity-50::-webkit-file-upload-button:hover {
  opacity: .5 !important;
}

.hover\:file\:tw-opacity-50::-ms-browse:hover {
  opacity: .5 !important;
}

.hover\:file\:tw-opacity-50::file-selector-button:hover {
  opacity: .5 !important;
}

.focus\:tw-z-10:focus {
  z-index: 10 !important;
}

.focus\:tw-outline-none:focus {
  outline-offset: 2px !important;
  outline: 2px solid rgba(0, 0, 0, 0) !important;
}

.focus\:tw-ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0)) !important;
}

.focus\:tw-ring-black:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
}

.focus\:tw-ring-offset-2:focus {
  --tw-ring-offset-width: 2px !important;
}

.active\:tw-scale-95:active {
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.disabled\:tw-cursor-not-allowed:disabled {
  cursor: not-allowed !important;
}

.disabled\:tw-bg-\[\#B1B1B1\]:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(177, 177, 177, var(--tw-bg-opacity)) !important;
}

.disabled\:tw-bg-gray-200:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(234, 234, 234, var(--tw-bg-opacity)) !important;
}

.disabled\:tw-text-gray-500:disabled {
  --tw-text-opacity: 1 !important;
  color: rgba(86, 86, 86, var(--tw-text-opacity)) !important;
}

.tw-group:hover .group-hover\:tw-ml-4 {
  margin-left: 1rem !important;
}

.tw-group:hover .group-hover\:tw-ml-8 {
  margin-left: 2rem !important;
}

.tw-group:hover .group-hover\:tw-opacity-50 {
  opacity: .5 !important;
}

.tw-group:hover .group-hover\:tw-opacity-60 {
  opacity: .6 !important;
}

@media (min-width: 320px) {
  .xs\:tw-block {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .md\:tw-bottom-4 {
    bottom: 1rem !important;
  }

  .md\:tw-left-1\/2 {
    left: 50% !important;
  }

  .md\:tw-left-4 {
    left: 1rem !important;
  }

  .md\:tw-left-auto {
    left: auto !important;
  }

  .md\:tw-right-0 {
    right: 0 !important;
  }

  .md\:tw-order-1 {
    order: 1 !important;
  }

  .md\:tw-order-2 {
    order: 2 !important;
  }

  .md\:tw-order-first {
    order: -9999 !important;
  }

  .md\:tw-order-last {
    order: 9999 !important;
  }

  .md\:tw-col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .md\:tw-col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .md\:tw-col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .md\:tw-col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .md\:tw-col-start-1 {
    grid-column-start: 1 !important;
  }

  .md\:tw-col-start-4 {
    grid-column-start: 4 !important;
  }

  .md\:\!tw-my-\[120px\] {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }

  .md\:tw-mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:\!tw-mt-\[120px\] {
    margin-top: 120px !important;
  }

  .md\:tw-mb-0 {
    margin-bottom: 0 !important;
  }

  .md\:tw-mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .md\:tw-mb-16 {
    margin-bottom: 4rem !important;
  }

  .md\:tw-mb-40 {
    margin-bottom: 10rem !important;
  }

  .md\:tw-mb-\[120px\] {
    margin-bottom: 120px !important;
  }

  .md\:tw-mb-\[160px\] {
    margin-bottom: 160px !important;
  }

  .md\:tw-mr-\[20px\] {
    margin-right: 20px !important;
  }

  .md\:tw-mt-0 {
    margin-top: 0 !important;
  }

  .md\:tw-mt-14 {
    margin-top: 3.5rem !important;
  }

  .md\:tw-mt-16 {
    margin-top: 4rem !important;
  }

  .md\:tw-mt-2 {
    margin-top: .5rem !important;
  }

  .md\:tw-mt-20 {
    margin-top: 5rem !important;
  }

  .md\:tw-mt-32 {
    margin-top: 8rem !important;
  }

  .md\:tw-mt-\[120px\] {
    margin-top: 120px !important;
  }

  .md\:tw-mt-\[42px\] {
    margin-top: 42px !important;
  }

  .md\:tw-mt-\[80px\] {
    margin-top: 80px !important;
  }

  .md\:tw-flex {
    display: flex !important;
  }

  .md\:tw-grid {
    display: grid !important;
  }

  .md\:tw-hidden {
    display: none !important;
  }

  .md\:tw-h-24 {
    height: 6rem !important;
  }

  .md\:tw-h-4 {
    height: 1rem !important;
  }

  .md\:tw-h-\[373px\] {
    height: 373px !important;
  }

  .md\:tw-h-\[50vw\] {
    height: 50vw !important;
  }

  .md\:tw-h-auto {
    height: auto !important;
  }

  .md\:tw-min-h-\[calc\(50vw-2\.5rem\)\] {
    min-height: calc(50vw - 2.5rem) !important;
  }

  .md\:tw-w-1\/2 {
    width: 50% !important;
  }

  .md\:tw-w-3\/12 {
    width: 25% !important;
  }

  .md\:tw-w-4 {
    width: 1rem !important;
  }

  .md\:tw-w-48 {
    width: 12rem !important;
  }

  .md\:tw-w-5\/12 {
    width: 41.6667% !important;
  }

  .md\:tw-w-6\/12 {
    width: 50% !important;
  }

  .md\:tw-w-7\/12 {
    width: 58.3333% !important;
  }

  .md\:tw-w-8\/12 {
    width: 66.6667% !important;
  }

  .md\:tw-w-96 {
    width: 24rem !important;
  }

  .md\:tw-w-\[120px\] {
    width: 120px !important;
  }

  .md\:tw-w-\[127px\] {
    width: 127px !important;
  }

  .md\:tw-w-\[calc\(100vw-40px\)\] {
    width: calc(100vw - 40px) !important;
  }

  .md\:tw-w-\[calc\(50\%-0\.6rem\)\] {
    width: calc(50% - .6rem) !important;
  }

  .md\:tw-w-auto {
    width: auto !important;
  }

  .md\:tw-min-w-\[512px\] {
    min-width: 512px !important;
  }

  .md\:tw-max-w-\[215px\] {
    max-width: 215px !important;
  }

  .md\:tw-max-w-\[300px\] {
    max-width: 300px !important;
  }

  .md\:tw-max-w-\[570px\] {
    max-width: 570px !important;
  }

  .md\:tw-max-w-\[826px\] {
    max-width: 826px !important;
  }

  .md\:tw-max-w-\[884px\] {
    max-width: 884px !important;
  }

  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:tw-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:tw-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .md\:tw-flex-row {
    flex-direction: row !important;
  }

  .md\:tw-flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .md\:tw-items-end {
    align-items: flex-end !important;
  }

  .md\:tw-items-center {
    align-items: center !important;
  }

  .md\:tw-items-baseline {
    align-items: baseline !important;
  }

  .md\:tw-justify-end {
    justify-content: flex-end !important;
  }

  .md\:tw-justify-center {
    justify-content: center !important;
  }

  .md\:tw-justify-between {
    justify-content: space-between !important;
  }

  .md\:tw-gap-10 {
    gap: 2.5rem !important;
  }

  .md\:tw-gap-20 {
    gap: 5rem !important;
  }

  .md\:tw-gap-5 {
    gap: 1.25rem !important;
  }

  .md\:tw-gap-x-10 {
    column-gap: 2.5rem !important;
  }

  .md\:tw-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .md\:tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:tw-border-r {
    border-right-width: 1px !important;
  }

  .md\:tw-border-solid {
    border-style: solid !important;
  }

  .md\:tw-border-gray-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(234, 234, 234, var(--tw-border-opacity)) !important;
  }

  .md\:tw-p-0 {
    padding: 0 !important;
  }

  .md\:tw-px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .md\:tw-px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:tw-px-\[40px\] {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .md\:tw-px-\[70px\] {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .md\:tw-py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:tw-py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:tw-py-\[140px\] {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .md\:tw-py-\[150px\] {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .md\:tw-pb-0 {
    padding-bottom: 0 !important;
  }

  .md\:tw-pb-2 {
    padding-bottom: .5rem !important;
  }

  .md\:tw-pb-32 {
    padding-bottom: 8rem !important;
  }

  .md\:tw-pb-\[63\.5\%\] {
    padding-bottom: 63.5% !important;
  }

  .md\:tw-pl-10 {
    padding-left: 2.5rem !important;
  }

  .md\:tw-pl-\[36px\] {
    padding-left: 36px !important;
  }

  .md\:tw-pr-10 {
    padding-right: 2.5rem !important;
  }

  .md\:tw-pt-\[137px\] {
    padding-top: 137px !important;
  }

  .md\:tw-pt-\[80px\] {
    padding-top: 80px !important;
  }

  .md\:tw-text-center {
    text-align: center !important;
  }

  .md\:tw-text-right {
    text-align: right !important;
  }

  .md\:tw-text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .md\:tw-text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .md\:tw-text-\[100px\] {
    font-size: 100px !important;
  }

  .md\:tw-text-\[32px\] {
    font-size: 32px !important;
  }

  .md\:tw-text-\[33px\] {
    font-size: 33px !important;
  }

  .md\:tw-text-\[34px\] {
    font-size: 34px !important;
  }

  .md\:tw-text-\[44px\] {
    font-size: 44px !important;
  }

  .md\:tw-text-\[52px\] {
    font-size: 52px !important;
  }

  .md\:tw-text-\[56px\] {
    font-size: 56px !important;
  }

  .md\:tw-text-\[64px\] {
    font-size: 64px !important;
  }

  .md\:tw-text-\[70px\] {
    font-size: 70px !important;
  }

  .md\:tw-text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .md\:tw-text-sm {
    font-size: .875rem !important;
    line-height: 1.25rem !important;
  }

  .md\:tw-text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}

@media (min-width: 800px) {
  .nav\:tw-block {
    display: block !important;
  }

  .nav\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }
}

@media (min-width: 993px) {
  .md2\:tw-block {
    display: block !important;
  }

  .md2\:tw-w-full {
    width: 100% !important;
  }

  .md2\:tw-border-none {
    border-style: none !important;
  }

  .md2\:tw-pl-0 {
    padding-left: 0 !important;
  }

  .md2\:tw-pr-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 1024px) {
  .lg\:tw-absolute {
    position: absolute !important;
  }

  .lg\:tw-left-0 {
    left: 0 !important;
  }

  .lg\:tw-top-0 {
    top: 0 !important;
  }

  .lg\:tw-top-\[200px\] {
    top: 200px !important;
  }

  .lg\:tw-order-1 {
    order: 1 !important;
  }

  .lg\:tw-order-2 {
    order: 2 !important;
  }

  .lg\:tw-order-first {
    order: -9999 !important;
  }

  .lg\:tw-col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .lg\:tw-col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .lg\:tw-col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .lg\:tw-col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .lg\:tw-col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .lg\:tw-col-start-7 {
    grid-column-start: 7 !important;
  }

  .lg\:tw--mb-80 {
    margin-bottom: -20rem !important;
  }

  .lg\:tw-mb-12 {
    margin-bottom: 3rem !important;
  }

  .lg\:tw-mb-20 {
    margin-bottom: 5rem !important;
  }

  .lg\:tw-mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .lg\:tw-mt-0 {
    margin-top: 0 !important;
  }

  .lg\:tw-mt-40 {
    margin-top: 10rem !important;
  }

  .lg\:tw-block {
    display: block !important;
  }

  .lg\:tw-flex {
    display: flex !important;
  }

  .lg\:tw-hidden {
    display: none !important;
  }

  .lg\:tw-aspect-video {
    aspect-ratio: 16 / 9 !important;
  }

  .lg\:tw-h-32 {
    height: 8rem !important;
  }

  .lg\:tw-h-\[800px\] {
    height: 800px !important;
  }

  .lg\:tw-h-full {
    height: 100% !important;
  }

  .lg\:tw-h-hero {
    height: calc(95vh - 106px) !important;
  }

  .lg\:tw-min-h-\[289px\] {
    min-height: 289px !important;
  }

  .lg\:tw-min-h-\[650px\] {
    min-height: 650px !important;
  }

  .lg\:tw-min-h-\[800px\] {
    min-height: 800px !important;
  }

  .lg\:tw-min-h-hero {
    min-height: 740px !important;
  }

  .lg\:tw-w-\[183px\] {
    width: 183px !important;
  }

  .lg\:tw-w-\[calc\(33\.333\%-1\.33rem\)\] {
    width: calc(33.333% - 1.33rem) !important;
  }

  .lg\:tw-w-full {
    width: 100% !important;
  }

  .lg\:\!tw-max-w-\[65px\] {
    max-width: 65px !important;
  }

  .lg\:tw-max-w-2xl {
    max-width: 42rem !important;
  }

  .lg\:tw-max-w-\[1200px\] {
    max-width: 1200px !important;
  }

  .lg\:tw-max-w-\[272px\] {
    max-width: 272px !important;
  }

  .lg\:tw-max-w-\[31em\] {
    max-width: 31em !important;
  }

  .lg\:tw-max-w-\[400px\] {
    max-width: 400px !important;
  }

  .lg\:tw-max-w-\[50em\] {
    max-width: 50em !important;
  }

  .lg\:tw-max-w-\[570px\] {
    max-width: 570px !important;
  }

  .lg\:tw-max-w-\[59em\] {
    max-width: 59em !important;
  }

  .lg\:tw-max-w-\[826px\] {
    max-width: 826px !important;
  }

  .lg\:tw-max-w-md {
    max-width: 28rem !important;
  }

  .lg\:tw-max-w-sm {
    max-width: 24rem !important;
  }

  .lg\:tw-basis-1\/2 {
    flex-basis: 50% !important;
  }

  .lg\:tw--translate-y-80 {
    --tw-translate-y: -20rem !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .lg\:tw-transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .lg\:tw-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .lg\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:tw-flex-row {
    flex-direction: row !important;
  }

  .lg\:tw-items-center {
    align-items: center !important;
  }

  .lg\:tw-justify-start {
    justify-content: flex-start !important;
  }

  .lg\:tw-justify-end {
    justify-content: flex-end !important;
  }

  .lg\:tw-justify-between {
    justify-content: space-between !important;
  }

  .lg\:tw-gap-10 {
    gap: 2.5rem !important;
  }

  .lg\:tw-gap-6 {
    gap: 1.5rem !important;
  }

  .lg\:tw-gap-8 {
    gap: 2rem !important;
  }

  .lg\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:tw-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:tw-rounded-b-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .lg\:tw-bg-gray-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(234, 234, 234, var(--tw-bg-opacity)) !important;
  }

  .lg\:tw-p-12 {
    padding: 3rem !important;
  }

  .lg\:tw-p-16 {
    padding: 4rem !important;
  }

  .lg\:tw-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:tw-px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .lg\:tw-px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .lg\:tw-px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:tw-px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:tw-px-\[70px\] {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .lg\:tw-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lg\:tw-py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:tw-py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lg\:tw-py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lg\:tw-py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .lg\:tw-pb-0 {
    padding-bottom: 0 !important;
  }

  .lg\:tw-pb-20 {
    padding-bottom: 5rem !important;
  }

  .lg\:tw-pb-8 {
    padding-bottom: 2rem !important;
  }

  .lg\:tw-pb-96 {
    padding-bottom: 24rem !important;
  }

  .lg\:tw-pb-\[133\%\] {
    padding-bottom: 133% !important;
  }

  .lg\:tw-pb-\[63\.5\%\] {
    padding-bottom: 63.5% !important;
  }

  .lg\:tw-pb-\[85\.44\%\] {
    padding-bottom: 85.44% !important;
  }

  .lg\:tw-pl-10 {
    padding-left: 2.5rem !important;
  }

  .lg\:tw-pl-14 {
    padding-left: 3.5rem !important;
  }

  .lg\:tw-pl-\[76px\] {
    padding-left: 76px !important;
  }

  .lg\:tw-pr-10 {
    padding-right: 2.5rem !important;
  }

  .lg\:tw-pr-\[76px\] {
    padding-right: 76px !important;
  }

  .lg\:tw-pt-0 {
    padding-top: 0 !important;
  }

  .lg\:tw-pt-10 {
    padding-top: 2.5rem !important;
  }

  .lg\:tw-pt-24 {
    padding-top: 6rem !important;
  }

  .lg\:tw-text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .lg\:tw-text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }

  .lg\:tw-text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .lg\:tw-text-7xl {
    font-size: 4.5rem !important;
    line-height: 1 !important;
  }

  .lg\:tw-text-8xl {
    font-size: 6rem !important;
    line-height: 1 !important;
  }

  .lg\:tw-text-\[120px\] {
    font-size: 120px !important;
  }

  .lg\:tw-text-\[12em\] {
    font-size: 12em !important;
  }

  .lg\:tw-text-\[32px\] {
    font-size: 32px !important;
  }

  .lg\:tw-text-\[52px\] {
    font-size: 52px !important;
  }

  .lg\:tw-text-\[64px\] {
    font-size: 64px !important;
  }

  .lg\:tw-text-\[84px\] {
    font-size: 84px !important;
  }

  .lg\:tw-text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .lg\:tw-text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .lg\:tw-text-sm {
    font-size: .875rem !important;
    line-height: 1.25rem !important;
  }

  .lg\:tw-text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}

@media (min-width: 1280px) {
  .xl\:tw-col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .xl\:tw-col-start-7 {
    grid-column-start: 7 !important;
  }

  .xl\:tw-col-end-13 {
    grid-column-end: 13 !important;
  }

  .xl\:tw-mx-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .xl\:tw-mb-16 {
    margin-bottom: 4rem !important;
  }

  .xl\:tw-mt-20 {
    margin-top: 5rem !important;
  }

  .xl\:tw-mt-64 {
    margin-top: 16rem !important;
  }

  .xl\:tw-block {
    display: block !important;
  }

  .xl\:tw-flex {
    display: flex !important;
  }

  .xl\:tw-min-h-\[373px\] {
    min-height: 373px !important;
  }

  .xl\:tw-w-1\/2 {
    width: 50% !important;
  }

  .xl\:tw-w-5\/12 {
    width: 41.6667% !important;
  }

  .xl\:tw-w-7\/12 {
    width: 58.3333% !important;
  }

  .xl\:tw-w-\[118px\] {
    width: 118px !important;
  }

  .xl\:tw-w-\[calc\(33\.333\%-1\.66rem\)\] {
    width: calc(33.333% - 1.66rem) !important;
  }

  .xl\:tw-min-w-\[534px\] {
    min-width: 534px !important;
  }

  .xl\:tw-max-w-\[352px\] {
    max-width: 352px !important;
  }

  .xl\:tw-max-w-\[506px\] {
    max-width: 506px !important;
  }

  .xl\:tw-gap-0 {
    gap: 0 !important;
  }

  .xl\:tw-gap-10 {
    gap: 2.5rem !important;
  }

  .xl\:tw-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:tw-px-\[150px\] {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .xl\:tw-pl-32 {
    padding-left: 8rem !important;
  }

  .xl\:tw-pr-32 {
    padding-right: 8rem !important;
  }

  .xl\:tw-text-\[160px\] {
    font-size: 160px !important;
  }

  .xl\:tw-text-\[64px\] {
    font-size: 64px !important;
  }

  .xl\:first\:tw-mt-\[60px\]:first-child {
    margin-top: 60px !important;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:tw-text-\[88px\] {
    font-size: 88px !important;
  }
}

@media (min-width: 1440px) {
  .\[\@media\(min-width\:1440px\)\]\:tw-col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-mt-16 {
    margin-top: 4rem !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-block {
    display: block !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-flex {
    display: flex !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-hidden {
    display: none !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-flex-col {
    flex-direction: column !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-items-end {
    align-items: flex-end !important;
  }

  .\[\@media\(min-width\:1440px\)\]\:tw-justify-between {
    justify-content: space-between !important;
  }
}

.mb {
  margin-bottom: 20px;
}

.mb--s {
  margin-bottom: 10px;
}

.mb--l {
  margin-bottom: 30px;
}

.mb--xl {
  margin-bottom: 40px;
}

.mb--xxl {
  margin-bottom: 60px;
}

.mb--xxxl {
  margin-bottom: 80px;
}

.mb--xxxxl {
  margin-bottom: 120px;
}

.mt--xl {
  margin-top: 40px;
}

.mt--xxl {
  margin-top: 60px;
}

hr.content-divider {
  border: 1px solid #ddd;
  margin: 30px 0 60px;
}

hr.content-divider--l {
  margin: 40px 0 60px;
}

hr.content-divider--xxxl-bottom {
  margin: 40px 0 80px;
}

.psuedo-select {
  text-align: left;
  position: relative;
}

.psuedo-select i {
  color: #a9a9a9;
  cursor: pointer;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  right: 0;
}

.psuedo-select__toggle {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: #000;
  background-position: calc(100% - 20px);
  border: 1px solid #ddd;
  border-radius: 0;
  outline: none;
  width: 100%;
  padding: 20px;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

.psuedo-select__toggle:hover {
  background-color: #fafafa;
}

.psuedo-select__toggle::-ms-expand {
  display: none;
}

.psuedo-select__options {
  z-index: 10;
  border-top: 0;
  border-bottom: 0;
  width: 100%;
  max-height: 225px;
  display: none;
  position: absolute;
  top: 100%;
  overflow-y: scroll;
}

.psuedo-select__option a {
  color: #666;
  background: #fafafa;
  padding: 15px 20px;
  font-size: 14px;
  text-decoration: none;
  display: block;
}

.psuedo-select__option a:hover {
  color: #fff;
  background: #222;
}

.psuedo-select--inline {
  display: inline-block;
}

.psuedo-select--inline .psuedo-select__toggle {
  padding-right: 60px;
}

.psuedo-select--dark .psuedo-select__toggle {
  color: #fff;
  background-position: calc(100% - 20px);
  border-color: #666;
}

.psuedo-select--dark .psuedo-select__toggle:hover {
  background-color: #333;
}

.psuedo-select--dark .psuedo-select__options {
  border-color: #666;
}

.psuedo-select--dark .psuedo-select__option a {
  color: red;
  background: #333;
  border-color: #666;
}

.psuedo-select--dark .psuedo-select__option a:hover {
  color: #fff;
  background: #00a03d;
}

.fancybox-container .fancybox-content {
  position: relative;
}

.fancybox-container .fancybox-content .fancybox-close-small {
  color: #fff;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.fancybox-container .fancybox-infobar {
  display: none;
}

.fancybox-container .fancybox-button {
  color: #fff;
  background: none;
  width: 54px;
  height: 54px;
}

.fancybox-container .fancybox-navigation .fancybox-button {
  width: 90px;
  height: 120px;
}

.fancybox-container .fancybox-slide--iframe .fancybox-content, .fancybox-container .fancybox-slide--video .fancybox-content {
  max-width: 100%;
  max-height: 100%;
  overflow: auto !important;
}

.fancybox-container.fancybox--dark-close .fancybox-close-small {
  color: #000;
  top: 18px;
}

@font-face {
  font-family: ArialMTStd-Black;
  src: url("39858B_0_0.63f91470.eot");
  src: url("39858B_0_0.63f91470.eot#iefix") format("embedded-opentype"), url("39858B_0_0.1d05ac96.woff2") format("woff2"), url("39858B_0_0.44269cc8.woff") format("woff"), url("39858B_0_0.a3f0f567.ttf") format("truetype");
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

html {
  line-height: 1 !important;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
}

q, blockquote {
  quotes: none;
}

q:before, blockquote:before, q:after, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body, *, input, textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.body {
  color: #5d5d5d;
  font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  overflow-x: hidden;
}

.body.scroll-hidden {
  overflow: hidden;
}

.body--case-studies-index .site-header {
  z-index: 20;
}

.body--case-studies-index .site-footer {
  padding-bottom: 82px;
}

.body.l-full {
  background-color: #3d3d3d;
  height: calc(100vh - 51px);
}

.body.l-full .site-footer {
  display: none;
}

.responsive-image {
  width: 100%;
  height: auto;
  display: block;
}

.home .content-wrapper {
  margin-bottom: 0;
}

.column-spacer--left {
  padding-left: 0;
}

@media (min-width: 769px) {
  .column-spacer--left {
    padding-left: 20px;
  }
}

@media (min-width: 1201px) {
  .column-spacer--left {
    padding-left: 60px;
  }
}

.column-spacer--right {
  padding-right: 0;
}

@media (min-width: 769px) {
  .column-spacer--right {
    padding-right: 20px;
  }
}

@media (min-width: 1201px) {
  .column-spacer--right {
    padding-right: 60px;
  }
}

.partner-scrolling {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  max-height: 640px;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (min-width: 769px) {
  .partner-scrolling {
    max-height: 840px;
  }
}

.partner-scrolling::-webkit-scrollbar {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  width: 2px;
}

.partner-scrolling::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, .1);
}

.partner-scrolling::-webkit-scrollbar-thumb {
  background: #000;
}

.partner-scrolling__inner {
  padding-right: 20px;
}

@media (min-width: 1201px) {
  .partner-scrolling__inner {
    padding-right: 40px;
  }
}

.partners-background {
  padding-top: 30px;
  position: relative;
  overflow: visible !important;
}

@media (min-width: 993px) {
  .partners-background {
    padding-top: 0;
    overflow: hidden;
  }
}

.partners-background:after {
  content: "";
  z-index: -1;
  pointer-events: none;
  background: #fafafa;
  width: calc(100% + 40px);
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: -20px;
}

@media (min-width: 993px) {
  .partners-background:after {
    width: 1000%;
    height: calc(100% + 80px);
    top: -80px;
    left: auto;
    right: -20px;
  }
}

.not {
  display: block;
}

@media (min-width: 576px) {
  .not\@s {
    display: none;
  }
}

@media (min-width: 769px) {
  .not\@m {
    display: none;
  }
}

@media (min-width: 993px) {
  .not\@l {
    display: none;
  }
}

@media (min-width: 1201px) {
  .not\@xl {
    display: none;
  }
}

.stickyfill:before, .stickyfill:after {
  content: "";
  display: table;
}

.max-width {
  max-width: 100%;
  display: block;
}

.flickity-page-dots {
  justify-content: center;
  margin-top: 24px;
  display: flex;
  margin-bottom: 0 !important;
  list-style: none !important;
}

.flickity-page-dots li.dot {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  opacity: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  background: #dedede;
  border: none;
  border-radius: 0;
  outline: none;
  width: 6px;
  height: 6px;
  padding: 0;
  line-height: 1;
  display: block;
  margin: 0 4px !important;
}

.flickity-page-dots li.dot.is-selected {
  background: #000;
}

[x-cloak] {
  display: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 993px) {
  .container {
    max-width: 1180px;
  }
}

.container--elements {
  flex-flow: wrap;
  margin-bottom: 80px;
  display: flex;
}

.container--case-studies {
  flex-flow: column;
  max-width: none;
  min-height: calc(100vh - 1353px);
  margin-top: 105px;
  padding: 0;
  display: flex;
}

@media (min-width: 576px) {
  .container--case-studies {
    min-height: calc(100vh - 667px);
    margin-top: 137px;
  }
}

.container--carousel {
  position: relative;
}

.container--wider {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 993px) {
  .container--wider {
    max-width: 1350px;
  }
}

.copy {
  font-size: 14px;
  overflow: hidden;
}

.copy p {
  margin-bottom: 30px;
  line-height: 25px;
}

.copy p.intro {
  color: #000;
  font-size: 20px;
  line-height: 32px;
}

.copy p.no-mb {
  margin-bottom: 0;
}

.copy h2 {
  color: #000;
  margin-bottom: 30px;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 22px;
}

.copy h2.accordion__title {
  margin-bottom: 0;
}

.copy h3 {
  color: #000;
  font-size: 20px;
  line-height: 32px;
}

.copy ol, .copy ul {
  margin-bottom: 20px;
}

.copy ol ol, .copy ol ul, .copy ul ol, .copy ul ul {
  margin-top: 20px;
}

.copy ol {
  list-style: decimal;
}

.copy ul {
  list-style: square;
}

.copy li {
  margin-bottom: 15px;
  margin-left: 15px;
  line-height: 1.7;
}

.copy .fancy-list {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.copy .fancy-list__item {
  margin-left: 0;
  display: flex;
}

.copy .fancy-list__item svg {
  flex: 0 0 16px;
  margin-top: 5px;
}

.copy .fancy-list__item span {
  padding-left: 10px;
}

.copy .fancy-list__item a {
  color: #5d5d5d;
}

.copy .fancy-list__item a:hover {
  color: #00a03d;
  text-decoration: none;
}

.copy strong, .copy b {
  font-weight: bold;
}

.copy em, .copy i {
  font-style: italic;
}

.copy .redactor a {
  color: #00a03d;
  text-decoration: underline;
  transition: color .2s ease-in-out;
}

.copy .redactor a:hover {
  color: #000;
}

.copy .about-intro p {
  color: #000;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 20px;
  line-height: 28px;
}

@media (min-width: 993px) {
  .copy .about-intro p {
    font-size: 24px;
    line-height: 32px;
  }
}

.copy .about-intro__highlight {
  color: #00a03d;
}

.copy img {
  max-width: 100%;
  display: block;
}

.copy.component-description p {
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 1.5;
}

.copy--project h2 {
  margin-top: 80px;
}

.copy--project h2:first-of-type {
  margin-top: 0;
}

.redactor--black-larger {
  color: #000;
  font-size: 18px;
  line-height: 150%;
}

.redactor--black-larger p {
  line-height: 150%;
}

.redactor--black-larger a {
  color: #000 !important;
}

.redactor--black-larger a:hover {
  color: #00a03d !important;
}

.redactor--black-medium {
  color: #000;
  font-size: 16px;
  line-height: 150%;
}

.redactor--black-medium p {
  line-height: 150%;
}

.redactor--black-medium a {
  color: #000 !important;
}

.redactor--black-medium a:hover {
  color: #00a03d !important;
}

h2 {
  line-height: 1.4;
}

h2.component-category {
  margin-bottom: 15px;
}

h2.no-mb {
  margin-bottom: 0;
}

h2.h2--smaller {
  margin-bottom: 10px;
  font-size: 16px;
}

h3.divider {
  text-align: center;
  text-transform: uppercase;
  color: #000;
  font-family: ArialMTStd-Black, Arial Bold, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 18px;
  line-height: 36px;
  position: relative;
  overflow: hidden;
}

h3.divider span {
  display: inline-block;
  position: relative;
}

h3.divider span:before, h3.divider span:after {
  content: "";
  border-top: 1px solid #dedede;
  width: 600px;
  height: 1px;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

h3.divider span:before {
  margin-right: 30px;
  right: 100%;
}

h3.divider span:after {
  margin-left: 30px;
  left: 100%;
}

.blockquote {
  margin: 30px 0 60px;
  padding: 20px;
  font-size: 20px;
  line-height: 32px;
}

@media (min-width: 993px) {
  .blockquote {
    padding: 40px;
  }
}

.blockquote p {
  color: inherit;
  margin-bottom: 10px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.blockquote cite {
  color: inherit;
  opacity: .5;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
}

.components-intro .redactor:first-of-type p:first-of-type {
  color: #000;
  font-size: 22px;
  line-height: 30px;
}

.copy--content-builder h2 {
  color: #000;
  margin-bottom: 30px;
  font-family: HelveticaNeue-Light, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 44px;
  line-height: 1.2;
}

.fancybox-slide--iframe .fancybox-content {
  width: 600px;
  max-width: 80%;
  height: 400px;
  max-height: 80%;
  margin: 0;
}

.fancybox-remove-scroll .fancybox-content {
  overflow: auto !important;
}

.aside .button {
  width: 100%;
}

.button {
  color: #000;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  z-index: 1;
  background: none;
  border: 1px solid #e3e3e3;
  outline: none;
  margin-bottom: 20px;
  padding: 20px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  transition: all .15s ease-in;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.button:before {
  content: "";
  z-index: -1;
  background: #000;
  transition: top .15s ease-in;
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.button:hover:before, .button:focus:before {
  top: 0;
}

.button:hover, .button:focus {
  color: #fff;
  border-color: #000;
}

.button--block {
  width: 100%;
  display: block;
}

.button--icon {
  padding-left: 60px;
}

.button--ghost {
  color: #fff;
}

.button--gallery {
  text-transform: uppercase;
  background-color: #fff;
  border: 0;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
}

.button--black {
  color: #fff;
  text-align: center;
  background: #000;
  border: none;
  margin-bottom: 40px;
  padding: 20px;
  font-size: 22px;
  line-height: 1.1;
}

.button--small-black {
  color: #fff;
  background: #000;
  border: none;
  padding: 21px 20px;
}

.button--small-black:before {
  background: #fff;
  border: 1px solid #e3e3e3;
}

.button--small-black:hover, .button--small-black:focus {
  color: #000;
  background: #fff;
}

.button--small-black:hover:before, .button--small-black:focus:before {
  top: 0;
}

.button i {
  position: absolute;
  top: 16px;
  left: 20px;
}

.button--no-border {
  border: none;
  padding: 0 0 0 23px;
  line-height: 1;
}

.button--no-border:before {
  display: none;
}

.button--no-border:hover, .button--no-border:focus {
  color: #000;
}

.button--no-border:hover:before, .button--no-border:focus:before {
  display: none;
}

.button--no-border i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.button-row {
  margin-top: 40px;
}

@media (min-width: 769px) {
  .button-row {
    margin-top: 60px;
  }
}

.button-row .button {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 769px) {
  .button-row .button {
    width: auto;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.button-row .button:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.breadcrumbs {
  white-space: nowrap;
  list-style: none;
  overflow-x: auto;
  overflow-y: hidden;
}

.breadcrumbs__item {
  display: inline-block;
}

.breadcrumbs__item:not(:last-child):after {
  color: #5d5d5d;
  content: ">";
  margin: 0 10px;
}

.breadcrumbs__link {
  color: #000;
  text-decoration: none;
}

.card {
  color: inherit;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.card__image {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: opacity .4s ease-in-out;
  display: block;
}

.card:hover {
  cursor: pointer;
}

.card:hover .card__image {
  opacity: .8;
}

.card__inner {
  box-sizing: border-box;
  border: 1px solid #dedede;
  border-bottom: 0;
  height: 100%;
  padding: 20px;
}

@media (min-width: 993px) {
  .card__inner {
    padding: 30px;
  }
}

.card__title {
  color: #000;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
}

.card__description {
  color: #606060;
  margin-top: 20px;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
}

.card__text {
  padding-right: 20px;
}

.card__icon {
  color: #dedede;
}

.card__cta {
  margin-top: 20px;
  font-size: 12px;
  text-decoration: underline;
  display: block;
}

.card__big-cta {
  text-align: center;
  color: #000;
  border: 1px solid #dedede;
  padding: 16px;
  font-size: 14px;
  text-decoration: none;
  transition: all .15s ease-in;
  display: block;
  position: relative;
  overflow: hidden;
}

.card__big-cta:before {
  content: "";
  z-index: -1;
  background: #000;
  transition: top .15s ease-in;
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.card__riba-badge {
  text-transform: uppercase;
  color: #000;
  z-index: 2;
  pointer-events: none;
  background: #fff;
  border-radius: 2px;
  align-items: center;
  padding: 5px 8px 5px 5px;
  font-size: 10px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
}

.card__riba-badge img {
  margin-right: 4px;
  display: block;
}

.card__riba-badge span {
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 1;
}

.card--green {
  border-bottom: 6px solid #7fb655;
}

.card--cpd-seminar .card__title {
  line-height: 135%;
}

.card--cpd-seminar .card__inner {
  flex-direction: column;
  display: flex;
}

.card--cpd-seminar .card__description {
  color: #000;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 25px;
}

.card--cpd-seminar .card__text {
  padding-right: 0;
}

.card--cpd-seminar .cpd-details {
  margin-bottom: 30px;
}

.card--cpd-seminar .card__big-cta {
  margin-top: auto;
}

.card--cpd-seminar:hover .card__big-cta, .card--cpd-seminar:focus .card__big-cta {
  color: #fff;
  border-color: #000;
}

.card--cpd-seminar:hover .card__big-cta:before, .card--cpd-seminar:focus .card__big-cta:before {
  top: 0;
}

.related-card {
  width: calc(100% - 10px);
  height: 280px;
  margin: 5px;
  display: inline-block;
  position: relative;
}

@media (min-width: 769px) {
  .related-card {
    width: calc(50% - 10px);
  }
}

@media (min-width: 993px) {
  .related-card {
    width: calc(33.3% - 10px);
  }
}

.related-card__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.related-card__overlay {
  width: 60%;
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.related-card__title {
  color: #fff;
  margin-bottom: 5px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
}

.related-card__link {
  color: #fff;
  text-decoration: underline;
}

.related-card--green .related-card__overlay {
  background: #7fb655;
}

.grid {
  flex-flow: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
  display: flex;
  overflow: visible;
}

.grid--wrap-reverse {
  flex-wrap: wrap-reverse;
}

.grid:after {
  content: " ";
  clear: both;
  display: block;
}

.grid__col {
  float: left;
  flex: 0 0 calc(100% - 30px);
  width: calc(100% - 30px);
  min-width: 0;
  min-height: 0;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

.grid__col--span\:\:1 {
  flex: 0 0 calc(8.33333% - 30px);
  width: calc(8.33333% - 30px);
}

.grid__col--span\:\:2 {
  flex: 0 0 calc(16.6667% - 30px);
  width: calc(16.6667% - 30px);
}

.grid__col--span\:\:3 {
  flex: 0 0 calc(25% - 30px);
  width: calc(25% - 30px);
}

.grid__col--span\:\:4 {
  flex: 0 0 calc(33.3333% - 30px);
  width: calc(33.3333% - 30px);
}

.grid__col--span\:\:5 {
  flex: 0 0 calc(41.6667% - 30px);
  width: calc(41.6667% - 30px);
}

.grid__col--span\:\:6 {
  flex: 0 0 calc(50% - 30px);
  width: calc(50% - 30px);
}

.grid__col--span\:\:7 {
  flex: 0 0 calc(58.3333% - 30px);
  width: calc(58.3333% - 30px);
}

.grid__col--span\:\:8 {
  flex: 0 0 calc(66.6667% - 30px);
  width: calc(66.6667% - 30px);
}

.grid__col--span\:\:9 {
  flex: 0 0 calc(75% - 30px);
  width: calc(75% - 30px);
}

.grid__col--span\:\:10 {
  flex: 0 0 calc(83.3333% - 30px);
  width: calc(83.3333% - 30px);
}

.grid__col--span\:\:11 {
  flex: 0 0 calc(91.6667% - 30px);
  width: calc(91.6667% - 30px);
}

.grid__col--span\:\:12 {
  flex: 0 0 calc(100% - 30px);
  width: calc(100% - 30px);
}

@media (min-width: 576px) {
  .grid__col--span\:\:1\@s {
    flex: 0 0 calc(8.33333% - 30px);
    width: calc(8.33333% - 30px);
  }

  .grid__col--span\:\:2\@s {
    flex: 0 0 calc(16.6667% - 30px);
    width: calc(16.6667% - 30px);
  }

  .grid__col--span\:\:3\@s {
    flex: 0 0 calc(25% - 30px);
    width: calc(25% - 30px);
  }

  .grid__col--span\:\:4\@s {
    flex: 0 0 calc(33.3333% - 30px);
    width: calc(33.3333% - 30px);
  }

  .grid__col--span\:\:5\@s {
    flex: 0 0 calc(41.6667% - 30px);
    width: calc(41.6667% - 30px);
  }

  .grid__col--span\:\:6\@s {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
  }

  .grid__col--span\:\:7\@s {
    flex: 0 0 calc(58.3333% - 30px);
    width: calc(58.3333% - 30px);
  }

  .grid__col--span\:\:8\@s {
    flex: 0 0 calc(66.6667% - 30px);
    width: calc(66.6667% - 30px);
  }

  .grid__col--span\:\:9\@s {
    flex: 0 0 calc(75% - 30px);
    width: calc(75% - 30px);
  }

  .grid__col--span\:\:10\@s {
    flex: 0 0 calc(83.3333% - 30px);
    width: calc(83.3333% - 30px);
  }

  .grid__col--span\:\:11\@s {
    flex: 0 0 calc(91.6667% - 30px);
    width: calc(91.6667% - 30px);
  }

  .grid__col--span\:\:12\@s {
    flex: 0 0 calc(100% - 30px);
    width: calc(100% - 30px);
  }
}

@media (min-width: 769px) {
  .grid__col--span\:\:1\@m {
    flex: 0 0 calc(8.33333% - 30px);
    width: calc(8.33333% - 30px);
  }

  .grid__col--span\:\:2\@m {
    flex: 0 0 calc(16.6667% - 30px);
    width: calc(16.6667% - 30px);
  }

  .grid__col--span\:\:3\@m {
    flex: 0 0 calc(25% - 30px);
    width: calc(25% - 30px);
  }

  .grid__col--span\:\:4\@m {
    flex: 0 0 calc(33.3333% - 30px);
    width: calc(33.3333% - 30px);
  }

  .grid__col--span\:\:5\@m {
    flex: 0 0 calc(41.6667% - 30px);
    width: calc(41.6667% - 30px);
  }

  .grid__col--span\:\:6\@m {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
  }

  .grid__col--span\:\:7\@m {
    flex: 0 0 calc(58.3333% - 30px);
    width: calc(58.3333% - 30px);
  }

  .grid__col--span\:\:8\@m {
    flex: 0 0 calc(66.6667% - 30px);
    width: calc(66.6667% - 30px);
  }

  .grid__col--span\:\:9\@m {
    flex: 0 0 calc(75% - 30px);
    width: calc(75% - 30px);
  }

  .grid__col--span\:\:10\@m {
    flex: 0 0 calc(83.3333% - 30px);
    width: calc(83.3333% - 30px);
  }

  .grid__col--span\:\:11\@m {
    flex: 0 0 calc(91.6667% - 30px);
    width: calc(91.6667% - 30px);
  }

  .grid__col--span\:\:12\@m {
    flex: 0 0 calc(100% - 30px);
    width: calc(100% - 30px);
  }
}

@media (min-width: 993px) {
  .grid__col--span\:\:1\@l {
    flex: 0 0 calc(8.33333% - 30px);
    width: calc(8.33333% - 30px);
  }

  .grid__col--span\:\:2\@l {
    flex: 0 0 calc(16.6667% - 30px);
    width: calc(16.6667% - 30px);
  }

  .grid__col--span\:\:3\@l {
    flex: 0 0 calc(25% - 30px);
    width: calc(25% - 30px);
  }

  .grid__col--span\:\:4\@l {
    flex: 0 0 calc(33.3333% - 30px);
    width: calc(33.3333% - 30px);
  }

  .grid__col--span\:\:5\@l {
    flex: 0 0 calc(41.6667% - 30px);
    width: calc(41.6667% - 30px);
  }

  .grid__col--span\:\:6\@l {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
  }

  .grid__col--span\:\:7\@l {
    flex: 0 0 calc(58.3333% - 30px);
    width: calc(58.3333% - 30px);
  }

  .grid__col--span\:\:8\@l {
    flex: 0 0 calc(66.6667% - 30px);
    width: calc(66.6667% - 30px);
  }

  .grid__col--span\:\:9\@l {
    flex: 0 0 calc(75% - 30px);
    width: calc(75% - 30px);
  }

  .grid__col--span\:\:10\@l {
    flex: 0 0 calc(83.3333% - 30px);
    width: calc(83.3333% - 30px);
  }

  .grid__col--span\:\:11\@l {
    flex: 0 0 calc(91.6667% - 30px);
    width: calc(91.6667% - 30px);
  }

  .grid__col--span\:\:12\@l {
    flex: 0 0 calc(100% - 30px);
    width: calc(100% - 30px);
  }
}

@media (min-width: 1201px) {
  .grid__col--span\:\:1\@xl {
    flex: 0 0 calc(8.33333% - 30px);
    width: calc(8.33333% - 30px);
  }

  .grid__col--span\:\:2\@xl {
    flex: 0 0 calc(16.6667% - 30px);
    width: calc(16.6667% - 30px);
  }

  .grid__col--span\:\:3\@xl {
    flex: 0 0 calc(25% - 30px);
    width: calc(25% - 30px);
  }

  .grid__col--span\:\:4\@xl {
    flex: 0 0 calc(33.3333% - 30px);
    width: calc(33.3333% - 30px);
  }

  .grid__col--span\:\:5\@xl {
    flex: 0 0 calc(41.6667% - 30px);
    width: calc(41.6667% - 30px);
  }

  .grid__col--span\:\:6\@xl {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
  }

  .grid__col--span\:\:7\@xl {
    flex: 0 0 calc(58.3333% - 30px);
    width: calc(58.3333% - 30px);
  }

  .grid__col--span\:\:8\@xl {
    flex: 0 0 calc(66.6667% - 30px);
    width: calc(66.6667% - 30px);
  }

  .grid__col--span\:\:9\@xl {
    flex: 0 0 calc(75% - 30px);
    width: calc(75% - 30px);
  }

  .grid__col--span\:\:10\@xl {
    flex: 0 0 calc(83.3333% - 30px);
    width: calc(83.3333% - 30px);
  }

  .grid__col--span\:\:11\@xl {
    flex: 0 0 calc(91.6667% - 30px);
    width: calc(91.6667% - 30px);
  }

  .grid__col--span\:\:12\@xl {
    flex: 0 0 calc(100% - 30px);
    width: calc(100% - 30px);
  }
}

.grid__col--shift\:\:0 {
  margin-left: 15px;
}

.grid__col--shift\:\:1 {
  margin-left: calc(8.33333% + 15px);
}

.grid__col--shift\:\:2 {
  margin-left: calc(16.6667% + 15px);
}

.grid__col--shift\:\:3 {
  margin-left: calc(25% + 15px);
}

.grid__col--shift\:\:4 {
  margin-left: calc(33.3333% + 15px);
}

.grid__col--shift\:\:5 {
  margin-left: calc(41.6667% + 15px);
}

.grid__col--shift\:\:6 {
  margin-left: calc(50% + 15px);
}

.grid__col--shift\:\:7 {
  margin-left: calc(58.3333% + 15px);
}

.grid__col--shift\:\:8 {
  margin-left: calc(66.6667% + 15px);
}

.grid__col--shift\:\:9 {
  margin-left: calc(75% + 15px);
}

.grid__col--shift\:\:10 {
  margin-left: calc(83.3333% + 15px);
}

.grid__col--shift\:\:11 {
  margin-left: calc(91.6667% + 15px);
}

.grid__col--shift\:\:12 {
  margin-left: calc(100% + 15px);
}

@media (min-width: 576px) {
  .grid__col--shift\:\:0\@s {
    margin-left: 15px;
  }

  .grid__col--shift\:\:1\@s {
    margin-left: calc(8.33333% + 15px);
  }

  .grid__col--shift\:\:2\@s {
    margin-left: calc(16.6667% + 15px);
  }

  .grid__col--shift\:\:3\@s {
    margin-left: calc(25% + 15px);
  }

  .grid__col--shift\:\:4\@s {
    margin-left: calc(33.3333% + 15px);
  }

  .grid__col--shift\:\:5\@s {
    margin-left: calc(41.6667% + 15px);
  }

  .grid__col--shift\:\:6\@s {
    margin-left: calc(50% + 15px);
  }

  .grid__col--shift\:\:7\@s {
    margin-left: calc(58.3333% + 15px);
  }

  .grid__col--shift\:\:8\@s {
    margin-left: calc(66.6667% + 15px);
  }

  .grid__col--shift\:\:9\@s {
    margin-left: calc(75% + 15px);
  }

  .grid__col--shift\:\:10\@s {
    margin-left: calc(83.3333% + 15px);
  }

  .grid__col--shift\:\:11\@s {
    margin-left: calc(91.6667% + 15px);
  }

  .grid__col--shift\:\:12\@s {
    margin-left: calc(100% + 15px);
  }
}

@media (min-width: 769px) {
  .grid__col--shift\:\:0\@m {
    margin-left: 15px;
  }

  .grid__col--shift\:\:1\@m {
    margin-left: calc(8.33333% + 15px);
  }

  .grid__col--shift\:\:2\@m {
    margin-left: calc(16.6667% + 15px);
  }

  .grid__col--shift\:\:3\@m {
    margin-left: calc(25% + 15px);
  }

  .grid__col--shift\:\:4\@m {
    margin-left: calc(33.3333% + 15px);
  }

  .grid__col--shift\:\:5\@m {
    margin-left: calc(41.6667% + 15px);
  }

  .grid__col--shift\:\:6\@m {
    margin-left: calc(50% + 15px);
  }

  .grid__col--shift\:\:7\@m {
    margin-left: calc(58.3333% + 15px);
  }

  .grid__col--shift\:\:8\@m {
    margin-left: calc(66.6667% + 15px);
  }

  .grid__col--shift\:\:9\@m {
    margin-left: calc(75% + 15px);
  }

  .grid__col--shift\:\:10\@m {
    margin-left: calc(83.3333% + 15px);
  }

  .grid__col--shift\:\:11\@m {
    margin-left: calc(91.6667% + 15px);
  }

  .grid__col--shift\:\:12\@m {
    margin-left: calc(100% + 15px);
  }
}

@media (min-width: 993px) {
  .grid__col--shift\:\:0\@l {
    margin-left: 15px;
  }

  .grid__col--shift\:\:1\@l {
    margin-left: calc(8.33333% + 15px);
  }

  .grid__col--shift\:\:2\@l {
    margin-left: calc(16.6667% + 15px);
  }

  .grid__col--shift\:\:3\@l {
    margin-left: calc(25% + 15px);
  }

  .grid__col--shift\:\:4\@l {
    margin-left: calc(33.3333% + 15px);
  }

  .grid__col--shift\:\:5\@l {
    margin-left: calc(41.6667% + 15px);
  }

  .grid__col--shift\:\:6\@l {
    margin-left: calc(50% + 15px);
  }

  .grid__col--shift\:\:7\@l {
    margin-left: calc(58.3333% + 15px);
  }

  .grid__col--shift\:\:8\@l {
    margin-left: calc(66.6667% + 15px);
  }

  .grid__col--shift\:\:9\@l {
    margin-left: calc(75% + 15px);
  }

  .grid__col--shift\:\:10\@l {
    margin-left: calc(83.3333% + 15px);
  }

  .grid__col--shift\:\:11\@l {
    margin-left: calc(91.6667% + 15px);
  }

  .grid__col--shift\:\:12\@l {
    margin-left: calc(100% + 15px);
  }
}

@media (min-width: 1201px) {
  .grid__col--shift\:\:0\@xl {
    margin-left: 15px;
  }

  .grid__col--shift\:\:1\@xl {
    margin-left: calc(8.33333% + 15px);
  }

  .grid__col--shift\:\:2\@xl {
    margin-left: calc(16.6667% + 15px);
  }

  .grid__col--shift\:\:3\@xl {
    margin-left: calc(25% + 15px);
  }

  .grid__col--shift\:\:4\@xl {
    margin-left: calc(33.3333% + 15px);
  }

  .grid__col--shift\:\:5\@xl {
    margin-left: calc(41.6667% + 15px);
  }

  .grid__col--shift\:\:6\@xl {
    margin-left: calc(50% + 15px);
  }

  .grid__col--shift\:\:7\@xl {
    margin-left: calc(58.3333% + 15px);
  }

  .grid__col--shift\:\:8\@xl {
    margin-left: calc(66.6667% + 15px);
  }

  .grid__col--shift\:\:9\@xl {
    margin-left: calc(75% + 15px);
  }

  .grid__col--shift\:\:10\@xl {
    margin-left: calc(83.3333% + 15px);
  }

  .grid__col--shift\:\:11\@xl {
    margin-left: calc(91.6667% + 15px);
  }

  .grid__col--shift\:\:12\@xl {
    margin-left: calc(100% + 15px);
  }
}

.two-col-grid {
  flex-flow: wrap;
  width: calc(100% + 120px);
  margin-left: -60px;
  display: flex;
  overflow: visible;
}

.two-col-grid--wrap-reverse {
  flex-wrap: wrap-reverse;
}

.two-col-grid:after {
  content: " ";
  clear: both;
  display: block;
}

.two-col-grid__col {
  float: left;
  flex: 0 0 calc(100% - 120px);
  width: calc(100% - 120px);
  min-width: 0;
  min-height: 0;
  margin-left: 60px;
  margin-right: 60px;
  position: relative;
}

.two-col-grid__col--span\:\:1 {
  flex: 0 0 calc(50% - 120px);
  width: calc(50% - 120px);
}

.two-col-grid__col--span\:\:2 {
  flex: 0 0 calc(100% - 120px);
  width: calc(100% - 120px);
}

@media (min-width: 576px) {
  .two-col-grid__col--span\:\:1\@s {
    flex: 0 0 calc(50% - 120px);
    width: calc(50% - 120px);
  }

  .two-col-grid__col--span\:\:2\@s {
    flex: 0 0 calc(100% - 120px);
    width: calc(100% - 120px);
  }
}

@media (min-width: 769px) {
  .two-col-grid__col--span\:\:1\@m {
    flex: 0 0 calc(50% - 120px);
    width: calc(50% - 120px);
  }

  .two-col-grid__col--span\:\:2\@m {
    flex: 0 0 calc(100% - 120px);
    width: calc(100% - 120px);
  }
}

@media (min-width: 993px) {
  .two-col-grid__col--span\:\:1\@l {
    flex: 0 0 calc(50% - 120px);
    width: calc(50% - 120px);
  }

  .two-col-grid__col--span\:\:2\@l {
    flex: 0 0 calc(100% - 120px);
    width: calc(100% - 120px);
  }
}

@media (min-width: 1201px) {
  .two-col-grid__col--span\:\:1\@xl {
    flex: 0 0 calc(50% - 120px);
    width: calc(50% - 120px);
  }

  .two-col-grid__col--span\:\:2\@xl {
    flex: 0 0 calc(100% - 120px);
    width: calc(100% - 120px);
  }
}

.two-col-grid__col--shift\:\:0 {
  margin-left: 60px;
}

.two-col-grid__col--shift\:\:1 {
  margin-left: calc(50% + 60px);
}

.two-col-grid__col--shift\:\:2 {
  margin-left: calc(100% + 60px);
}

@media (min-width: 576px) {
  .two-col-grid__col--shift\:\:0\@s {
    margin-left: 60px;
  }

  .two-col-grid__col--shift\:\:1\@s {
    margin-left: calc(50% + 60px);
  }

  .two-col-grid__col--shift\:\:2\@s {
    margin-left: calc(100% + 60px);
  }
}

@media (min-width: 769px) {
  .two-col-grid__col--shift\:\:0\@m {
    margin-left: 60px;
  }

  .two-col-grid__col--shift\:\:1\@m {
    margin-left: calc(50% + 60px);
  }

  .two-col-grid__col--shift\:\:2\@m {
    margin-left: calc(100% + 60px);
  }
}

@media (min-width: 993px) {
  .two-col-grid__col--shift\:\:0\@l {
    margin-left: 60px;
  }

  .two-col-grid__col--shift\:\:1\@l {
    margin-left: calc(50% + 60px);
  }

  .two-col-grid__col--shift\:\:2\@l {
    margin-left: calc(100% + 60px);
  }
}

@media (min-width: 1201px) {
  .two-col-grid__col--shift\:\:0\@xl {
    margin-left: 60px;
  }

  .two-col-grid__col--shift\:\:1\@xl {
    margin-left: calc(50% + 60px);
  }

  .two-col-grid__col--shift\:\:2\@xl {
    margin-left: calc(100% + 60px);
  }
}

.wider-gutter-grid {
  flex-flow: wrap;
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
  overflow: visible;
}

.wider-gutter-grid--wrap-reverse {
  flex-wrap: wrap-reverse;
}

.wider-gutter-grid:after {
  content: " ";
  clear: both;
  display: block;
}

.wider-gutter-grid__col {
  float: left;
  flex: 0 0 calc(100% - 40px);
  width: calc(100% - 40px);
  min-width: 0;
  min-height: 0;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}

.wider-gutter-grid__col--span\:\:1 {
  flex: 0 0 calc(8.33333% - 40px);
  width: calc(8.33333% - 40px);
}

.wider-gutter-grid__col--span\:\:2 {
  flex: 0 0 calc(16.6667% - 40px);
  width: calc(16.6667% - 40px);
}

.wider-gutter-grid__col--span\:\:3 {
  flex: 0 0 calc(25% - 40px);
  width: calc(25% - 40px);
}

.wider-gutter-grid__col--span\:\:4 {
  flex: 0 0 calc(33.3333% - 40px);
  width: calc(33.3333% - 40px);
}

.wider-gutter-grid__col--span\:\:5 {
  flex: 0 0 calc(41.6667% - 40px);
  width: calc(41.6667% - 40px);
}

.wider-gutter-grid__col--span\:\:6 {
  flex: 0 0 calc(50% - 40px);
  width: calc(50% - 40px);
}

.wider-gutter-grid__col--span\:\:7 {
  flex: 0 0 calc(58.3333% - 40px);
  width: calc(58.3333% - 40px);
}

.wider-gutter-grid__col--span\:\:8 {
  flex: 0 0 calc(66.6667% - 40px);
  width: calc(66.6667% - 40px);
}

.wider-gutter-grid__col--span\:\:9 {
  flex: 0 0 calc(75% - 40px);
  width: calc(75% - 40px);
}

.wider-gutter-grid__col--span\:\:10 {
  flex: 0 0 calc(83.3333% - 40px);
  width: calc(83.3333% - 40px);
}

.wider-gutter-grid__col--span\:\:11 {
  flex: 0 0 calc(91.6667% - 40px);
  width: calc(91.6667% - 40px);
}

.wider-gutter-grid__col--span\:\:12 {
  flex: 0 0 calc(100% - 40px);
  width: calc(100% - 40px);
}

@media (min-width: 576px) {
  .wider-gutter-grid__col--span\:\:1\@s {
    flex: 0 0 calc(8.33333% - 40px);
    width: calc(8.33333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:2\@s {
    flex: 0 0 calc(16.6667% - 40px);
    width: calc(16.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:3\@s {
    flex: 0 0 calc(25% - 40px);
    width: calc(25% - 40px);
  }

  .wider-gutter-grid__col--span\:\:4\@s {
    flex: 0 0 calc(33.3333% - 40px);
    width: calc(33.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:5\@s {
    flex: 0 0 calc(41.6667% - 40px);
    width: calc(41.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:6\@s {
    flex: 0 0 calc(50% - 40px);
    width: calc(50% - 40px);
  }

  .wider-gutter-grid__col--span\:\:7\@s {
    flex: 0 0 calc(58.3333% - 40px);
    width: calc(58.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:8\@s {
    flex: 0 0 calc(66.6667% - 40px);
    width: calc(66.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:9\@s {
    flex: 0 0 calc(75% - 40px);
    width: calc(75% - 40px);
  }

  .wider-gutter-grid__col--span\:\:10\@s {
    flex: 0 0 calc(83.3333% - 40px);
    width: calc(83.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:11\@s {
    flex: 0 0 calc(91.6667% - 40px);
    width: calc(91.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:12\@s {
    flex: 0 0 calc(100% - 40px);
    width: calc(100% - 40px);
  }
}

@media (min-width: 769px) {
  .wider-gutter-grid__col--span\:\:1\@m {
    flex: 0 0 calc(8.33333% - 40px);
    width: calc(8.33333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:2\@m {
    flex: 0 0 calc(16.6667% - 40px);
    width: calc(16.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:3\@m {
    flex: 0 0 calc(25% - 40px);
    width: calc(25% - 40px);
  }

  .wider-gutter-grid__col--span\:\:4\@m {
    flex: 0 0 calc(33.3333% - 40px);
    width: calc(33.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:5\@m {
    flex: 0 0 calc(41.6667% - 40px);
    width: calc(41.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:6\@m {
    flex: 0 0 calc(50% - 40px);
    width: calc(50% - 40px);
  }

  .wider-gutter-grid__col--span\:\:7\@m {
    flex: 0 0 calc(58.3333% - 40px);
    width: calc(58.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:8\@m {
    flex: 0 0 calc(66.6667% - 40px);
    width: calc(66.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:9\@m {
    flex: 0 0 calc(75% - 40px);
    width: calc(75% - 40px);
  }

  .wider-gutter-grid__col--span\:\:10\@m {
    flex: 0 0 calc(83.3333% - 40px);
    width: calc(83.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:11\@m {
    flex: 0 0 calc(91.6667% - 40px);
    width: calc(91.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:12\@m {
    flex: 0 0 calc(100% - 40px);
    width: calc(100% - 40px);
  }
}

@media (min-width: 993px) {
  .wider-gutter-grid__col--span\:\:1\@l {
    flex: 0 0 calc(8.33333% - 40px);
    width: calc(8.33333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:2\@l {
    flex: 0 0 calc(16.6667% - 40px);
    width: calc(16.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:3\@l {
    flex: 0 0 calc(25% - 40px);
    width: calc(25% - 40px);
  }

  .wider-gutter-grid__col--span\:\:4\@l {
    flex: 0 0 calc(33.3333% - 40px);
    width: calc(33.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:5\@l {
    flex: 0 0 calc(41.6667% - 40px);
    width: calc(41.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:6\@l {
    flex: 0 0 calc(50% - 40px);
    width: calc(50% - 40px);
  }

  .wider-gutter-grid__col--span\:\:7\@l {
    flex: 0 0 calc(58.3333% - 40px);
    width: calc(58.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:8\@l {
    flex: 0 0 calc(66.6667% - 40px);
    width: calc(66.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:9\@l {
    flex: 0 0 calc(75% - 40px);
    width: calc(75% - 40px);
  }

  .wider-gutter-grid__col--span\:\:10\@l {
    flex: 0 0 calc(83.3333% - 40px);
    width: calc(83.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:11\@l {
    flex: 0 0 calc(91.6667% - 40px);
    width: calc(91.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:12\@l {
    flex: 0 0 calc(100% - 40px);
    width: calc(100% - 40px);
  }
}

@media (min-width: 1201px) {
  .wider-gutter-grid__col--span\:\:1\@xl {
    flex: 0 0 calc(8.33333% - 40px);
    width: calc(8.33333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:2\@xl {
    flex: 0 0 calc(16.6667% - 40px);
    width: calc(16.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:3\@xl {
    flex: 0 0 calc(25% - 40px);
    width: calc(25% - 40px);
  }

  .wider-gutter-grid__col--span\:\:4\@xl {
    flex: 0 0 calc(33.3333% - 40px);
    width: calc(33.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:5\@xl {
    flex: 0 0 calc(41.6667% - 40px);
    width: calc(41.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:6\@xl {
    flex: 0 0 calc(50% - 40px);
    width: calc(50% - 40px);
  }

  .wider-gutter-grid__col--span\:\:7\@xl {
    flex: 0 0 calc(58.3333% - 40px);
    width: calc(58.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:8\@xl {
    flex: 0 0 calc(66.6667% - 40px);
    width: calc(66.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:9\@xl {
    flex: 0 0 calc(75% - 40px);
    width: calc(75% - 40px);
  }

  .wider-gutter-grid__col--span\:\:10\@xl {
    flex: 0 0 calc(83.3333% - 40px);
    width: calc(83.3333% - 40px);
  }

  .wider-gutter-grid__col--span\:\:11\@xl {
    flex: 0 0 calc(91.6667% - 40px);
    width: calc(91.6667% - 40px);
  }

  .wider-gutter-grid__col--span\:\:12\@xl {
    flex: 0 0 calc(100% - 40px);
    width: calc(100% - 40px);
  }
}

.wider-gutter-grid__col--shift\:\:0 {
  margin-left: 20px;
}

.wider-gutter-grid__col--shift\:\:1 {
  margin-left: calc(8.33333% + 20px);
}

.wider-gutter-grid__col--shift\:\:2 {
  margin-left: calc(16.6667% + 20px);
}

.wider-gutter-grid__col--shift\:\:3 {
  margin-left: calc(25% + 20px);
}

.wider-gutter-grid__col--shift\:\:4 {
  margin-left: calc(33.3333% + 20px);
}

.wider-gutter-grid__col--shift\:\:5 {
  margin-left: calc(41.6667% + 20px);
}

.wider-gutter-grid__col--shift\:\:6 {
  margin-left: calc(50% + 20px);
}

.wider-gutter-grid__col--shift\:\:7 {
  margin-left: calc(58.3333% + 20px);
}

.wider-gutter-grid__col--shift\:\:8 {
  margin-left: calc(66.6667% + 20px);
}

.wider-gutter-grid__col--shift\:\:9 {
  margin-left: calc(75% + 20px);
}

.wider-gutter-grid__col--shift\:\:10 {
  margin-left: calc(83.3333% + 20px);
}

.wider-gutter-grid__col--shift\:\:11 {
  margin-left: calc(91.6667% + 20px);
}

.wider-gutter-grid__col--shift\:\:12 {
  margin-left: calc(100% + 20px);
}

@media (min-width: 576px) {
  .wider-gutter-grid__col--shift\:\:0\@s {
    margin-left: 20px;
  }

  .wider-gutter-grid__col--shift\:\:1\@s {
    margin-left: calc(8.33333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:2\@s {
    margin-left: calc(16.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:3\@s {
    margin-left: calc(25% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:4\@s {
    margin-left: calc(33.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:5\@s {
    margin-left: calc(41.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:6\@s {
    margin-left: calc(50% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:7\@s {
    margin-left: calc(58.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:8\@s {
    margin-left: calc(66.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:9\@s {
    margin-left: calc(75% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:10\@s {
    margin-left: calc(83.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:11\@s {
    margin-left: calc(91.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:12\@s {
    margin-left: calc(100% + 20px);
  }
}

@media (min-width: 769px) {
  .wider-gutter-grid__col--shift\:\:0\@m {
    margin-left: 20px;
  }

  .wider-gutter-grid__col--shift\:\:1\@m {
    margin-left: calc(8.33333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:2\@m {
    margin-left: calc(16.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:3\@m {
    margin-left: calc(25% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:4\@m {
    margin-left: calc(33.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:5\@m {
    margin-left: calc(41.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:6\@m {
    margin-left: calc(50% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:7\@m {
    margin-left: calc(58.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:8\@m {
    margin-left: calc(66.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:9\@m {
    margin-left: calc(75% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:10\@m {
    margin-left: calc(83.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:11\@m {
    margin-left: calc(91.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:12\@m {
    margin-left: calc(100% + 20px);
  }
}

@media (min-width: 993px) {
  .wider-gutter-grid__col--shift\:\:0\@l {
    margin-left: 20px;
  }

  .wider-gutter-grid__col--shift\:\:1\@l {
    margin-left: calc(8.33333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:2\@l {
    margin-left: calc(16.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:3\@l {
    margin-left: calc(25% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:4\@l {
    margin-left: calc(33.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:5\@l {
    margin-left: calc(41.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:6\@l {
    margin-left: calc(50% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:7\@l {
    margin-left: calc(58.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:8\@l {
    margin-left: calc(66.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:9\@l {
    margin-left: calc(75% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:10\@l {
    margin-left: calc(83.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:11\@l {
    margin-left: calc(91.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:12\@l {
    margin-left: calc(100% + 20px);
  }
}

@media (min-width: 1201px) {
  .wider-gutter-grid__col--shift\:\:0\@xl {
    margin-left: 20px;
  }

  .wider-gutter-grid__col--shift\:\:1\@xl {
    margin-left: calc(8.33333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:2\@xl {
    margin-left: calc(16.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:3\@xl {
    margin-left: calc(25% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:4\@xl {
    margin-left: calc(33.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:5\@xl {
    margin-left: calc(41.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:6\@xl {
    margin-left: calc(50% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:7\@xl {
    margin-left: calc(58.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:8\@xl {
    margin-left: calc(66.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:9\@xl {
    margin-left: calc(75% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:10\@xl {
    margin-left: calc(83.3333% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:11\@xl {
    margin-left: calc(91.6667% + 20px);
  }

  .wider-gutter-grid__col--shift\:\:12\@xl {
    margin-left: calc(100% + 20px);
  }
}

.downloads-list {
  border: 1px solid #ddd;
  margin-bottom: 40px;
}

.download {
  border-bottom: 1px solid #ddd;
  display: flex;
}

.download__labels {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  display: flex;
}

.download__actions {
  border-left: 1px solid #ddd;
  justify-content: center;
  align-items: center;
  width: 50px;
  display: inline-flex;
}

.download:last-child {
  border-bottom: 0;
}

.download__title {
  color: #5d5d5d;
  padding: 20px;
  line-height: 1.3;
  text-decoration: none;
  display: inline-block;
}

.download__title:hover {
  color: #000;
}

.download__type {
  opacity: .5;
  text-transform: uppercase;
}

.download__button {
  color: #5d5d5d;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.download__button:hover {
  color: #000;
}

.hero {
  background-color: #000;
  height: 370px;
  margin-bottom: 40px;
  position: relative;
}

@media (min-width: 769px) {
  .hero {
    height: 570px;
  }
}

@media (min-width: 993px) {
  .hero {
    margin-bottom: 80px;
  }
}

@media (min-width: 769px) {
  .hero--small {
    height: 370px;
  }
}

.hero--nm {
  margin-bottom: 0;
}

@media (min-width: 769px) {
  .hero--system {
    margin-bottom: 0;
  }
}

.hero--news-index {
  color: #000;
  background: #fff;
  height: auto;
  margin-bottom: 40px;
  padding-top: 80px;
}

@media (min-width: 769px) {
  .hero--news-index {
    margin-bottom: 80px;
    padding-top: 120px;
  }
}

.hero--news-index .hero__text {
  color: #000;
}

.hero--news-index .hero__overlay {
  position: relative;
  bottom: 0;
}

.hero__intro-text {
  color: #2d2d2d;
  margin-top: 40px;
  font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 20px;
  line-height: 1.65;
}

@media (min-width: 769px) {
  .hero__intro-text {
    font-size: 24px;
    line-height: 1.54;
  }
}

.hero__overlay {
  width: 100%;
  position: absolute;
  bottom: 40px;
}

@media (min-width: 769px) {
  .hero__overlay {
    bottom: 80px;
  }

  .hero__overlay__inner {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
  }
}

.hero__text {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
  font-family: ArialMTStd-Black, Arial Bold, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 33px;
}

@media (min-width: 769px) {
  .hero__text {
    margin-bottom: 0;
    font-size: 50px;
  }
}

.hero__breadcrumb {
  color: #fff;
  margin-bottom: 10px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
}

@media (min-width: 993px) {
  .about .hero {
    margin-bottom: 0;
  }

  .about .hero__overlay {
    bottom: 160px;
  }
}

.icon, .icon > svg {
  max-width: 100%;
  display: block;
}

.icon > svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
  position: absolute;
}

.icon {
  position: relative;
}

.icon:after {
  content: "";
  width: auto;
  height: 0;
  display: block;
}

.icon--logo-twitter-2, .icon--logo-youtube, .icon--logo-linkedin {
  width: 25px;
  height: 25px;
}

.icon--shape-star, .icon--launch-app, .icon--zoom, .icon--menu-8, .icon--move-layer-down, .icon--e-remove, .icon--down-arrow, .icon--up-arrow, .icon--left-arrow, .icon--right-arrow {
  width: 22px;
  height: 22px;
}

.icon--full-screen, .icon--gallery-view {
  width: 15px;
  height: 15px;
}

.icon--video-64 {
  width: 20px;
  height: 20px;
}

.icon--plus {
  width: 8px;
  height: 8px;
}

.news-preview {
  color: #000;
  font-size: 16px;
  text-decoration: none;
  display: flex;
}

.news-preview--feature {
  margin-bottom: 40px;
  display: block;
}

@media (min-width: 769px) {
  .news-preview--feature {
    margin-bottom: 0;
  }
}

.news-preview--feature .news-preview__image {
  width: 100%;
}

.news-preview--feature .news-preview__title {
  margin-top: 30px;
  font-size: 22px;
  line-height: 1.2;
}

@media (min-width: 769px) {
  .news-preview--feature .news-preview__title {
    font-size: 28px;
  }
}

.news-preview--small {
  border-bottom: 1px solid #dedede;
  flex-direction: column;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

@media (min-width: 769px) {
  .news-preview--small {
    flex-direction: row;
  }
}

.news-preview--small .news-preview__image {
  width: 100%;
}

@media (min-width: 769px) {
  .news-preview--small .news-preview__image {
    width: 255px;
  }

  .news-preview--small .news-preview__info {
    padding-left: 20px;
  }
}

.news-preview--small .news-preview__title {
  font-size: 18px;
  line-height: 1.2;
}

.news-preview--small:hover, .news-preview--small:focus {
  outline: none;
}

.news-preview--small:hover .news-preview__title, .news-preview--small:focus .news-preview__title {
  text-decoration: underline;
}

.news-preview__image {
  flex: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.news-preview__image:before {
  content: "";
  width: 100%;
  padding-top: 56.25%;
  display: block;
}

.news-preview__image img {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.news-preview__info {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 10px 0 0;
  font-size: 14px;
  display: flex;
}

@media (min-width: 769px) {
  .news-preview__info {
    padding: 1em 0;
  }
}

.news-preview__excerpt {
  margin-bottom: 20px !important;
}

@media (min-width: 769px) {
  .news-preview__excerpt {
    margin-bottom: 30px !important;
  }
}

.news-preview__title {
  font-weight: bold;
  margin-bottom: 10px !important;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
}

@media (min-width: 769px) {
  .news-preview__title {
    margin-bottom: 20px !important;
  }
}

.news-preview__date {
  text-transform: uppercase;
  color: #848484;
  font-size: 14px;
  margin-bottom: 0 !important;
  line-height: 1 !important;
}

.news-preview a {
  text-decoration: none;
  display: block;
}

.news-preview a:hover, .news-preview a:focus {
  outline: none;
}

.news-preview a:hover .news-preview__title, .news-preview a:focus .news-preview__title {
  text-decoration: underline;
}

#map {
  width: 100%;
  height: 100%;
}

.partners-map {
  margin-bottom: 60px;
  position: relative;
}

.partners-map:before {
  content: "";
  width: 100%;
  padding-top: 63.0435%;
  display: block;
}

.partners-map__inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.map-snackbar {
  color: #fff;
  background-color: #000;
  border-radius: 3px;
  padding: 10px 20px;
  transition: background-color .5s ease-in-out;
  display: none;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.map-snackbar--open {
  display: block;
}

.map-snackbar--success {
  background-color: #7fb655;
}

.map-snackbar--error {
  background-color: #e53c3c;
}

.partner-info {
  background-color: #fff;
  margin-bottom: 30px;
  transition: background-color .3s linear;
  display: flex;
  position: relative;
}

.partner-info--installer {
  border-top: 6px solid #4eb2ff;
}

.partner-info--stockist {
  border-top: 6px solid #7eb555;
}

.partner-info__installer {
  margin-top: 25px;
  display: flex;
}

.partner-info__installer-details {
  width: 50%;
  padding-right: 10px;
}

.partner-info__installer-label {
  text-transform: uppercase;
  letter-spacing: 1.54px;
  align-items: center;
  margin-bottom: 10px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 10px;
  display: inline-flex;
}

.copy .partner-info__installer-type, .copy .partner-info__installer-specialism {
  margin: 0;
}

.partner-info__installer-link {
  color: #000;
}

.partner-info--selected.partner-info--installer {
  border: 6px solid #4eb2ff;
}

.partner-info--selected.partner-info--stockist {
  border: 6px solid #7eb555;
}

.partner-info__inner {
  border: 1px solid #ddd;
  width: 100%;
  overflow: visible;
}

.partner-info__inner--in-modal {
  border: none;
  border-top: 6px solid #4eb2ff;
}

.partner-info__inner--in-modal .partner-info__title {
  padding-right: 60px;
}

.partner-info__inner-details {
  width: 50%;
  padding-right: 10px;
}

.partner-info__header {
  padding: 30px;
}

.copy .partner-info__title {
  color: #000;
  margin-bottom: 0;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 22px;
}

.copy .partner-info__title--installer {
  color: #4eb2ff;
}

.copy .partner-info__title--stockist {
  color: #7eb555;
}

.partner-info__details {
  border-top: 1px solid #ddd;
  padding: 30px;
  display: flex;
}

.partner-info__details p {
  margin-bottom: 0;
}

.periodic-element {
  color: #fff;
  flex-direction: column;
  flex-basis: 120px;
  height: 120px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 20px 13px;
  text-decoration: none;
  display: flex;
}

.periodic-element__title {
  font-size: 20px;
  font-weight: bold;
}

.periodic-element__subtitle {
  opacity: .5;
  font-size: 10px;
}

.periodic-element__desc {
  margin-top: auto;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.2;
}

.periodic-modal-wrap {
  z-index: 10;
  align-items: center;
  justify-items: center;
  width: 100%;
  min-height: calc(100vh - 51px);
  margin-top: 51px;
  padding: 20px;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 993px) {
  .periodic-modal-wrap {
    margin-top: 25px;
  }
}

.periodic-modal {
  background-color: #fff;
  max-width: 640px;
  margin: auto;
}

.periodic-modal__title {
  color: #fff;
  padding: 20px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 22px;
}

@media (min-width: 769px) {
  .periodic-modal__title {
    padding: 30px;
  }
}

.periodic-modal__inner {
  padding: 20px;
}

@media (min-width: 576px) {
  .periodic-modal__inner {
    flex-wrap: wrap;
    display: flex;
  }
}

@media (min-width: 993px) {
  .periodic-modal__inner {
    padding: 30px 30px 60px;
  }
}

.periodic-modal__main {
  flex-basis: 60%;
  padding-right: 30px;
}

.periodic-modal__side {
  flex: 1;
}

.periodic-modal__label {
  color: #5d5d5d;
  margin-bottom: 20px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  display: block;
}

.periodic-modal__system {
  color: #000;
}

.periodic-modal-header-wrap {
  justify-content: space-between;
  display: flex;
}

.periodic-modal-close {
  color: inherit;
}

.periodic-modal.periodic-modal--popup .periodic-modal__title {
  padding: 30px;
}

.person-details {
  margin-bottom: 20px;
}

.person-details p {
  margin: 0;
}

.person-details__name {
  color: #5d5d5d;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.person-details.redactor .person-details__email {
  color: #009a3e;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.post-details {
  align-items: center;
  display: flex;
}

.post-details__date {
  color: #5d5d5d;
  margin-bottom: 10px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.post-details__avatar {
  border-radius: 50%;
  width: 60px;
  margin-right: 20px;
}

.project-details {
  margin-bottom: 30px;
}

.project-details__label {
  color: #606060;
  margin-bottom: 10px;
  font-family: HelveticaNeue-Light, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: normal;
  display: block;
}

.project-details__desc {
  color: #000;
  margin-bottom: 30px;
  font-size: 16px;
}

@media (min-width: 769px) {
  .project-details__desc {
    margin-bottom: 0;
  }
}

.project-details__desc a {
  color: #000;
}

.search-result__heading {
  color: #00a03d;
  margin-bottom: 10px;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 18px;
  text-decoration: none;
  display: block;
}

.search-result__tags {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.search-box {
  border: 1px solid #dedede;
  margin-bottom: 20px;
  padding: 12px 20px 12px 50px;
  position: relative;
}

.search-box i {
  width: 20px;
  height: 20px;
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 17px;
}

.search-box__input {
  border: 0;
  width: 100%;
  font-size: 14px;
  line-height: 31px;
}

.search-box__input:focus {
  outline: 0;
}

.search-results {
  order: 2;
}

@media (min-width: 993px) {
  .search-results {
    order: 1;
  }
}

.search-results-form {
  order: 1;
}

@media (min-width: 993px) {
  .search-results-form {
    order: 2;
  }
}

.nav__search {
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  background: #fff;
  width: 100%;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 576px) {
  .nav__search {
    width: 370px;
    right: 210px;
  }
}

.nav__search--open {
  pointer-events: all;
  opacity: 1;
  z-index: 1;
  box-shadow: 0 0 3px #dedede;
}

.nav__search__inner {
  padding: 10px 10px 10px 20px;
  position: relative;
}

.nav__search__inner:before {
  content: "\a ";
  border-bottom: 5px solid #fff;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  top: -5px;
  right: 170px;
}

@media (min-width: 576px) {
  .nav__search__inner:before {
    right: 70px;
  }
}

.nav__search__input {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: 0;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  line-height: 31px;
}

.nav__search__input:focus {
  outline: 0;
}

.nav__search__btn {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #7eb555;
  border: 0;
  width: 55px;
  height: 55px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.nav__search__btn:focus {
  outline: 0;
}

.system__prefix {
  color: #009a3e;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: ArialMTStd-Black, Arial Bold, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  display: block;
}

.system__title {
  text-transform: uppercase;
  color: #000;
  margin-bottom: 20px;
  font-family: ArialMTStd-Black, Arial Bold, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 24px;
}

.system__intro {
  color: #000;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 32px;
}

.system__buildup {
  max-width: 100%;
}

.system__media {
  position: relative;
}

.system__expander {
  color: #fff;
  z-index: 1;
  background: #009a3e;
  padding: 10px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.system__elements {
  flex-flow: wrap;
  margin-bottom: 30px;
  display: flex;
}

.video {
  padding: 0 0 56.25%;
  position: relative;
}

.video__iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .body--case-studies-index .contact-fab {
    display: none;
  }
}

.contact-fab {
  z-index: 50;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #019c49;
  border: 3px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  transition: background-color .2s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

@media (min-width: 769px) {
  .contact-fab {
    width: 60px;
    height: 60px;
    bottom: 30px;
    right: 30px;
  }
}

.contact-fab svg {
  width: 18px;
}

@media (min-width: 769px) {
  .contact-fab svg {
    width: 24px;
  }
}

.contact-fab:hover {
  background-color: #7fb655;
}

.form {
  width: 100%;
  margin-bottom: 80px;
}

.form__row {
  position: relative;
}

.form__row--radio_group {
  margin-bottom: 30px;
}

.form__row--radio_group label {
  color: #000;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 1px;
  display: flex;
}

.form__row--radio_group label:last-child {
  margin-bottom: 0;
}

.form__row--radio_group label .form__input--radio_group {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  padding: 0;
  display: block;
  position: relative;
}

.form__row--radio_group label .form__input--radio_group:after {
  content: "";
  visibility: visible;
  z-index: 2;
  background-color: #fff;
  border: 9px solid #fff;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 1px #dedede;
}

.form__row--radio_group label .form__input--radio_group:checked:after {
  content: "";
  visibility: visible;
  z-index: 2;
  background-color: #88b361;
  border: 9px solid #fff;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 1px #dedede;
}

.form__label {
  color: #000;
  margin-bottom: 15px;
  font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  display: block;
}

.form__label--required {
  padding-right: 70px;
}

.form__label--required:after {
  content: "required";
  text-transform: uppercase;
  color: #b1b1b1;
  font-size: 10px;
  position: absolute;
  top: 5px;
  right: 0;
}

.form__instructions {
  color: #000;
  margin-bottom: 15px;
  font-size: 16px;
  display: block;
}

.form__input {
  background: #fff;
  border: 1px solid #dedede;
  width: 100%;
  height: 57px;
  margin-bottom: 30px;
  padding: 0 16px;
  font-size: 16px;
  display: block;
}

.form__input:focus {
  outline: 0;
}

.form__input--textarea {
  resize: none;
  height: 200px;
  padding: 16px;
}

@media (min-width: 769px) {
  .form__input--textarea {
    height: 300px;
  }
}

.form__input--textarea[name="address"] {
  height: auto;
}

.form__input--submit {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  border-radius: 40px;
  width: auto;
  min-width: 130px;
  height: auto;
  padding: 16px;
  background: #000 !important;
}

.form__input--submit:hover {
  background: #333 !important;
}

.form__input--downloads-submit {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  background: #459952 !important;
}

.form__input--downloads-submit:hover {
  background: #7fc58a !important;
}

.form--downloads {
  margin-bottom: 30px;
}

.ff-form-success {
  text-align: center;
  color: #fff;
  background-color: #019c49;
  margin-bottom: 30px;
  padding: 20px 10px;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  line-height: 1.2;
}

.contact-tabs {
  justify-content: space-between;
  margin-bottom: 60px;
  display: none;
}

@media (min-width: 769px) {
  .contact-tabs {
    display: flex;
  }
}

.contact-tabs__button {
  color: #6c6c6c;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: #eaeaea;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 30px 10px;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  transition: all .1s ease-in;
}

.contact-tabs__button--active {
  color: #fff;
  background-color: #000;
}

.contact-tabs__button:focus {
  outline: 0;
}

.contact-tabs__button:hover {
  color: #fff;
  background-color: #000;
}

.contact-tabs__select-wrapper {
  display: block;
  position: relative;
}

@media (min-width: 769px) {
  .contact-tabs__select-wrapper {
    display: none;
  }
}

.contact-tabs__select-wrapper i {
  color: #fff;
  cursor: pointer;
  pointer-events: none;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 20px;
  right: 15px;
}

.contact-tabs__select {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: #fff;
  white-space: nowrap;
  background-color: #000;
  border: 0;
  border-radius: 0;
  width: 100%;
  margin-bottom: 40px;
  padding: 20px 40px 20px 20px;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  overflow: hidden;
}

.contact-tabs__select::-ms-expand {
  display: none;
}

.content-reveal {
  opacity: 0;
  height: 0;
  transition: opacity .5s ease-out;
  overflow: hidden;
}

.content-reveal--active {
  opacity: 1;
  height: auto;
}

#contact-map {
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
}

@media (min-width: 769px) {
  #contact-map {
    height: 500px;
    margin-bottom: 60px;
  }
}

.partners-search {
  width: 100%;
  margin-bottom: 30px;
}

@media (min-width: 769px) {
  .partners-search {
    margin-bottom: 24px;
  }
}

.partners-search__form {
  width: 100%;
  height: 60px;
  display: block;
}

@media (min-width: 769px) {
  .partners-search__form {
    height: 70px;
  }
}

.partners-search__row {
  display: flex;
}

.partners-search__search {
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #ddd;
  border-right: 0;
  border-radius: 0;
  width: 100%;
  height: 60px;
  margin: 0;
  padding: 0 20px;
  font-size: 16px;
  display: block;
}

@media (min-width: 769px) {
  .partners-search__search {
    height: 70px;
    padding: 0 30px;
  }
}

.partners-search__search::-webkit-input-placeholder {
  color: #000;
}

.partners-search__search::-ms-input-placeholder {
  color: #000;
}

.partners-search__search::placeholder {
  color: #000;
}

.partners-search__submit {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #000;
  border: none;
  border-radius: 0;
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
}

@media (min-width: 769px) {
  .partners-search__submit {
    flex: 0 0 70px;
    width: 70px;
    height: 70px;
  }
}

.tooltip {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
  position: relative;
}

.tooltip-info {
  z-index: 9999;
  box-sizing: border-box;
  text-transform: none;
  color: #a7a7a7;
  letter-spacing: 0;
  background-color: #000;
  border: 1px solid #000;
  min-width: 300px;
  padding: 30px;
  font-family: HelveticaNeue-Medium, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  line-height: 23px;
  display: none;
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%);
}

.tooltip-info p {
  margin-bottom: 0 !important;
}

.tooltip-info p:nth-of-type(2) {
  margin-top: 20px;
}

.tooltip-info strong {
  color: #fff;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.tooltip:hover .tooltip-info {
  display: block;
}

.tooltip i {
  width: 24px;
  height: 12px;
  margin-left: -12px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
}

.tooltip i:after {
  content: "";
  background-color: #000;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%)rotate(45deg);
}

.aside-content {
  display: flex;
}

.aside-content__media {
  flex: 0 0 70px;
}

.aside-content__copy {
  padding-right: 15px;
}

.aside-content__logo {
  margin-bottom: 20px;
}

.action-bar {
  background-color: #fafafa;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 40px;
  padding: 15px 0;
  display: none;
}

@media (min-width: 769px) {
  .action-bar {
    margin-bottom: 80px;
    display: block;
  }
}

.action-bar__inner {
  justify-content: space-between;
  align-items: center;
  height: 30px;
  display: flex;
}

.action-bar__nav {
  height: 100%;
  display: flex;
}

.action-bar__nav li + li:before {
  content: "";
  border-right: 1px solid #dfdfdf;
  margin-right: 30px;
  padding-right: 30px;
}

.action-bar__nav-item {
  line-height: 30px;
  display: inline-flex;
}

.action-bar__nav-link {
  color: #000;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.action-bar__nav-link svg {
  margin-right: 10px;
}

.action-bar__nav-link:hover {
  text-decoration: underline;
}

.comparisons__card-content li, .comparisons__table-cell--left {
  color: #000;
  font-size: 14px;
  line-height: 25px;
}

.comparisons__card-header a, .comparisons__table-cell--header a {
  color: #747474;
  font-size: 12px;
}

.comparisons {
  width: 100%;
}

.comparisons strong {
  font-weight: bold;
}

.comparisons svg {
  min-width: 25px;
}

.comparisons__table {
  width: 100%;
  margin-bottom: 20px;
  display: none;
}

@media (min-width: 769px) {
  .comparisons__table {
    display: block;
  }
}

.comparisons__table-cell {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  padding: 20px;
}

@media (min-width: 1201px) {
  .comparisons__table-cell {
    min-width: 170px;
  }
}

.comparisons__table-cell--blank {
  opacity: 0;
  visibility: hidden;
  border: none;
}

.comparisons__table-cell--empty {
  border-top: 1px solid #d8d8d8;
}

.comparisons__table-cell--category {
  border: none;
  font-size: 16px;
}

.comparisons__table-cell--header {
  padding: 30px;
  font-size: 16px;
}

.comparisons__table-cell--header img {
  width: 70px;
  display: inline-block;
}

.comparisons__table-cell--header p {
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.comparisons__table-cell--left {
  text-align: left;
  width: 300px;
}

@media (min-width: 769px) {
  .comparisons__cards {
    display: none;
  }
}

.comparisons__header {
  text-align: center;
  width: 100%;
  padding: 20px;
  font-size: 16px;
}

.comparisons__card {
  border: 1px solid #d8d8d8;
  margin-bottom: 20px;
}

.comparisons__card:first-child {
  border-top: none;
}

.comparisons__card-header {
  border-bottom: 1px solid #d8d8d8;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  display: flex;
}

.comparisons__card-header img {
  width: 60px;
  margin-right: 20px;
  display: block;
}

.comparisons__card-header p {
  color: #000;
  margin-bottom: 10px;
  font-size: 16px;
}

.comparisons__card-content {
  padding: 20px 30px;
}

.comparisons__card-content li {
  flex-flow: row;
  justify-content: flex-start;
  margin-bottom: 20px;
  display: flex;
}

.comparisons__card-content li svg {
  margin-right: 30px;
}

.comparisons__footnote {
  margin-bottom: 60px;
  line-height: 19px;
}

.download-modal {
  justify-content: center;
  align-items: center;
  display: flex;
}

.download-modal__wrapper {
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media (min-width: 993px) {
  .download-modal__wrapper {
    flex-direction: row;
  }
}

.download-modal__slider, .download-modal__content {
  width: 100%;
}

.download-modal__slider {
  height: 250px;
  overflow-x: hidden;
}

@media (min-width: 993px) {
  .download-modal__slider {
    height: auto;
    min-height: 600px;
  }
}

.download-modal__slider .slick-list, .download-modal__slider .slick-track, .download-modal__slider .slick-slide, .download-modal__slider .slick-slide > div {
  height: 100%;
}

.download-modal__slide {
  object-fit: cover;
  height: 100%;
}

.download-modal__success {
  color: #000;
  z-index: 10;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.download-modal__success p {
  margin-top: 30px;
}

.download-modal__content {
  padding: 30px;
  position: relative;
}

@media (min-width: 993px) {
  .download-modal__content {
    min-height: 300px;
    padding: 60px;
  }
}

.download-modal__content h2 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

@media (min-width: 993px) {
  .download-modal__content h2 {
    font-size: 28px;
  }
}

.download-modal__content strong {
  color: #009a3e;
}

.download-modal__content p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
}

.download-modal__content .ff-form-success {
  display: none;
}

.download-modal__disclaimer {
  align-items: center;
  display: flex;
}

.download-modal__disclaimer svg {
  flex-shrink: 0;
  width: 14px;
}

.download-modal__disclaimer p {
  margin-bottom: 0;
  margin-left: 15px;
}

.download-modal__disclaimer p, .download-modal__disclaimer a {
  color: #000;
  font-size: 12px;
}

.download-modal__disclaimer a:hover {
  color: #459952;
}

.download-modal__fancybox.fancybox-is-open .fancybox-caption a {
  color: #459952;
  text-decoration: underline;
}

.filter-bar {
  padding: 30px 0;
}

@media (min-width: 769px) {
  .filter-bar {
    padding: 50px 0;
  }
}

.filter-bar--partners {
  padding-top: 0;
}

.filter-bar__filters {
  flex-direction: column;
  display: flex;
}

@media (min-width: 769px) {
  .filter-bar__filters {
    flex-direction: row;
    align-items: flex-end;
  }
}

.filter-bar__filter {
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 20px;
}

@media (min-width: 769px) {
  .filter-bar__filter {
    margin-bottom: 0;
    padding-right: 30px;
  }
}

.filter-bar__label {
  text-transform: uppercase;
  letter-spacing: 1.54px;
  color: #000;
  align-items: center;
  margin-bottom: 15px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 10px;
  display: inline-flex;
}

.filter-bar__select-wrapper {
  position: relative;
}

.filter-bar__select-wrapper i {
  color: #a9a9a9;
  cursor: pointer;
  pointer-events: none;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 11px;
  right: 11px;
}

.filter-bar__select, .filter-bar__search {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  display: block;
}

.filter-bar__select::-ms-expand {
  display: none;
}

.filter-bar__search::-ms-expand {
  display: none;
}

.filter-bar__submit {
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
  background-color: #000 !important;
}

@media (min-width: 769px) {
  .filter-bar__submit {
    width: 60px;
  }
}

.filter-bar__submit:hover {
  background: #333 !important;
}

.product-block__header {
  flex-flow: row;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.product-block__header img {
  max-width: 35px;
  margin-right: 20px;
}

@media (min-width: 769px) {
  .product-block__header img {
    max-width: 65px;
  }
}

.product-block__header h2 {
  margin: 0;
}

.product-block .button {
  margin-bottom: 0;
}

.lazy-image img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
}

.basic-header {
  margin-top: 80px;
}

@media (min-width: 769px) {
  .basic-header {
    margin-top: 60px;
  }
}

.basic-header__breadcrumb {
  color: #000;
  margin-bottom: 20px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
}

.basic-header__title {
  text-transform: uppercase;
  color: #000;
  margin-bottom: 30px;
  font-family: ArialMTStd-Black, Arial Bold, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 33px;
}

@media (min-width: 769px) {
  .basic-header__title {
    margin-bottom: 40px;
    font-size: 50px;
  }
}

.cpd-detail__block {
  border-bottom: 1px solid #dedede;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.cpd-detail__block:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.cpd-details__row {
  margin-bottom: 25px;
  display: flex;
}

.cpd-details__row:last-child {
  margin-bottom: 0;
}

.cpd-details__icon {
  width: 16px;
  height: 16px;
  margin-top: 4px;
}

.cpd-details__icon svg {
  display: block;
}

.cpd-details__info {
  padding-left: 15px;
}

.cpd-details__type {
  letter-spacing: .1em;
  color: #828282;
  text-transform: uppercase;
  margin-bottom: 2px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 10px;
  font-weight: bold;
  line-height: 25px;
}

.cpd-details__value {
  color: #000;
  font-size: 14px;
}

.cpd-form {
  background: #fafafa;
  padding: 80px 0;
}

.share-links li {
  margin-bottom: 16px;
  display: block;
}

.share-links li:last-child {
  margin-bottom: 0;
}

.share-links a {
  color: #000;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  display: flex;
}

.share-links a svg {
  margin-right: 14px;
}

.share-links a:hover {
  text-decoration: underline;
}

.news-header {
  border-top: 1px solid #000;
  font-weight: bold;
  margin-bottom: 20px !important;
  padding-top: 15px !important;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
  font-size: 16px !important;
  line-height: 1 !important;
}

.pagination {
  color: #000;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 16px;
  display: flex;
}

.pagination__middle {
  flex: none;
  width: auto;
  display: flex;
}

.pagination__middle > div {
  margin: 0 2px;
}

.pagination__select {
  position: relative;
}

.pagination__select select {
  color: #00a03d;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  padding: 0 20px 0 5px;
  font-family: HelveticaNeue-Bold, HelveticaNeue, Helvetica Neue, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  display: block;
  position: relative;
}

.pagination__select select::-ms-expand {
  opacity: 0;
  display: none;
}

.pagination__select:after {
  pointer-events: none;
  z-index: 1;
  content: "";
  background: none;
  border-bottom: 2px solid #979797;
  border-right: 2px solid #979797;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)translateX(-6px)rotate(45deg);
}

.pagination__disabled-link {
  color: #aaa;
  cursor: default;
}

.pagination a {
  color: #000;
  text-decoration: none;
}

/*# sourceMappingURL=app.css.map */
