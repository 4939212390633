.psuedo-select {
    position: relative;
    text-align: left;

    i {
        position: absolute;
        right: 0;
        top: 0;
        color: #a9a9a9;
        width: 17px;
        height: 17px;
        cursor: pointer;
    }

    &__toggle {
        background-position: calc(100% - 20px) center;
        border: #dddddd 1px solid;
        appearance: none;
        border-radius: 0;
        font-family: $fontFamilyStandardMedium;
        font-size: 16px;
        color: #000;
        padding: 20px;
        width: 100%;
        outline: none;
        display: block;
        text-decoration: none;

        &:hover {
            background-color: #fafafa;
        }

        &::-ms-expand {
            display: none;
        }
    }

    &__options {
        position: absolute;
        display: none;
        top: 100%;
        width: 100%;
        max-height: 225px;
        overflow-y: scroll;
        //border: #DDDDDD 1px solid;
        border-top: 0;
        border-bottom: 0;
        z-index: 10;
    }

    &__option a {
        display: block;
        //border-bottom: #DDDDDD 1px solid;
        padding: 15px 20px;
        font-size: 14px;
        text-decoration: none;
        background: #fafafa;
        color: #666;

        &:hover {
            background: #222222;
            color: #fff;
        }
    }

    &--inline {
        display: inline-block;

        .psuedo-select__toggle {
            padding-right: 60px;
        }
    }

    &--dark {
        .psuedo-select__toggle {
            //background: #000 url('../img/svg/drop.svg') no-repeat;
            background-position: calc(100% - 20px) center;
            color: #fff;
            border-color: #666;

            &:hover {
                background-color: #333;
            }
        }

        .psuedo-select__options {
            border-color: #666;
        }

        .psuedo-select__option a {
            background: #333;
            color: red;
            border-color: #666;

            &:hover {
                background: map-get($colour, greenXD);
                color: #fff;
            }
        }
    }
}
