.post-details {
    display: flex;
    align-items: center;
    &__date {
        color: map-get($colour, startS);
        font-family: $fontFamilyStandardBold;
        margin-bottom: map-get($spacing, s);
    }

    &__avatar {
        width: 60px;
        margin-right: map-get($spacing, m);
        border-radius: 50%;
    }
}
