.basic-header {
    margin-top: map-get($spacing, xxxl);
    @include media(">m") {
        margin-top: map-get($spacing, xxl);
    }

    &__breadcrumb {
        color: map-get($colour, startD);
        text-decoration: none;
        display: inline-block;
        margin-bottom: map-get($spacing, m);
        font-family: $fontFamilyStandardBold;
        font-size: map-get($fontSize, l);
    }

    &__title {
        text-transform: uppercase;
        font-size: map-get($fontSize, xxxxxl);
        font-family: $fontFamilyAltBlack;
        color: map-get($colour, startD);
        margin-bottom: map-get($spacing, l);
        @include media(">m") {
            font-size: map-get($fontSize, xxxxxxl);
            margin-bottom: map-get($spacing, xl);
        }
    }
}
