/*
    This mixin has a lot of code that might at first glance look WET.

    In particular when we create different viewport versions of shifts
    and spans, you will notice we repeat the code. But it is for an important
    reason, because if we put the responsive @media queries directly inline
    the SCSS would compile with that order, which isn't what we want.

    For example...

    We want .grid__col--span::4@l to trump .grid__col--span::12 but if
    we did the responsive loop for all of 4 columns our styles would be
    overriden by the time we got to grid__col--span::12

    So rendering out each device at a time solves this problem.
*/

@use "sass:math";

@mixin generateColSpan($span, $columns, $gutter) {
    $percentage: percentage(math.div($span, $columns));
    flex: 0 0 calc(#{$percentage} - #{$gutter});
    width: calc(#{$percentage} - #{$gutter});
}

@mixin generateColumnShift($span, $columns, $gutter) {
    $percentage: percentage(math.div($span, $columns));
    margin-left: calc(#{$percentage} + #{$gutter*0.5});
}

@mixin generateGrid($gutter,$columns) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: visible;
    width: calc(100% + #{$gutter});
    margin-left: -#{$gutter*0.5};

    &--wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    // Clear for old browsers that are using clear
    &:after {
        content: " ";
        display: block;
        clear: both;
    }

    &__col {
        flex: 0 1 auto;
        margin-left: #{$gutter*0.5};
        margin-right: #{$gutter*0.5};
        position: relative;
        min-width: 0;

        // Old browser support
        float: left;
        min-height: 0;

        // Make the default column full span
        @include generateColSpan($columns, $columns, $gutter);

        // Generate spans for the default 'all' device
        @for $i from 1 to $columns + 1 {
            &--span\:\:#{$i} {
                @include generateColSpan($i, $columns, $gutter);
            }
        }

        // Generate spans for each of our supported breakpoints
        @each $target, $value in $breakpoints {
            @for $i from 1 to $columns + 1 {
                &--span\:\:#{$i} {
                    @include target($target){
                        @include generateColSpan($i, $columns, $gutter);
                    }
                }
            }
        }

        /*
            Unlike spans, we need to start shifts at 0 because there
            is a chance we would want to disable the shift.

            For example, you might want to shift by 3 on small devices
            but as soon as you hit a large device you might want no
            shifting at all.
        */

        // Generate shifts for the default 'all' device
        @for $i from 0 to $columns + 1 {
            &--shift\:\:#{$i} {
                @include generateColumnShift($i, $columns, $gutter);
            }
        }

        // Generate shifts for each of our supported breakpoints
        @each $target, $value in $breakpoints {
            @for $i from 0 to $columns + 1 {
                &--shift\:\:#{$i} {
                    @include target($target){
                        @include generateColumnShift($i, $columns, $gutter);
                    }
                }
            }
        }

    }
}

.grid {
    $defaultGridGutter: map-get($spacing, l);
    $defaultGridColumns: nth($gridColumnOptions, 1); // In future we might support multiple grid column options
    @include generateGrid($defaultGridGutter, $defaultGridColumns);
}

.two-col-grid {
    @include generateGrid(map-get($spacing, xxxxl), 2);
}

.wider-gutter-grid {
    @include generateGrid(map-get($spacing, xl), 12);
}
