.action-bar {
    background-color: #fafafa;
    margin-bottom: map-get($spacing, xl);
    padding: 15px 0;
    border-bottom: 1px solid map-get($colour, greyXXXL);
    display: none;

    @include media(">m") {
        margin-bottom: map-get($spacing, xxxl);
        display: block;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: map-get($spacing, l);
    }

    &__nav {
        display: flex;
        height: 100%;

        li + li:before {
            content: "";
            border-right: 1px solid map-get($colour, greyXXXL);
            margin-right: map-get($spacing, l);
            padding-right: map-get($spacing, l);
        }
    }

    &__nav-item {
        display: inline-flex;
        line-height: map-get($spacing, l);
    }

    &__nav-link {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        color: map-get($colour, startD);

        svg {
            margin-right: 10px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
