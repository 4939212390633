/*
    Project Variables
    ---
    Use this section for any custom variables you need to set for this project
    and this project alone.
*/

/*
    Font Families
    ---
    The various font family stacks used around the website.
    All font families should inherit the $fontFamilyBase
    as it contains OS-level fonts and emoji support fallbacks.
*/

$fontFamilyBase: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$fontFamilyStandard: $fontFamilyBase;
$fontFamilyStandardLight: "HelveticaNeue-Light", "HelveticaNeue",
    "Helvetica Neue", Helvetica, Arial, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
$fontFamilyStandardMedium: "HelveticaNeue-Medium", "HelveticaNeue",
    "Helvetica Neue", Helvetica, Arial, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
$fontFamilyStandardBold: "HelveticaNeue-Bold", "HelveticaNeue", "Helvetica Neue",
    Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
$fontFamilyAltBlack: ArialMTStd-Black, "Arial Bold", Arial, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$fontFamilyAltBold: "Arial-Bold", Arial, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";

/*
    Font Sizes
    ---
    This uses the clothing tag approach to sizing, where if we need
    to introduce another size we can incrementally keep adding X's in
    either direction. For example $fontSizeXXXL

    M = Medium - the baseline
*/

$fontSize: (
    xxs: 10px,
    xs: 11px,
    s: 12px,
    m: 14px,
    l: 16px,
    xl: 18px,
    xxl: 20px,
    xxxl: 22px,
    xxxxl: 24px,
    xxxxxl: 33px,
    xxxxxxl: 50px
);

/*
    Spacing Sizes (for margins and padding)
    ---
    This uses the clothing tag approach to sizing, where if we need
    to introduce another size we can incrementally keep adding X's in
    either direction. For example $spacingSizeXXXL

    M = Medium - the baseline
*/
$spacing: (
    xs: 5px,
    s: 10px,
    m: 20px,
    l: 30px,
    xl: 40px,
    xxl: 60px,
    xxxl: 80px,
    xxxxl: 120px,
    xxxxxl: 160px
);

/*
    Colours
    ---
    This uses a variation of the clothing tag approach but instead of large and small
    it's light and dark.

    S = Standard - the most common version of that colour
*/

$colour: (
    startL: #fff,
    startS: #5d5d5d,
    startD: #000,
    blueL: #93c7ff,
    blueS: #007cff,
    blueD: #044488,
    blueXD: #003267,
    greenS: #7fb655,
    greenD: #009a3e,
    greenXD: #00a03d,
    greenXXD: #019c49,
    greyXXXL: #dfdfdf,
    greyXXL: #dddddd,
    greyXL: #e3e3e3,
    greyL: #dedede,
    greyS: #a9a9a9,
    greyM: #606060,
    greyD: #444444,
    isoOrangeS: #ff7d00,
    isoYellowS: #f8e71c,
    isoGreenS: #7fb655,
    isoPurpleS: #653986,
    isoBlueS: #00c4ff,
    isoDarkBlueS: #0970ca,
    isoRedS: #e53c3c,
    installerBlue: #4eb2ff,
    stockistGreen: #7eb555,
    solarYellow: #ffd229,
    darkGrey: #2D2D2D
);

/*
    Breakpoints
    ---
    This powers the include-media mixin
*/
$breakpoints: (
    s: 575px,
    m: 768px,
    l: 992px,
    xl: 1200px
);

/*
    Grid System
    ---
    The first item in $gridColumnOptions is the default grid and we
    only create the other grids if enabled in $gridOptions

*/
$gridColumnOptions: 12;
