.periodic-element {
    display: flex;
    flex-direction: column;
    flex-basis: 120px;
    height: 120px;
    text-decoration: none;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 20px 13px;
    color: map-get($colour, startL);

    &__title {
        font-size: map-get($fontSize, xxl);
        font-weight: bold;
    }

    &__subtitle {
        font-size: map-get($fontSize, xxs);
        opacity: 0.5;
    }

    &__desc {
        font-size: map-get($fontSize, xs);
        font-weight: bold;
        line-height: 1.2;
        margin-top: auto;
    }
}
