.pagination {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #000;
    margin-bottom: 5px;

    &__middle {
        width: auto;
        display: flex;
        flex: none;
        > div {
            margin: 0px 2px;
        }
    }

    &__select {
        position: relative;
        select {
            display: block;
            font-size: 16px;
            color: map-get($colour, greenXD);
            border: none;
            position: relative;
            appearance: none;
            padding: 0px 5px;
            padding-right: 20px;
            font-family: $fontFamilyStandardBold;
            font-weight: bold;
            line-height: 1;
            &::-ms-expand {
                display: none;
                opacity: 0;
            }
        }
        &:after {
            pointer-events: none;
            width: 6px;
            height: 6px;
            background: transparent;
            border-right: 2px solid #979797;
            border-bottom: 2px solid #979797;
            position: absolute;
            transform: translateY(-50%) translateX(-6px) rotate(45deg);
            top: 50%;
            right: 0px;
            z-index: 1;
            content: "";
        }
    }

    &__disabled-link {
        color: #aaaaaa;
        cursor: default;
    }

    a {
        color: #000;
        text-decoration: none;
    }
}
