.downloads-list {
    border: 1px solid map-get($colour, greyXXL);
    margin-bottom: map-get($spacing, xl);
}

.download {
    display: flex;
    border-bottom: 1px solid map-get($colour, greyXXL);

    &__labels {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-right: map-get($spacing, m);
        font-size: map-get($fontSize, m);
        align-items: center;
        font-family: $fontFamilyStandardMedium;
    }

    &__actions {
        border-left: 1px solid map-get($colour, greyXXL);
        width: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &:last-child {
        border-bottom: 0;
    }

    &__title {
        display: inline-block;
        text-decoration: none;
        color: map-get($colour, startS);
        line-height: 1.3;
        padding: map-get($spacing, m);

        &:hover {
            color: map-get($colour, startD);
        }
    }

    &__type {
        opacity: 0.5;
        text-transform: uppercase;
    }

    &__button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: map-get($colour, startS);

        &:hover {
            color: map-get($colour, startD);
        }
    }
}
