.body--case-studies-index .contact-fab {
    @include media("<=m") {
        display: none;
    }
}

.contact-fab {
    position: fixed;
    border: 3px solid map-get($colour, startL);
    background-color: map-get($colour, greenXXD);
    transition: background-color 0.2s ease-in-out;
    z-index: 50;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    right: 20px;
    width: 45px;
    height: 45px;

    @include media(">m") {
        bottom: 30px;
        right: 30px;
        width: 60px;
        height: 60px;
    }

    svg {
        width: 18px;

        @include media(">m") {
            width: 24px;
        }
    }

    &:hover {
        background-color: map-get($colour, greenS);
    }
}