@use "sass:math";

.news-preview {
    display: flex;
    text-decoration: none;
    color: map-get($colour, startD);
    font-size: 16px;
    &--feature {
        display: block;
        margin-bottom: map-get($spacing, xl);
        @include media(">m") {
            margin-bottom: 0;
        }
        .news-preview__image {
            width: 100%;
        }
        .news-preview__title {
            font-size: 22px;
            line-height: 1.2;
            margin-top: map-get($spacing, l);
            @include media(">m") {
                font-size: 28px;
            }
        }
    }

    &--small {
        padding-bottom: map-get($spacing, m);
        margin-bottom: map-get($spacing, m);
        border-bottom: 1px solid #DEDEDE;
        flex-direction: column;

        @include media(">m") {
            flex-direction: row;
        }

        .news-preview__image {
            width: 100%;
            @include media(">m") {
                width: 255px;
            }
        }

        .news-preview__info {
            @include media(">m") {
                padding-left: map-get($spacing, m);
            }
        }

        .news-preview__title {
            font-size: 18px;
            line-height: 1.2;
        }

        &:hover, &:focus {
            outline: none;
            .news-preview__title {
                text-decoration: underline;
            }
        }
    }

    &__image {
        flex: none;
        position: relative;
        display: block;
        overflow: hidden;
        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: math.div(9, 16) * 100%;
        }
        img {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__info {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0 0;
        font-size: 14px;
        @include media(">m") {
            padding: 1em 0;
        }
    }

    &__excerpt {
        margin-bottom: map-get($spacing, m) !important;
        @include media(">m") {
            margin-bottom: map-get($spacing, l) !important;
        }
    }

    &__title {
        font-family: $fontFamilyStandardBold !important;
        font-weight: bold;
        margin-bottom: 10px !important;
        @include media(">m") {
            margin-bottom: map-get($spacing, m) !important;
        }
    }

    &__date {
        margin-bottom: 0 !important;
        font-size: 14px;
        line-height: 1 !important;
        text-transform: uppercase;
        color: #848484;
    }

    a {
        display: block;
        text-decoration: none;
        &:hover, &:focus {
            outline: none;
            .news-preview__title {
                text-decoration: underline;
            }
        }
    }
}
