.periodic-modal-wrap {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: map-get($spacing, m);
    margin-top: 51px;
    min-height: calc(100vh - 51px);
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    @include media(">l") {
        margin-top: 25px;
    }
}

.periodic-modal {
    max-width: 640px;
    margin: auto;
    background-color: white;
    &__title {
        padding: map-get($spacing, m);
        color: map-get($colour, startL);
        font-family: $fontFamilyStandardBold;
        font-size: map-get($fontSize, xxxl);
        @include media(">m") {
            padding: map-get($spacing, l);
        }
    }

    &__inner {
        padding: map-get($spacing, m);

        @include media(">s") {
            display: flex;
            flex-wrap: wrap;
        }
        @include media(">l") {
            padding: map-get($spacing, l);
            padding-bottom: 60px;
        }
    }

    &__main {
        flex-basis: 60%;
        padding-right: map-get($spacing, l);
    }

    &__side {
        flex: 1;
    }

    &__label {
        color: map-get($colour, startS);
        font-family: $fontFamilyStandardBold;
        margin-bottom: map-get($spacing, m);
        display: block;
    }

    &__system {
        color: map-get($colour, startD);
    }
}

.periodic-modal-header-wrap {
    display: flex;
    justify-content: space-between;
}

.periodic-modal-close {
    color: inherit;
}

.periodic-modal.periodic-modal--popup {
    .periodic-modal__title {
        padding: map-get($spacing, l);
    }
}
