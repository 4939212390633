.tooltip {
    display:inline-block;
    position:relative;
    cursor: pointer;
    margin-left: 10px;

    &-info {
        min-width:300px;
        top:35px;
        left:50%;
        transform:translate(-50%, 0);
        padding: 30px;
        background-color:#000000;
        position:absolute;
        z-index:9999;
        box-sizing:border-box;
        border:1px solid #000000;
        display:none;
        text-transform: none;
        color: #A7A7A7;
        letter-spacing: 0;
        font-size: 14px;
        line-height: 23px;
        font-family: $fontFamilyStandardMedium;

        p {
            margin-bottom: 0!important;
            &:nth-of-type(2) {
                margin-top: 20px;
            }
        }

        strong {
            font-family: $fontFamilyStandardBold;
            color: #fff;
        }
    }

    &:hover {
        .tooltip-info {
            display: block;
        }
    }

    i {
        position:absolute;
        bottom:100%;
        left:50%;
        margin-left:-12px;
        width:24px;
        height:12px;
        overflow:hidden;

        &:after {
            content:'';
            position:absolute;
            width:12px;
            height:12px;
            left:50%;
            transform:translate(-50%,50%) rotate(45deg);
            background-color:#000;
        }
    }
}
