.download-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    &__wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;

        @include media(">l") {
            flex-direction: row;
        }
    }

    &__slider,
    &__content {
        width: 100%;
    }

    &__slider {
        height: 250px;
        overflow-x: hidden;

        @include media(">l") {
            height: auto;
            min-height: 600px;
        }

        .slick-list,
        .slick-track {
            height: 100%;
        }

        .slick-slide {
            height: 100%;

            >div {
                height: 100%;
            }
        }
    }

    &__slide {
        object-fit: cover;
        height: 100%;
    }

    &__success {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: map-get($spacing, "l");
        color: map-get($colour, "startD");
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 10;

        p {
            margin-top: map-get($spacing, "l");
        }
    }

    &__content {
        position: relative;
        padding: map-get($spacing, "l");

        @include media(">l") {
            padding: map-get($spacing, "xxl");
            min-height: 300px;
        }

        h2 {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: map-get($spacing, "m");

            @include media(">l") {
                font-size: 28px;
            }
        }

        strong {
            color: map-get($colour, "greenD");
        }

        p {
            font-size: 14px;
            line-height: 1.5;
            color: map-get($colour, "startD");
        }

        .ff-form-success {
            display: none;
        }
    }

    &__disclaimer {
        display: flex;
        align-items: center;

        svg {
            flex-shrink: 0;
            width: 14px;
        }

        p {
            margin-left: 15px;
            margin-bottom: 0;
        }

        p,
        a {
            font-size: 12px;
            color: map-get($colour, "startD");
        }

        a {
            &:hover {
                color: #459952;
            }
        }
    }

    &__fancybox {
        &.fancybox-is-open {
            .fancybox-caption {
                a {
                    color: #459952;
                    text-decoration: underline;
                }
            }
        }
    }
}