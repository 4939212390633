.copy {
    font-size: map-get($fontSize, m);
    overflow: hidden;

    p {
        margin-bottom: map-get($spacing, l);
        line-height: 25px;

        &.intro {
            font-size: map-get($fontSize, xxl);
            line-height: 32px;
            color: map-get($colour, startD);
        }

        &.no-mb {
            margin-bottom: 0px;
        }
    }

    h2 {
        font-family: $fontFamilyStandardMedium;
        font-size: map-get($fontSize, xxxl);
        color: map-get($colour, startD);
        margin-bottom: map-get($spacing, l);

        &.accordion__title {
            margin-bottom: 0;
        }
    }

    h3 {
        font-size: map-get($fontSize, xxl);
        line-height: 32px;
        color: map-get($colour, startD);
    }

    ol,
    ul {
        margin-bottom: map-get($spacing, m);

        // Make nested lists have a bit of spacing above their parent
        ol,
        ul {
            margin-top: map-get($spacing, m);
        }
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: square;
    }

    li {
        margin-left: map-get($spacing, l) * 0.5;
        margin-bottom: map-get($spacing, l) * 0.5;
        line-height: 1.7;
    }

    .fancy-list {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: map-get($spacing, m);

        &__item {
            display: flex;
            margin-left: 0;

            svg {
                margin-top: 5px;
                flex: 0 0 16px;
            }

            span {
                padding-left: map-get($spacing, s);
            }

            a {
                color: map-get($colour, startS);

                &:hover {
                    text-decoration: none;
                    color: map-get($colour, greenXD);
                }
            }
        }
    }

    strong,
    b {
        font-weight: bold;
    }

    em,
    i {
        font-style: italic;
    }

    .redactor {
        a {
            color: map-get($colour, greenXD);
            text-decoration: underline;
            transition: color .2s ease-in-out;

            &:hover {
                color: map-get($colour, startD);
            }
        }
    }

    .about-intro {
        p {
            font-size: map-get($fontSize, xxl);
            line-height: 28px;
            color: map-get($colour, startD);
            font-family: $fontFamilyStandardBold;

            @include media(">l") {
                font-size: map-get($fontSize, xxxxl);
                line-height: 32px;
            }
        }

        &__highlight {
            color: map-get($colour, greenXD);
        }
    }

    img {
        display: block;
        max-width: 100%;
    }

    &.component-description {
        p {
            margin-bottom: 15px;
            font-size: map-get($fontSize, s);
            line-height: 1.5;
        }
    }

    &--project {
        h2 {
            margin-top: map-get($spacing, xxxl);

            &:first-of-type {
                margin-top: 0;
            }
        }
    }
}

.redactor {
    &--black-larger {
        font-size: 18px;
        color: black;
        line-height: 150%;

        p {
            line-height: 150%;
        }

        a {
            color: black !important;

            &:hover {
                color: map-get($colour, greenXD) !important;
            }
        }
    }

    &--black-medium {
        font-size: 16px;
        color: black;
        line-height: 150%;

        p {
            line-height: 150%;
        }

        a {
            color: black !important;

            &:hover {
                color: map-get($colour, greenXD) !important;
            }
        }
    }
}

h2 {
    line-height: 1.4;

    &.component-category {
        margin-bottom: (map-get($spacing, l))*0.5;
    }

    &.no-mb {
        margin-bottom: 0;
    }

    &.h2--smaller {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

// Misc type styles
h3 {
    &.divider {
        position: relative;
        text-align: center;
        overflow: hidden;
        font-family: $fontFamilyAltBlack;
        font-size: map-get($fontSize, xl);
        text-transform: uppercase;
        color: map-get($colour, startD);
        line-height: 36px;

        span {
            display: inline-block;
            position: relative;

            &:before,
            &:after {
                content: "";
                position: absolute;
                height: 1px;
                border-top: 1px solid map-get($colour, greyL);
                top: 0;
                bottom: 0;
                margin-top: auto;
                margin-bottom: auto;
                width: 600px;
            }

            &:before {
                right: 100%;
                margin-right: map-get($spacing, l);
            }

            &:after {
                left: 100%;
                margin-left: map-get($spacing, l);
            }
        }
    }
}

.blockquote {
    font-size: map-get($fontSize, xxl);
    line-height: 32px;
    padding: map-get($spacing, m);
    margin: map-get($spacing, l) 0 map-get($spacing, xxl);

    @include media(">l") {
        padding: map-get($spacing, xl);
    }

    p {
        color: inherit;
        margin-bottom: map-get($spacing, s);
        font-family: $fontFamilyStandardBold;
    }

    cite {
        color: inherit;
        opacity: 0.5;
        font-family: $fontFamilyStandardMedium;
        font-size: map-get($fontSize, m);
    }
}

.components-intro {
    .redactor {
        &:first-of-type {
            p {
                &:first-of-type {
                    color: map-get($colour, startD);
                    font-size: map-get($fontSize, xxxl);
                    line-height: 30px;
                }
            }
        }
    }
}

.copy--content-builder {
    h2 {
        font-family: $fontFamilyStandardLight;
        font-size: 44px;
        line-height: 1.2;
        color: map-get($colour, startD);
        margin-bottom: map-get($spacing, l);
    }
}