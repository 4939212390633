.form {
    width: 100%;
    margin-bottom: 80px;

    &__row {
        position: relative;

        &--radio_group {
            margin-bottom: 30px;

            label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 15px;
                color: #000;
                padding-left: 1px;
                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                }

                .form__input--radio_group {
                    position: relative;
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                    cursor: pointer;

                    &:after {
                        width: 30px;
                        height: 30px;
                        border-radius: 15px;
                        top: 0;
                        left: 0;
                        position: relative;
                        background-color: white;
                        content: '';
                        display: block;
                        visibility: visible;
                        border: 9px solid white;
                        z-index: 2;
                        box-shadow: 0px 0px 0px 1px #dedede;
                    }

                    &:checked:after {
                        width: 30px;
                        height: 30px;
                        border-radius: 30px;
                        top: 0;
                        left: 0;
                        position: relative;
                        background-color: #88B361;
                        content: '';
                        display: block;
                        visibility: visible;
                        border: 9px solid white;
                        z-index: 2;
                        box-shadow: 0px 0px 0px 1px #dedede;
                    }
                }
            }
        }
    }

    &__label {
        display: block;
        font-size: 16px;
        font-family: $fontFamilyStandard;
        color: #000;
        margin-bottom: 15px;

        &--required {
            padding-right: 70px;

            &:after {
                content: 'required';
                position: absolute;
                right: 0;
                top: 5px;
                text-transform: uppercase;
                font-size: 10px;
                color: #B1B1B1;
            }
        }
    }

    &__instructions {
        display: block;
        font-size: 16px;
        color: #000;
        margin-bottom: 15px;
    }

    &__input {
        display: block;
        padding: 0 16px;
        border: 1px solid #dedede;
        background: #fff;
        width: 100%;
        height: 57px;
        font-size: 16px;
        margin-bottom: 30px;

        &:focus {
            outline: 0;
        }

        &--textarea {
            height: 200px;
            padding: 16px;
            resize: none;

            @include media(">m") {
                height: 300px;
            }

            &[name=address] {
                height: auto;
            }
        }

        &--submit {
            width: auto;
            min-width: 130px;
            border-radius: 40px;
            background: #000 !important;
            color: #fff;
            cursor: pointer;
            appearance: none;
            border: none;
            height: auto;
            padding: 16px;

            &:hover {
                background: lighten(#000, 20%) !important;
            }
        }

        &--downloads-submit {
            width: 100%;
            display: flex;
            justify-content: space-between;
            background: #459952 !important;
            color: #fff;
            align-items: center;
            padding: 20px 30px;
            height: auto;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
            appearance: none;
            border: 0;

            &:hover {
                background: lighten(#459952, 20%) !important;
            }
        }
    }

    &--downloads {
        margin-bottom: map-get($spacing, 'l');
    }
}

// Custom freeform styles
.ff-form-success {
    background-color: map-get($colour, greenXXD);
    padding: map-get($spacing, m) map-get($spacing, s);
    font-size: map-get($fontSize, l);
    font-family: $fontFamilyStandardMedium;
    text-align: center;
    margin-bottom: map-get($spacing, l);
    color: map-get($colour, startL);
    line-height: 1.2;
}