.hero {
    position: relative;
    height: 370px;
    margin-bottom: map-get($spacing, xl);
    background-color: map-get($colour, startD);

    @include media(">m") {
        height: 570px;
    }

    @include media(">l") {
        margin-bottom: map-get($spacing, xxxl);
    }

    &--small {
        @include media(">m") {
            height: 370px;
        }
    }

    &--map {
        //height: 100px;
    }

    &--nm {
        margin-bottom: 0;
    }

    &--system {
        @include media(">m") {
            margin-bottom: 0;
        }
    }

    &--news-index {
        height: auto;
        background: white;
        margin-bottom: map-get($spacing, xl);
        padding-top: map-get($spacing, xxxl);
        color: map-get($colour, startD);
        @include media(">m") {
            margin-bottom: map-get($spacing, xxxl);
            padding-top: map-get($spacing, xxxxl);
        }
        .hero__text {
            color: map-get($colour, startD);
        }
        .hero__overlay {
            bottom: 0;
            position: relative;
        }
    }

    &__intro-text {
        color: map-get($colour, darkGrey);
        font-family: $fontFamilyStandard;
        margin-top: map-get($spacing, xl);
        font-size: map-get($fontSize, xxl);
        line-height: 1.65;
        @include media(">m") {
            font-size: map-get($fontSize, xxxxl);
            line-height: 1.54;
        }
    }

    &__overlay {
        position: absolute;
        bottom: 40px;
        width: 100%;

        @include media(">m") {
            bottom: 80px;
        }

        &__inner {
            @include media(">m") {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }
        }
    }

    &__text {
        font-size: map-get($fontSize, xxxxxl);
        font-family: $fontFamilyAltBlack;
        text-transform: uppercase;
        color: map-get($colour, startL);
        margin-bottom: map-get($spacing, m);

        @include media(">m") {
            margin-bottom: 0;
            font-size: map-get($fontSize, xxxxxxl);
        }
    }

    &__breadcrumb {
        color: map-get($colour, startL);
        text-decoration: none;
        display: inline-block;
        margin-bottom: map-get($spacing, s);
        font-family: $fontFamilyStandardBold;
        font-size: map-get($fontSize, l);
    }
}

.about {
    .hero {
        @include media(">l") {
            margin-bottom: 0;
        }

        &__overlay {
            @include media(">l") {
                bottom: 160px;
            }
        }
    }
}
