.partner-info {
    position: relative;
    display: flex;
    margin-bottom: map-get($spacing, l);
    background-color: #fff;
    transition: background-color 0.3s linear;

    &--installer {
        border-top: 6px solid map-get($colour, installerBlue);
    }

    &--stockist {
        border-top: 6px solid map-get($colour, stockistGreen);
    }

    &__installer {
        display: flex;
        margin-top: 25px;
    }

    &__installer-details {
        width: 50%;
        padding-right: 10px;
    }

    &__installer-label {
        text-transform: uppercase;
        font-family: $fontFamilyStandardBold;
        letter-spacing: 1.54px;
        margin-bottom: 10px;
        font-size: map-get($fontSize, xxs);
        display: inline-flex;
        align-items: center;
    }

    .copy &__installer-type, .copy &__installer-specialism {
        margin: 0;
    }

    &__installer-link {
        color: #000;
    }

    &--selected#{&}--installer {
        border: 6px solid map-get($colour, installerBlue);
    }

    &--selected#{&}--stockist {
        border: 6px solid map-get($colour, stockistGreen);
    }

    &__inner {
        width: 100%;
        border: 1px solid map-get($colour, greyXXL);
        overflow: visible;
        //padding: map-get($spacing, l);
        &--in-modal {
            border: none;
            border-top: 6px solid map-get($colour, installerBlue);
            .partner-info__title {
                padding-right: 60px;
            }
        }
    }

    &__inner-details {
        width: 50%;
        padding-right: 10px;
    }

    &__header {
        padding: 30px;
    }

    .copy &__title {
        font-size: map-get($fontSize, xxxl);
        font-family: $fontFamilyStandardMedium;
        margin-bottom: map-get($spacing, m);
        margin-bottom: 0;
        color: map-get($colour, startD);

        &--installer {
            color: map-get($colour, installerBlue);
        }

        &--stockist {
            color: map-get($colour, stockistGreen);
        }
    }

    &__details {
        display: flex;
        padding: 30px;
        border-top: 1px solid #dddddd;

        p {
            margin-bottom: 0;
        }
    }

    // &--selected {
    //     h2,
    //     p,
    //     a {
    //         color: #fff !important;
    //     }
    // }
}
