.filter-bar {
    padding: 30px 0;

    @include media(">m") {
        padding: 50px 0;
    }

    &--partners {
        padding-top: 0;
    }

    &__filters {
        display: flex;
        flex-direction: column;

        @include media(">m") {
            flex-direction: row;
            align-items: flex-end;
        }
    }

    &__filter {
        flex-grow: 1;
        flex-basis: 0;
        margin-bottom: 20px;

        @include media(">m") {
            padding-right: 30px;
            margin-bottom: 0;
        }
    }

    &__label {
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        font-family: $fontFamilyStandardBold;
        letter-spacing: 1.54px;
        margin-bottom: (map-get($spacing, l))*0.5;
        font-size: map-get($fontSize, xxs);
        color: #000;
    }

    &__select-wrapper {
        position: relative;

        i {
            position: absolute;
            right: 11px;
            top: 11px;
            color: #a9a9a9;
            width: 17px;
            height: 17px;
            cursor: pointer;
            pointer-events: none;
        }
    }

    &__select,
    &__search {
        display: block;
        width: 100%;
        height: 40px;
        border: 1px solid #dddddd;
        appearance: none;
        background-color: #fff;
        border-radius: 0;
        font-size: 14px;
        padding: 0 10px;
        color: #000;

        &::-ms-expand {
            display: none;
        }
    }

    &__submit {
        height: 40px;
        width: 100%;
        appearance: none;
        background-color: #000 !important;
        cursor: pointer;
        appearance: none;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media(">m") {
            width: 60px;
        }

        &:hover {
            background: lighten(#000, 20%) !important;
        }
    }
}