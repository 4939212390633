.mb {
    margin-bottom: map-get($spacing, m);

    &--s {
        margin-bottom: map-get($spacing, s);
    }

    &--l {
        margin-bottom: map-get($spacing, l);
    }

    &--xl {
        margin-bottom: map-get($spacing, xl);
    }

    &--xxl {
        margin-bottom: map-get($spacing, xxl);
    }

    &--xxxl {
        margin-bottom: map-get($spacing, xxxl);
    }

    &--xxxxl {
        margin-bottom: map-get($spacing, xxxxl);
    }
}

.mt {
    &--xl {
        margin-top: map-get($spacing, xl);
    }
    &--xxl {
        margin-top: map-get($spacing, xxl);
    }
}

hr {
    &.content-divider {
        margin: map-get($spacing, l) 0 map-get($spacing, xxl);
        border-color: map-get($colour, greyXXL);
        border-style: solid;
        border-width: 1px;

        &--l {
            margin: map-get($spacing, xl) 0 map-get($spacing, xxl);
        }

        &--xxxl-bottom {
            margin: map-get($spacing, xl) 0 map-get($spacing, xxxl);
        }
    }
}
