
.cpd-detail__block {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #DEDEDE;
    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}


.cpd-details {
    &__row {
        display: flex;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__icon {
        width: 16px;
        height: 16px;
        margin-top: 4px;
        svg {
            display: block;
        }
    }
    &__info {
        padding-left: 15px;
    }
    &__type {
        font-weight: bold;
        font-size: 10px;
        line-height: 25px;
        letter-spacing: 0.1em;
        color: #828282;
        text-transform: uppercase;
        font-family: $fontFamilyStandardBold;
        margin-bottom: 2px;
    }
    &__value {
        font-size: 14px;
        color: black;
    }
}
