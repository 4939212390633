$comparisons-breakpoint: ">m"; // When to hide/display the table and cards
$comparisons-border-style: 1px solid #d8d8d8;

%comparisons-text-styles {
    font-size: map-get($fontSize, m);
    line-height: 25px;
    color: map-get($colour, startD);
}

%comparisons-link-styles {
    font-size: map-get($fontSize, s);
    color: #747474;
}

.comparisons {
    width: 100%;

    strong {
        font-weight: bold;
    }

    // Ticks
    svg {
        min-width: 25px;
    }

    &__table {
        display: none;
        width: 100%;
        margin-bottom: map-get($spacing, m);

        @include media($comparisons-breakpoint) {
            display: block;
        }
    }

    &__table-cell {
        padding: map-get($spacing, m);
        text-align: center;
        background-color: map-get($colour, startL);
        border-bottom: $comparisons-border-style;
        border-left: $comparisons-border-style;
        border-right: $comparisons-border-style;

        @include media(">xl") {
            min-width: 170px;
        }

        &--blank {
            border: none;
            opacity: 0;
            visibility: hidden;
        }

        &--empty {
            border-top: $comparisons-border-style;
        }

        &--category {
            font-size: map-get($fontSize, l);
            border: none;
        }

        &--header {
            padding: map-get($spacing, l);
            font-size: map-get($fontSize, l);

            img {
                display: inline-block;
                width: 70px;
            }

            p {
                margin-top: map-get($spacing, s);
                margin-bottom: map-get($spacing, s);
                color: map-get($colour, startD);
            }

            a {
                @extend %comparisons-link-styles;
            }
        }

        &--left {
            @extend %comparisons-text-styles;
            text-align: left;
            width: 300px;
        }
    }

    &__cards {
        @include media($comparisons-breakpoint) {
            display: none;
        }
    }

    &__header {
        width: 100%;
        padding: map-get($spacing, m);
        font-size: map-get($fontSize, l);
        text-align: center;
    }

    &__card {
        margin-bottom: map-get($spacing, m);
        border: $comparisons-border-style;

        &:first-child {
            border-top: none;
        }
    }

    &__card-header {
        display: flex;
        flex-flow: nowrap row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: map-get($spacing, l);
        border-bottom: $comparisons-border-style;

        img {
            display: block;
            width: 60px;
            margin-right: map-get($spacing, m);
        }

        p {
            margin-bottom: map-get($spacing, s);
            font-size: map-get($fontSize, l);
            color: map-get($colour, startD);
        }

        a {
            @extend %comparisons-link-styles;
        }
    }

    &__card-content {
        padding: map-get($spacing, m) map-get($spacing, l);

        li {
            @extend %comparisons-text-styles;
            display: flex;
            flex-flow: nowrap row;
            justify-content: flex-start;
            margin-bottom: map-get($spacing, m);

            svg {
                margin-right: map-get($spacing, l);
            }
        }
    }

    &__footnote {
        margin-bottom: map-get($spacing, xxl);
        line-height: 19px;
    }
}
