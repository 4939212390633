/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/39858b");

@font-face {
    font-family: "ArialMTStd-Black";
    src: url("./global/webfonts/39858B_0_0.eot");
    src: url("./global/webfonts/39858B_0_0.eot?#iefix") format("embedded-opentype"),
        url("./global/webfonts/39858B_0_0.woff2") format("woff2"),
        url("./global/webfonts/39858B_0_0.woff") format("woff"),
        url("./global/webfonts/39858B_0_0.ttf") format("truetype");
}