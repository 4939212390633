.breadcrumbs {
    list-style: none;
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &__item {
        display: inline-block;

        &:not(:last-child) {
            &::after {
                color: map-get($colour, startS);
                content: ">";
                margin: 0 map-get($spacing, s);
            }
        }
    }

    &__link {
        text-decoration: none;
        color: map-get($colour, startD);
    }
}
