.contact-tabs {
    display: none;
    justify-content: space-between;
    margin-bottom: 60px;

    @include media(">m") {
        display: flex;
    }

    &__button {
        background-color: #eaeaea;
        color: #6c6c6c;
        width: 100%;
        height: 100%;
        padding: 30px 10px;
        font-size: 16px;
        border: 0;
        appearance: none;
        cursor: pointer;
        transition: all 0.1s ease-in;
        font-family: $fontFamilyStandardMedium;

        &--active {
            background-color: #000;
            color: #fff;
        }

        &:focus {
            outline: 0;
        }
    }

    &__button:hover{
        background-color: #000;
        color: #fff;
    }

    &__select-wrapper {
        position: relative;
        display: block;

        @include media(">m") {
            display: none;
        }

        i {
            position: absolute;
            right: 15px;
            top: 20px;
            color: map-get($colour, startL);
            width: 17px;
            height: 17px;
            cursor: pointer;
            pointer-events: none;
        }
    }

    &__select {
        width: 100%;
        appearance: none;
        border: 0;
        background-color: #000;
        color: #fff;
        margin-bottom: 40px;
        font-size: map-get($fontSize, m);
        font-family: $fontFamilyStandardMedium;
        padding: 20px;
        padding-right: 40px;
        overflow: hidden;
        white-space: nowrap;
        border-radius: 0;

        &::-ms-expand {
            display: none;
        }
    }
}

.content-reveal {
    transition: opacity 0.5s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;

    &--active {
        opacity: 1;
        height: auto;
    }
}
