.button {
    display: inline-block;
    padding: map-get($spacing, m);
    font-family: $fontFamilyStandardBold;
    font-size: map-get($fontSize, l);
    line-height: 1.5;
    color: map-get($colour, startD);
    text-decoration: none;
    transition: 0.15s ease-in;
    appearance: none;
    border: 1px solid map-get($colour, greyXL);
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: none;
    margin-bottom: map-get($spacing, m);

    &:before {
        content: "";
        position: absolute;
        background: map-get($colour, startD);
        bottom: 0;
        left: 0;
        right: 0;
        top: 100%;
        z-index: -1;
        transition: top 0.15s ease-in;
    }

    &:hover,
    &:focus {
        &:before {
            top: 0;
        }
    }

    &:hover,
    &:focus {
        color: map-get($colour, startL);
        border-color: map-get($colour, startD);
    }

    &--block {
        display: block;
        width: 100%;
    }

    &--icon {
        padding-left: 60px;
    }

    &--ghost {
        color: map-get($colour, startL);
    }

    &--gallery {
        text-transform: uppercase;
        border: 0;
        font-family: $fontFamilyStandardMedium;
        font-size: map-get($fontSize, s);
        background-color: map-get($colour, startL);
    }

    &--black {
        background: #000;
        color: #fff;
        border: none;
        text-align: center;
        font-size: 22px;
        margin-bottom: 40px;
        padding: map-get($spacing, m);
        line-height: 1.1;
    }

    &--small-black {
        background: map-get($colour, startD);
        padding: #{map-get($spacing, m) + 1px}  map-get($spacing, m);
        color: map-get($colour, startL);
        border: none;
        &:before {
            background: map-get($colour, startL);
            border: 1px solid map-get($colour, greyXL);
        }
        &:hover, &:focus {
            color: map-get($colour, startD);
            background: map-get($colour, startL);
            &:before {
                top: 0;
            }
        }
    }

    i {
        position: absolute;
        left: 20px;
        top: 16px;
    }

    &--no-border {
        padding: 0px;
        padding-left: 23px; // To account for an 8px icon + 15px gap
        border: none;
        line-height: 1;
        &:before {
            display: none;
        }
        &:hover,
        &:focus {
            color: map-get($colour, startD);
            &:before {
                display: none;
            }
        }

        i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.button-row {
    margin-top: map-get($spacing, xl);
    @include media(">m") {
        margin-top: map-get($spacing, xxl);
    }
    .button {
        width: 100%;
        text-align: center;
        margin-bottom: map-get($spacing, m);
        @include media(">m") {
            margin-right: map-get($spacing, m);
            margin-bottom: 0;
            width: auto;
        }
        &:last-child {
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}
