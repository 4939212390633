@use "sass:math";

#map {
    width: 100%;
    height: 100%;
}

.partners-map {
    margin-bottom: map-get($spacing, xxl);
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: math.div(29, 46) * 100%;
    }
    &__inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
}

.map {
    &-snackbar {
        display: none;
        position: fixed;
        bottom: 30px;
        background-color: black;
        transition: background-color 0.5s ease-in-out;
        padding: 10px 20px;
        color: #fff;
        border-radius: 3px;
        left: 50%;
        transform: translateX(-50%);

        &--open {
            display: block;
        }

        &--success {
            background-color: map-get($colour, greenS);
        }

        &--error {
            background-color: map-get($colour, isoRedS);
        }
    }
}
