.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    position: relative;
    //margin-bottom: map-get($spacing, l);

    &__image {
        width: 100%;
        height: auto;
        display: block;
        transition: opacity 0.4s ease-in-out;
        max-width: 100%;
        flex-shrink: 0;
    }

    &:hover {
        cursor: pointer;
        .card__image {
            opacity: 0.8;
        }
    }

    &__inner {
        padding: map-get($spacing, m);
        box-sizing: border-box;
        border: 1px solid map-get($colour, greyL);
        border-bottom: 0;
        height: 100%;

        @include media(">l") {
            padding: map-get($spacing, m) * 1.5;
        }
    }

    &__title {
        font-family: $fontFamilyStandardBold;
        font-size: map-get($fontSize, l);
        color: map-get($colour, startD);
    }

    &__description {
        margin-top: map-get($spacing, m);
        font-size: map-get($fontSize, s);
        line-height: 1.5;
        color: map-get($colour, greyM);
        display: flex;
    }

    &__text {
        padding-right: 20px;
    }

    &__icon {
        color: map-get($colour, greyL);
    }

    &__cta {
        font-size: map-get($fontSize, s);
        text-decoration: underline;
        margin-top: map-get($spacing, m);
        display: block;
    }

    &__big-cta {
        display: block;
        border: 1px solid #DEDEDE;
        padding: 16px;
        font-size: map-get($fontSize, m);
        text-decoration: none;
        text-align: center;
        color: black;
        position: relative;
        transition: all 0.15s ease-in;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            background: map-get($colour, startD);
            bottom: 0;
            left: 0;
            right: 0;
            top: 100%;
            z-index: -1;
            transition: top 0.15s ease-in;
        }
    }

    &__riba-badge {
        text-transform: uppercase;
        color: black;
        font-size: 10px;
        background: white;
        border-radius: 2px;
        padding: 5px;
        padding-right: 8px;
        display: flex;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
        align-items: center;
        pointer-events: none;
        img {
            display: block;
            margin-right: 4px;
        }
        span {
            line-height: 1;
            font-family: $fontFamilyStandardBold;
        }
    }

    &--green {
        border-bottom: 6px solid map-get($colour, isoGreenS);
    }

    &--cpd-seminar {
        .card__title {
            line-height: 135%;
        }
        .card__inner {
            display: flex;
            flex-direction: column;
        }
        .card__description {
            font-size: map-get($fontSize, m);
            margin-bottom: map-get($spacing, l);
            color: black;
            font-size: 14px;
            line-height: 25px;
        }
        .card__text {
            padding-right: 0;
        }
        .cpd-details {
            margin-bottom: map-get($spacing, l);
        }
        .card__big-cta {
            margin-top: auto;
        }
        &:hover,
        &:focus {
            .card__big-cta{
                color: map-get($colour, startL);
                border-color: map-get($colour, startD);
                &:before {
                    top: 0;
                }
            }
        }
    }
}
