.nav {
    &__search {
        background: map-get($colour, startL);
        right: 0;
        position: absolute;
        top: 0px;
        width: 100%;
        opacity: 0;
        z-index: -1;
        transition: opacity 0.2s ease-in-out;
        pointer-events: none;

        @include media(">s") {
            width: 370px;
            right: 210px;
        }

        &--open {
            pointer-events: all;
            opacity: 1;
            z-index: 1;
            box-shadow: 0 0 3px map-get($colour, greyL);
        }

        &__inner {
            position: relative;
            padding: 10px 10px 10px 20px;

            &:before {
                content: "\A";
                width: 0px;
                height: 0px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid map-get($colour, startL);
                position: absolute;
                top: -5px;
                right: 170px;

                @include media(">s") {
                    right: 70px;
                }
            }
        }

        &__input {
            border: 0;
            width: 100%;
            font-size: map-get($fontSize, m);
            line-height: 31px;
            appearance: none;
            padding-top: 12px;
            padding-bottom: 12px;

            &:focus {
                outline: 0;
            }
        }

        &__btn {
            position: absolute;
            top: 10px;
            right: 10px;
            border: 0;
            height: 55px;
            background: #7eb555;
            width: 55px;
            color: map-get($colour, startL);
            font-size: map-get($fontSize, s);
            text-transform: uppercase;
            font-family: $fontFamilyStandardBold;
            letter-spacing: 2px;

            &:focus {
                outline: 0;
            }
        }
    }
}
