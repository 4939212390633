.person-details {
    margin-bottom: map-get($spacing, m);
    p {
        margin: 0;
    }
    &__name {
        font-family: $fontFamilyStandardBold;
        color: map-get($colour, startS);
    }

    &.redactor &__email {
        font-family: $fontFamilyStandardBold;
        color: map-get($colour, greenD);
    }
}
