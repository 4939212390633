.video {
	position: relative;
	padding: 0;
	padding-bottom: 56.25%; // 16:9 Ratio
}

.video__iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
