.search-result {
    &__heading {
        display: block;
        text-decoration: none;
        color: map-get($colour, greenXD);
        font-family: $fontFamilyStandardMedium;
        font-size: map-get($fontSize, xl);
        margin-bottom: map-get($spacing, s);
    }

    &__tags {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

.search-box {
    position: relative;
    padding: 12px 20px 12px 50px;
    border: 1px solid map-get($colour, greyL);
    margin-bottom: map-get($spacing, m);

    i {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 17px;
        width: 20px;
        height: 20px;
    }

    &__input {
        border: 0;
        width: 100%;
        font-size: map-get($fontSize, m);
        line-height: 31px;

        &:focus {
            outline: 0;
        }
    }
}

.search-results {
    order: 2;
    @include media(">l") {
        order: 1;
    }
}

.search-results-form {
    order: 1;
    @include media(">l") {
        order: 2;
    }
}
