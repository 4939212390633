/*
    Create an @ targeter for the current selector
*/
@mixin target($target) {
    @if $target!='' {
        @include media(">#{$target}") {
            &\@#{$target} {
                @content;
            }
        }
    } @else {
        @content;
    }
}
