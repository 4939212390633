.system {
    &__prefix {
        display: block;
        font-family: $fontFamilyAltBlack;
        font-size: map-get($fontSize, m);
        color: map-get($colour, greenD);
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__title {
        font-family: $fontFamilyAltBlack;
        font-size: map-get($fontSize, xxxxl);
        text-transform: uppercase;
        color: map-get($colour, startD);
        margin-bottom: map-get($spacing, m);
    }

    &__intro {
        font-size: map-get($fontSize, xxl);
        line-height: 32px;
        color: map-get($colour, startD);
        margin-bottom: map-get($spacing, m);
    }

    &__buildup {
        max-width: 100%;
    }

    &__media {
        position: relative;
    }

    &__expander {
        position: absolute;
        display: block;
        background: map-get($colour, greenD);
        color: map-get($colour, startL);
        top: 0;
        left: 0;
        z-index: 1;
        padding: 10px;
    }

    &__elements {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: map-get($spacing, l);
    }
}
